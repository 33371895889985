export interface FeaturesParams {
  [key: string]: boolean;
}

export interface FeaturesContextParams {
  features?: FeaturesParams;
}

export enum FEATURES {
  VERIFICATION_FLOW = 'verification_flow',
  FORM_BUILDER = 'form_builder',
  FLOW_BUILDER = 'flow_builder',
  TRANSLATION = 'translations',
  COMPLY_ADVANTAGE_AML_BUSINESS = 'comply_advantage_aml_business',
  UNIT_21_ENTITY_ID = 'unit_21_entity_id',
  ELLIPTIC_WALLET = 'elliptic_wallet',
  MAXMIND_GEO_IP = 'maxmind_geo_ip',
  SHUFTIPRO_KYB = 'shuftipro_kyb',
  COMPLY_ADVANTAGE_AML = 'comply_advantage_aml',
  FINCEN_BUSINESS = 'fincen_business',
  FINCEN_PERSON = 'fincen_person',
  UNIT_21_ENTITY_DOC = 'unit_21_entity_doc',
  BLACKLISTED_PROFILES_PERSON = 'blacklisted_profiles_person',
  BLACKLISTED_PROFILES_BUSINESS = 'blacklisted_profiles_business',
  UNIT_21_KYB = 'unit_21_kyb',
  BLACKLISTED_DEVICE_INFOS = 'blacklisted_device_infos',
  PROHIBITED_COUNTRIES = 'prohibited_countries',
  IDENFY = 'idenfy',
  VERIFF = 'veriff',
  DIRECT_IDENTITY_VERIFICATION = 'direct_identity_verification',
  VERIFF_IDENTITY_VERIFICATION = 'veriff_identity_verification',
  TRX_RISK_SCORE = 'trx_risk_score',
  GLOBAL_SEARCH = 'global_search',
  CUSTOMIZATION_TOOLS = 'customization_tools',
  TRANSACTION_MONITORING = 'transaction_monitoring',
  CASE_RISK_SCORING = 'case_risk_scoring',
  AI_SERVICES = 'ai_services',
  EDD_REPORTS = 'edd_reports',
  DATA_SHARING = 'data_sharing',
}

export const FEATURES_SCREENING = [
  FEATURES.VERIFF_IDENTITY_VERIFICATION,
  FEATURES.UNIT_21_KYB,
  FEATURES.UNIT_21_ENTITY_ID,
  FEATURES.UNIT_21_ENTITY_DOC,
  FEATURES.SHUFTIPRO_KYB,
  FEATURES.MAXMIND_GEO_IP,
  FEATURES.FINCEN_BUSINESS,
  FEATURES.FINCEN_PERSON,
  FEATURES.ELLIPTIC_WALLET,
  FEATURES.COMPLY_ADVANTAGE_AML,
  FEATURES.COMPLY_ADVANTAGE_AML_BUSINESS,
  FEATURES.PROHIBITED_COUNTRIES,
  FEATURES.BLACKLISTED_PROFILES_BUSINESS,
  FEATURES.BLACKLISTED_PROFILES_PERSON,
  FEATURES.BLACKLISTED_DEVICE_INFOS,
  FEATURES.DIRECT_IDENTITY_VERIFICATION,
  FEATURES.IDENFY,
  FEATURES.VERIFF,
];
