import styled from '@emotion/styled';
import { getColor } from '../../../../utils';
import { Text } from '../../../Text';
import { HorisontalInputDecoratorViewProps } from './types';

export const Container = styled.div`
  max-width: 100%;
`;

export const InputContainer = styled.label<
  Pick<HorisontalInputDecoratorViewProps, 'disabled' | 'label'>
>`
  display: grid;
  align-items: center;
  grid-template-columns: max-content minmax(0px, 1fr);
  gap: 8px;
  position: relative;
  cursor: pointer;
  user-select: none;

  ${({ disabled }) =>
    disabled
      ? `
    pointer-events: none;
  `
      : ''}

  ${({ label }) =>
    !label &&
    `
  grid-template-columns: max-content;
  `}
`;

export const Label = styled(Text)<
  Pick<HorisontalInputDecoratorViewProps, 'error' | 'disabled'>
>`
  white-space: pre-line;
  overflow-wrap: anywhere;
  line-break: auto;
  color: ${getColor('main-deep-4')};

  ${({ error }) =>
    error
      ? `
    color: ${getColor('error-deep-3')};
  `
      : ''}

  ${({ disabled }) =>
    disabled
      ? `
    color: ${getColor('gray-deep-3')};
  `
      : ''}
`;
