import { PERMISSIONS, Protected } from '@aid-package/context';
import { useParams } from 'react-router-dom';
import {
  CaseManagementLink,
  DashboardLink,
  TransactionAlertsLink,
  TransactionCasesLink,
  Dropdown,
  Link,
  TitleLink,
  TMSettingsLink,
  UsersLink,
  EmailsLink,
  RemindersLink,
  SharingLink,
} from './components';

import { Container, GroupContainer } from './styles';

export const ENABLED_PRICING = process.env.REACT_APP_ENABLED_PRICING;

export function Navigation() {
  const { client_id } = useParams();

  return (
    <Container flexDirection="column">
      <Protected
        check={[
          PERMISSIONS.SEE_PROFILES_LIST,
          PERMISSIONS.SEE_PROFILE,
          PERMISSIONS.SEE_CM_CASES_LIST,
          PERMISSIONS.SEE_SUBMISSIONS_LIST,
          PERMISSIONS.SEE_SUBMISSION,
          PERMISSIONS.SEE_SUBMISSION_CONFIDENTIAL,
          PERMISSIONS.SEE_PROFILE_CONFIDENTIAL,
          PERMISSIONS.SEE_DASHBOARD,
          PERMISSIONS.MANAGE_PROFILE,
          PERMISSIONS.SEE_SHARED_CASES,
          PERMISSIONS.SEE_CM_CASES,
          PERMISSIONS.MANAGE_CM_CASES,
          PERMISSIONS.SEE_APPROVED_CM_CASES,
          PERMISSIONS.SEE_DASHBOARD_VERIFICATION,
          PERMISSIONS.FORMS_CREATE,
          PERMISSIONS.FORMS_UPDATE,
          PERMISSIONS.FORMS_PUBLISH,
          PERMISSIONS.FORMS_MAPPINGS,
          PERMISSIONS.CM_IDENTITY_VERIFICATIONS,
          PERMISSIONS.SEE_TM_DASHBOARD,
        ]}
      >
        <GroupContainer>
          <Protected
            check={[
              PERMISSIONS.SEE_DASHBOARD,
              PERMISSIONS.MANAGE_PROFILE,
              PERMISSIONS.SEE_SHARED_CASES,
              PERMISSIONS.SEE_CM_CASES,
              PERMISSIONS.MANAGE_CM_CASES,
              PERMISSIONS.SEE_APPROVED_CM_CASES,
              PERMISSIONS.SEE_DASHBOARD_VERIFICATION,
            ]}
          >
            <TitleLink to="#" text="Verifications" />
          </Protected>
          <Protected
            check={[
              PERMISSIONS.SEE_DASHBOARD,
              PERMISSIONS.SEE_DASHBOARD_VERIFICATION,
              PERMISSIONS.SEE_TM_DASHBOARD,
            ]}
          >
            <DashboardLink />
          </Protected>
          <Protected
            check={[
              PERMISSIONS.SEE_PROFILES_LIST,
              PERMISSIONS.SEE_PROFILE,
              PERMISSIONS.SEE_PROFILE_CONFIDENTIAL,
              PERMISSIONS.SEE_CM_CASES_LIST,
              PERMISSIONS.SEE_SUBMISSIONS_LIST,
              PERMISSIONS.SEE_SUBMISSION,
              PERMISSIONS.SEE_SUBMISSION_CONFIDENTIAL,
              PERMISSIONS.SEE_SHARED_CASES,
              PERMISSIONS.SEE_CM_CASES,
              PERMISSIONS.MANAGE_CM_CASES,
              PERMISSIONS.SEE_APPROVED_CM_CASES,
              PERMISSIONS.SUBMISSIONS_SEE_OTHERS,
              PERMISSIONS.SUBMISSIONS_UPDATE_OTHERS,
              PERMISSIONS.MANAGE_PROFILE,
              PERMISSIONS.CM_IDENTITY_VERIFICATIONS,
            ]}
          >
            <CaseManagementLink />
          </Protected>
          <Protected
            check={[
              PERMISSIONS.FORMS_CREATE,
              PERMISSIONS.FORMS_PUBLISH,
              PERMISSIONS.FORMS_UPDATE,
            ]}
          >
            <Dropdown icon="windowGear" text="Flow Builders">
              <GroupContainer>
                <Protected
                  check={[
                    PERMISSIONS.FORMS_CREATE,
                    PERMISSIONS.FORMS_PUBLISH,
                    PERMISSIONS.FORMS_UPDATE,
                  ]}
                >
                  <Link
                    to={`/${client_id}/builder/verification_flows`}
                    text="Verification Flows"
                  />
                </Protected>
                <Protected
                  check={[PERMISSIONS.FORMS_CREATE, PERMISSIONS.FORMS_UPDATE]}
                >
                  <Link
                    to={`/${client_id}/builder/forms`}
                    text="Form Builder"
                  />
                </Protected>
              </GroupContainer>
            </Dropdown>
          </Protected>
          <Protected check={[PERMISSIONS.FORMS_MAPPINGS]}>
            <Dropdown icon="mapping" text="Form Mappings">
              <GroupContainer>
                <Protected
                  check={[
                    PERMISSIONS.FORMS_CREATE,
                    PERMISSIONS.FORMS_PUBLISH,
                    PERMISSIONS.FORMS_UPDATE,
                  ]}
                >
                  <Link
                    to={`/${client_id}/builder/form_mappings/mappings`}
                    text="Mappings"
                  />
                </Protected>
                <Link
                  to={`/${client_id}/builder/form_mappings/exports`}
                  text="Exports"
                />
                <Link
                  to={`/${client_id}/builder/form_mappings/addresses`}
                  text="Addresses"
                />
                <Link
                  to={`/${client_id}/builder/form_mappings/account_statuses`}
                  text="Account Statuses"
                />
                <Link
                  to={`/${client_id}/builder/form_mappings/companies`}
                  text="Companies"
                />
                <Link
                  to={`/${client_id}/builder/form_mappings/idv_results`}
                  text="IDV Results"
                />
                <Link
                  to={`/${client_id}/builder/form_mappings/onboarding_flows`}
                  text="Onboarding Flows"
                />
                <Link
                  to={`/${client_id}/builder/form_mappings/periodic_reviews`}
                  text="Periodic Reviews"
                />
                <Link
                  to={`/${client_id}/builder/form_mappings/persons`}
                  text="Persons"
                />
                <Link
                  to={`/${client_id}/builder/form_mappings/risk_assessments`}
                  text="Risk Assessments"
                />
                <Link
                  to={`/${client_id}/builder/form_mappings/screening_checks`}
                  text="Screening Checks"
                />
              </GroupContainer>
            </Dropdown>
          </Protected>
        </GroupContainer>
      </Protected>
      <Protected
        check={[
          PERMISSIONS.SEE_TM,
          PERMISSIONS.MANAGE_TM,
          PERMISSIONS.SEE_MONITORING_ALERTS,
          PERMISSIONS.SEE_SCREENING_ALERTS,
          PERMISSIONS.MANAGE_TM_SCORING_RULES,
          PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
          PERMISSIONS.FILL_SAR_REPORT,
          PERMISSIONS.MANAGE_SAR_REPORT,
        ]}
      >
        <Protected
          check={[
            PERMISSIONS.SEE_TM,
            PERMISSIONS.MANAGE_TM,
            PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
          ]}
        >
          <TitleLink to="" text="Transaction monitoring" />
        </Protected>
        <Dropdown icon="transactions" text="Transactions">
          <GroupContainer>
            <Protected
              check={[
                PERMISSIONS.SEE_TM,
                PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
              ]}
            >
              <TransactionCasesLink />
            </Protected>
            <Protected
              check={[
                PERMISSIONS.SEE_MONITORING_ALERTS,
                PERMISSIONS.SEE_SCREENING_ALERTS,
                PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
              ]}
            >
              <TransactionAlertsLink />
            </Protected>
            <Protected
              check={[
                PERMISSIONS.SEE_CASE_RELATED_TRANSACTIONS,
                PERMISSIONS.SEE_TM,
              ]}
            >
              <Link
                to={`/${client_id}/transaction/monitoring`}
                text="Transaction List"
              />
            </Protected>
            <Protected check={[PERMISSIONS.MANAGE_TM]}>
              <Link to={`/${client_id}/transaction/rules`} text="Rules" />
            </Protected>
            <Protected
              check={[
                PERMISSIONS.FILL_SAR_REPORT,
                PERMISSIONS.MANAGE_SAR_REPORT,
              ]}
            >
              <Link to={`/${client_id}/transaction/sar`} text="FinCEN SAR" />
            </Protected>
            <Protected check={[PERMISSIONS.MANAGE_TM, PERMISSIONS.SEE_TM]}>
              <TMSettingsLink />
            </Protected>
            <Protected check={[PERMISSIONS.MANAGE_TM_SCORING_RULES]}>
              <Link
                to={`/${client_id}/transaction/matrices`}
                text="TRX Risk Scores"
              />
            </Protected>
          </GroupContainer>
        </Dropdown>
      </Protected>
      <Protected
        check={[
          PERMISSIONS.CHECKUPS_PERFORM_INSTANT,
          PERMISSIONS.AML_BUSINESS,
          PERMISSIONS.AML_INDIVIDUAL,
          PERMISSIONS.BUS_REGISTRY,
          PERMISSIONS.ELLIPTIC,
          PERMISSIONS.UNIT_21_KYB,
          PERMISSIONS.UNIT_21_KYB_DOC,
          PERMISSIONS.UNIT_21_KYB_US,
          PERMISSIONS.UNIT_21_KYB_WORLD,
          PERMISSIONS.CA_AML_INDIVIDUAL,
          PERMISSIONS.CA_AML_BUSINESS,
          PERMISSIONS.FINCEN_LIST,
          PERMISSIONS.FINCEN_INDIVIDUAL,
          PERMISSIONS.FINCEN_BUSINESS,
          PERMISSIONS.BLACKLIST_BUSINESS,
          PERMISSIONS.BLACKLIST_INDIVIDUAL,
          PERMISSIONS.CLIENT_MANAGE,
          PERMISSIONS.SEE_GLOBAL_SEARCH_HISTORY,
        ]}
      >
        <GroupContainer>
          <Protected
            check={[
              PERMISSIONS.CLIENT_MANAGE,
              PERMISSIONS.CHECKUPS_PERFORM_INSTANT,
              PERMISSIONS.AML_BUSINESS,
              PERMISSIONS.AML_INDIVIDUAL,
              PERMISSIONS.BUS_REGISTRY,
              PERMISSIONS.ELLIPTIC,
              PERMISSIONS.UNIT_21_KYB,
              PERMISSIONS.UNIT_21_KYB_DOC,
              PERMISSIONS.UNIT_21_KYB_US,
              PERMISSIONS.UNIT_21_KYB_WORLD,
              PERMISSIONS.CA_AML_INDIVIDUAL,
              PERMISSIONS.CA_AML_BUSINESS,
              PERMISSIONS.FINCEN_LIST,
              PERMISSIONS.FINCEN_INDIVIDUAL,
              PERMISSIONS.FINCEN_BUSINESS,
              PERMISSIONS.BLACKLIST_BUSINESS,
              PERMISSIONS.BLACKLIST_INDIVIDUAL,
              PERMISSIONS.SEE_GLOBAL_SEARCH_HISTORY,
              PERMISSIONS.SEE_SHUFTIPRO_AML_BUSINESS,
              PERMISSIONS.SEE_SHUFTIPRO_AML_INDIVIDUAL,
            ]}
          >
            <TitleLink to="" text="Compliance toolset" />
          </Protected>
          <Protected check={[PERMISSIONS.SEE_GLOBAL_SEARCH_HISTORY]}>
            <Link
              icon="searchDetail"
              to={`/${client_id}/search/search_entries`}
              text="Global Search"
            />
          </Protected>
          <Protected
            check={[
              PERMISSIONS.CHECKUPS_PERFORM_INSTANT,
              PERMISSIONS.AML_BUSINESS,
              PERMISSIONS.AML_INDIVIDUAL,
              PERMISSIONS.BUS_REGISTRY,
              PERMISSIONS.ELLIPTIC,
              PERMISSIONS.UNIT_21_KYB,
              PERMISSIONS.UNIT_21_KYB_DOC,
              PERMISSIONS.UNIT_21_KYB_US,
              PERMISSIONS.UNIT_21_KYB_WORLD,
              PERMISSIONS.CA_AML_INDIVIDUAL,
              PERMISSIONS.CA_AML_BUSINESS,
              PERMISSIONS.FINCEN_LIST,
              PERMISSIONS.FINCEN_INDIVIDUAL,
              PERMISSIONS.FINCEN_BUSINESS,
              PERMISSIONS.BLACKLIST_BUSINESS,
              PERMISSIONS.BLACKLIST_INDIVIDUAL,
              PERMISSIONS.SEE_SHUFTIPRO_AML_BUSINESS,
              PERMISSIONS.SEE_SHUFTIPRO_AML_INDIVIDUAL,
            ]}
          >
            <Dropdown icon="windowSearch" text="Screenings">
              <GroupContainer>
                <Protected
                  check={[
                    PERMISSIONS.CHECKUPS_PERFORM_INSTANT,
                    PERMISSIONS.AML_BUSINESS,
                    PERMISSIONS.AML_INDIVIDUAL,
                    PERMISSIONS.BUS_REGISTRY,
                    PERMISSIONS.ELLIPTIC,
                    PERMISSIONS.UNIT_21_KYB,
                    PERMISSIONS.UNIT_21_KYB_DOC,
                    PERMISSIONS.UNIT_21_KYB_US,
                    PERMISSIONS.UNIT_21_KYB_WORLD,
                    PERMISSIONS.CA_AML_INDIVIDUAL,
                    PERMISSIONS.CA_AML_BUSINESS,
                    PERMISSIONS.FINCEN_LIST,
                    PERMISSIONS.FINCEN_INDIVIDUAL,
                    PERMISSIONS.FINCEN_BUSINESS,
                    PERMISSIONS.BLACKLIST_BUSINESS,
                    PERMISSIONS.BLACKLIST_INDIVIDUAL,
                    PERMISSIONS.SEE_SHUFTIPRO_AML_BUSINESS,
                    PERMISSIONS.SEE_SHUFTIPRO_AML_INDIVIDUAL,
                  ]}
                >
                  <Link
                    to={`/${client_id}/checkup/checkups_kinds`}
                    text="Screenings"
                  />
                </Protected>

                <Protected check={[PERMISSIONS.CHECKUPS_PERFORM_INSTANT]}>
                  <Link
                    to={`/${client_id}/checkup/instant_checkups/all`}
                    text="Screening Management"
                  />
                </Protected>
                <Protected check={PERMISSIONS.CA_AML_INDIVIDUAL}>
                  <Link
                    to={`/${client_id}/checkup/instant_checkups/comply_advantage_aml`}
                    text="AML For Individual"
                  />
                </Protected>
                <Protected check={PERMISSIONS.CA_AML_BUSINESS}>
                  <Link
                    to={`/${client_id}/checkup/instant_checkups/comply_advantage_aml_business`}
                    text="AML For Business"
                  />
                </Protected>
                <Protected check={PERMISSIONS.FINCEN_INDIVIDUAL}>
                  <Link
                    to={`/${client_id}/checkup/instant_checkups/fincen_person`}
                    text="FinCEN 314(a) Individual"
                  />
                </Protected>
                <Protected check={PERMISSIONS.FINCEN_BUSINESS}>
                  <Link
                    to={`/${client_id}/checkup/instant_checkups/fincen_business`}
                    text="FinCEN 314(a) Business"
                  />
                </Protected>
                <Protected check={PERMISSIONS.BLACKLIST_INDIVIDUAL}>
                  <Link
                    to={`/${client_id}/checkup/instant_checkups/blacklisted_profiles_person`}
                    text="A.ID Blacklist Individual"
                  />
                </Protected>
                <Protected check={PERMISSIONS.BLACKLIST_BUSINESS}>
                  <Link
                    to={`/${client_id}/checkup/instant_checkups/blacklisted_profiles_business`}
                    text="A.ID Blacklist Business"
                  />
                </Protected>
                <Protected check={PERMISSIONS.BUS_REGISTRY}>
                  <Link
                    to={`/${client_id}/checkup/instant_checkups/shuftipro_kyb`}
                    text="Company Registries"
                  />
                </Protected>
                <Protected check={PERMISSIONS.ELLIPTIC}>
                  <Link
                    to={`/${client_id}/checkup/instant_checkups/elliptic_wallet`}
                    text="Cryptocurrency Toolset"
                  />
                </Protected>
                <Protected check={PERMISSIONS.SEE_SHUFTIPRO_AML_INDIVIDUAL}>
                  <Link
                    to={`/${client_id}/checkup/instant_checkups/shuftipro_aml`}
                    text="AML For Individual (Shuftipro)"
                  />
                </Protected>
                <Protected check={PERMISSIONS.SEE_SHUFTIPRO_AML_BUSINESS}>
                  <Link
                    to={`/${client_id}/checkup/instant_checkups/shuftipro_aml_business`}
                    text="AML For Business (Shuftipro)"
                  />
                </Protected>
              </GroupContainer>
            </Dropdown>
          </Protected>
          <Protected
            check={[
              PERMISSIONS.MANAGE_BLACKLIST_PROFILES,
              PERMISSIONS.FINCEN_LIST,
              PERMISSIONS.CLIENT_MANAGE,
              PERMISSIONS.MANAGE_PROHIBITED_COUNTRIES,
              PERMISSIONS.MANAGE_BLACKLISTED_DEVICE_INFO,
              PERMISSIONS.MANAGE_STOP_WORD_FINCEN,
            ]}
          >
            <Dropdown icon="tools" text="Tools">
              <GroupContainer>
                <Protected
                  check={[
                    PERMISSIONS.MANAGE_BLACKLIST_PROFILES,
                    PERMISSIONS.FINCEN_LIST,
                    PERMISSIONS.CLIENT_MANAGE,
                    PERMISSIONS.MANAGE_PROHIBITED_COUNTRIES,
                    PERMISSIONS.MANAGE_BLACKLISTED_DEVICE_INFO,
                    PERMISSIONS.MANAGE_STOP_WORD_FINCEN,
                    PERMISSIONS.MANAGE_DICTIONARIES,
                    PERMISSIONS.MANAGE_CUSTOMER_DOCUMENTS,
                    PERMISSIONS.MANAGE_CUSTOMER_MESSAGES,
                    PERMISSIONS.SEE_CUSTOMER_MESSAGES,
                    PERMISSIONS.MANAGE_EMAIL_TEMPLATES,
                  ]}
                >
                  <Protected check={[PERMISSIONS.MANAGE_DICTIONARIES]}>
                    <Link
                      to={`/${client_id}/builder/client_dictionaries`}
                      text="Dictionaries"
                    />
                  </Protected>
                  <Protected check={PERMISSIONS.MANAGE_BLACKLIST_PROFILES}>
                    <Link
                      to={`/${client_id}/profile/blacklist`}
                      text="Blacklist"
                    />
                  </Protected>
                  <Protected check={PERMISSIONS.MANAGE_BLACKLISTED_DEVICE_INFO}>
                    <Link
                      to={`/${client_id}/checkup/blacklisted_device_infos`}
                      text="Blacklist Device (IP)"
                    />
                  </Protected>
                  <Protected check={PERMISSIONS.MANAGE_PROHIBITED_COUNTRIES}>
                    <Link
                      to={`/${client_id}/checkup/prohibited_countries`}
                      text="Prohibited Countries"
                    />
                  </Protected>
                  <Protected check={PERMISSIONS.FINCEN_LIST}>
                    <Link
                      to={`/${client_id}/checkup/fincen_lists`}
                      text="FinCEN 314(a) Mass Screenings"
                    />
                  </Protected>
                  <Protected check={PERMISSIONS.CLIENT_MANAGE}>
                    <Link
                      to={`/${client_id}/checkup/translations`}
                      text="Translations"
                    />
                  </Protected>
                  <Protected check={PERMISSIONS.MANAGE_STOP_WORD_FINCEN}>
                    <Link
                      to={`/${client_id}/checkup/stop_words`}
                      text="Mass Screening Ignored Words"
                    />
                  </Protected>
                  <Protected check={PERMISSIONS.MANAGE_CUSTOMER_DOCUMENTS}>
                    <Link
                      to={`/${client_id}/verification/customer_documents`}
                      text="Customer Documents"
                    />
                  </Protected>
                  <Protected
                    check={[
                      PERMISSIONS.SEE_CUSTOMER_MESSAGES,
                      PERMISSIONS.MANAGE_CUSTOMER_MESSAGES,
                      PERMISSIONS.MANAGE_EMAIL_TEMPLATES,
                    ]}
                  >
                    <EmailsLink />
                  </Protected>
                </Protected>
              </GroupContainer>
            </Dropdown>
          </Protected>
          <Protected
            check={[
              PERMISSIONS.MANAGE_BLACKLIST_PROFILES,
              PERMISSIONS.MANAGE_RESOLUTION_ITEMS,
              PERMISSIONS.MANAGE_CASE_REJECTION_REASONS,
              PERMISSIONS.MANAGE_ADDITIONAL_RISK_SCORING_TEMPLATES,
              PERMISSIONS.SEE_TM,
            ]}
          >
            <Dropdown icon="customisation" text="Customisation">
              <GroupContainer>
                <Protected
                  check={[
                    PERMISSIONS.MANAGE_BLACKLIST_PROFILES,
                    PERMISSIONS.MANAGE_RESOLUTION_ITEMS,
                    PERMISSIONS.MANAGE_CASE_REJECTION_REASONS,
                    PERMISSIONS.MANAGE_ADDITIONAL_RISK_SCORING_TEMPLATES,
                    PERMISSIONS.SEE_TM,
                    PERMISSIONS.CLIENT_MANAGE,
                  ]}
                >
                  <Protected check={[PERMISSIONS.MANAGE_BLACKLIST_PROFILES]}>
                    <Link
                      to={`/${client_id}/account/blacklist_reasons`}
                      text="Blacklist Reasons"
                    />
                  </Protected>
                  <Protected check={PERMISSIONS.MANAGE_RESOLUTION_ITEMS}>
                    <Link
                      to={`/${client_id}/account/resolutions`}
                      text="Screening Resolutions"
                    />
                  </Protected>
                  <Protected check={PERMISSIONS.MANAGE_CASE_REJECTION_REASONS}>
                    <Link
                      to={`/${client_id}/account/case_rejection_reasons`}
                      text="Rejection Reasons"
                    />
                  </Protected>
                  <Protected
                    check={PERMISSIONS.MANAGE_ADDITIONAL_RISK_SCORING_TEMPLATES}
                  >
                    <Link
                      to={`/${client_id}/account/risk_scoring_templates`}
                      text="Risk Scoring Templates"
                    />
                  </Protected>
                  <Protected check={PERMISSIONS.CLIENT_MANAGE}>
                    <Link to={`/${client_id}/account/form_tags`} text="Tags" />
                  </Protected>

                  <Protected check={[PERMISSIONS.REMINDER_SETTINGS]}>
                    <RemindersLink />
                  </Protected>
                </Protected>
              </GroupContainer>
            </Dropdown>
          </Protected>
        </GroupContainer>
      </Protected>
      <Protected
        check={[
          PERMISSIONS.CLIENT_INVITE_USERS,
          PERMISSIONS.CLIENT_MANAGE,
          PERMISSIONS.SEE_BALANCE,
          PERMISSIONS.REFILL_BALANCE,
          PERMISSIONS.REMINDER_SETTINGS,
        ]}
      >
        <GroupContainer>
          <Protected
            check={[
              PERMISSIONS.CLIENT_MANAGE,
              PERMISSIONS.SEE_BALANCE,
              PERMISSIONS.REFILL_BALANCE,
              PERMISSIONS.REMINDER_SETTINGS,
              PERMISSIONS.MANAGE_CLIENT_SUBSCRIPTIONS,
            ]}
          >
            <TitleLink to="" text="Client settings" />
          </Protected>
          <Protected
            check={[PERMISSIONS.CLIENT_MANAGE, PERMISSIONS.CLIENT_INVITE_USERS]}
          >
            <UsersLink />
          </Protected>
          <Protected check={PERMISSIONS.MANAGE_DATA_RETENTION}>
            <Link
              to={`/${client_id}/account/data/retention_configs`}
              icon="hosting"
              text="Data Retention"
            />
          </Protected>
          <Protected check={PERMISSIONS.MANAGE_DATA_SHARING}>
            <SharingLink />
          </Protected>
          <Protected check={PERMISSIONS.CLIENT_MANAGE}>
            <Link
              to={`/${client_id}/account/roles`}
              icon="permissions"
              text="Roles"
            />
          </Protected>
          <Protected
            check={[PERMISSIONS.SEE_BALANCE, PERMISSIONS.REFILL_BALANCE]}
          >
            <Link
              to={`/${client_id}/account/balance`}
              icon="payment"
              text="Payments & Billing"
            />
          </Protected>
          {ENABLED_PRICING && (
            <Protected check={[PERMISSIONS.MANAGE_CLIENT_SUBSCRIPTIONS]}>
              <Link
                to={`/${client_id}/pricing/subscription`}
                icon="payment"
                text="Subscription"
              />
            </Protected>
          )}
          <Link
            external={true}
            target="_blank"
            to="https://a-id.helpsite.com/"
            icon="question"
            text="Help"
          />
        </GroupContainer>
      </Protected>
      <Protected check={[PERMISSIONS.ADMIN]}>
        <GroupContainer>
          <TitleLink to="" text="Administrator tools" />
          <Link
            to={`/${client_id}/admin/users`}
            icon="users"
            text="Platform Users"
          />
          <Link
            to={`/${client_id}/admin/clients`}
            icon="windowChart"
            text="Client Management"
          />
          <Dropdown icon="checkups" text="Screenings Settings">
            <GroupContainer>
              <Link
                to={`/${client_id}/admin/checkup_service/providers`}
                text="Providers"
              />
              <Link
                to={`/${client_id}/admin/checkup_service/checkup_kinds`}
                text="Screenings"
              />
              <Link
                to={`/${client_id}/admin/checkup_service/clients_providers`}
                text="Clients Providers"
              />
              <Link
                to={`/${client_id}/admin/checkup_service/checkup_kinds_clients`}
                text="Screenings Clients"
              />
            </GroupContainer>
          </Dropdown>
          <Dropdown icon="templates" text="Templates">
            <GroupContainer>
              <Link
                to={`/${client_id}/admin/field_templates`}
                text="Form Field"
              />
            </GroupContainer>
          </Dropdown>
          <Dropdown icon="tools" text="Settings">
            <GroupContainer>
              <Link to={`/${client_id}/admin/regions`} text="Regions" />
            </GroupContainer>
          </Dropdown>
          {ENABLED_PRICING && (
            <Dropdown icon="payment" text="Pricing">
              <GroupContainer>
                <Link to={`/${client_id}/admin/pricing/plans`} text="Plans" />
                <Link
                  to={`/${client_id}/admin/pricing/features`}
                  text="Features"
                />
                <Link
                  to={`/${client_id}/admin/pricing/sections`}
                  text="Sections"
                />
              </GroupContainer>
            </Dropdown>
          )}
          <Link
            external={true}
            target="_blank"
            to={`${window.location.origin}/auth/admin`}
            icon="settings"
            text="Authentication tools"
          />
        </GroupContainer>
      </Protected>
    </Container>
  );
}
