import gql from 'graphql-tag';

export const Notifications = gql`
  query Notifications(
    $is_readed: Boolean
    $is_readed_modal: Boolean
    $page: Int
    $created_before: String
    $created_after: String
    $obj_type: NotificationObjectType
    $notification_type: NotificationType
    $case_states: [CaseStatus!]
    $sort_field: String
    $sort_order: String
    $client_id: String
    $q: String
  ) {
    notifications(
      page: $page
      is_readed: $is_readed
      is_readed_modal: $is_readed_modal
      per: 20
      created_before: $created_before
      created_after: $created_after
      obj_type: $obj_type
      notification_type: $notification_type
      case_states: $case_states
      sort_field: $sort_field
      sort_order: $sort_order
      client_id: $client_id
      q: $q
    ) @rest(type: "ResponseNotifications", path: "/nh/notifications?{args}") {
      data @type(name: "Notification") {
        created_at
        id
        is_readed
        obj_id
        obj_meta {
          display_name
        }
        display_name
        obj_type
        notification_type
        client @type(name: "Client") {
          name
          id
          logo_id
        }
        pinned
        payload {
          channel
          data {
            body
            body_attributes
            client_id
            notification_id
            obj_id
            obj_meta {
              display_name
            }
            obj_type
            notification_type
            title
          }
        }
        state
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const NotificationConfig = gql`
  query NotificationConfig {
    notificationConfig
      @rest(
        type: "ResponseNotificationConfig"
        path: "/nh/accounts/issue_ws_token"
      ) {
      data @type(name: "NotificationConfig") {
        channel
        token
      }
      success
    }
  }
`;

export const NotificationSubscriptionConfig = gql`
  query NotificationSubscriptionConfig {
    notificationSubscriptionConfig
      @rest(
        type: "ResponseNotificationConfig"
        path: "/nh/accounts/issue_ws_subscription_token"
      ) {
      data @type(name: "NotificationConfig") {
        channel
        token
      }
      success
    }
  }
`;

export const NotificationSettings = gql`
  query NotificationSettings {
    notificationSettings
      @rest(
        type: "ResponseNotificationSettings"
        path: "/nh/account_client_settings/current"
      ) {
      data @type(name: "NotificationSetting") {
        id
        email_enabled
        case_assignment_changed_enabled_email
        case_assignment_changed_enabled_ws
        case_state_changed_enabled_email
        case_state_changed_enabled_ws
        client_name
        client_id
        checkup_ca_match_status_callback_arrived_enabled_email
        checkup_ca_match_status_callback_arrived_enabled_ws
        idenfy_result_doc_expired_enabled_email
        idenfy_result_doc_expired_enabled_ws
        updated_at
        veriff_result_doc_expired_enabled_email
        veriff_result_doc_expired_enabled_ws
        ws_enabled
        case_reminder_executed_enabled_email
        case_reminder_executed_enabled_ws
        case_required_action_closed_enabled_email
        case_required_action_closed_enabled_ws
        case_required_action_opened_enabled_email
        case_required_action_opened_enabled_ws
        comment_mention_created_enabled_email
        comment_mention_created_enabled_ws
        edd_report_assigned_enabled_ws
        edd_report_assigned_enabled_email
        data_retention_config_updated_enabled_ws
        data_retention_config_updated_enabled_email
        data_retention_plan_updated_enabled_ws
        data_retention_plan_updated_enabled_email
        suspicious_activity_report_created_enabled_ws
        suspicious_activity_report_created_enabled_email
        suspicious_activity_report_status_change_enabled_ws
        suspicious_activity_report_status_change_enabled_email
      }
      success
    }
  }
`;
