import { useMemo } from 'react';
import { Text, Link, DefaultLink } from '../Text';
import { useToProfileController } from './controller';
import { ToProfileViewProps } from './types';

export function ToProfile({
  profile_id,
  text,
  weight = 'Bold',
  withoutIcon,
  disabled,
  ...props
}: ToProfileViewProps) {
  const { canShow, link } = useToProfileController(profile_id);

  if (canShow && link && !disabled) {
    if (withoutIcon) {
      return (
        <DefaultLink
          weight={weight}
          text={text || 'External profile'}
          {...props}
          to={link}
        />
      );
    }

    return (
      <Link
        weight={weight}
        text={text || 'External profile'}
        {...props}
        to={link}
      />
    );
  }

  return <Text text={text || 'External profile'} {...props} />;
}
