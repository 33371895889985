import {
  FEATURES,
  PERMISSIONS,
  useCheckAvailableFeature,
  useCheckPermission,
} from '@aid-package/context';
import { useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useInviteController() {
  const location = useLocation();
  const currentParams = useRef(new URLSearchParams(location.search));
  const { canShow } = useCheckPermission(PERMISSIONS.MANAGE_DATA_SHARING, true);
  const { canAvailabel: canCreatable } = useCheckAvailableFeature(
    FEATURES.DATA_SHARING
  );
  const [sharingKey, setSharingKey] = useState(
    currentParams.current.get('sharing_key')
  );

  const onRequestClose = useCallback(() => {
    setSharingKey(null);
    window.history.replaceState('', '', window.location.pathname);
  }, []);

  return { sharingKey, onRequestClose, canShow: canShow && canCreatable };
}
