import { useDataSharingAvailableClientsQuery } from '@aid-package/api';
import { useClientContext } from '@aid-package/context';
import { useMemo } from 'react';

export function useBodyModel(sharingKey: string) {
  const { client: account } = useClientContext();
  const { data, loading } = useDataSharingAvailableClientsQuery({
    variables: { id: sharingKey },
  });

  const isMultiple = useMemo(
    () => !!account?.accounts_clients?.length || false,
    [account]
  );

  return {
    clients: data?.dataSharingAvailableClients?.data,
    loading,
    account_id: account?.id || '',
    isMultiple,
  };
}
