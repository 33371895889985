import gql from 'graphql-tag';
import { PROFILE_FRAGMENT } from '../profiles/profiles';

export const CUSTOMER_FRAGMENT = gql`
  ${PROFILE_FRAGMENT}
  fragment CustomerFragment on Customer {
    avatar_id
    company_name
    created_at
    customer_verification_full_name
    email
    first_name
    full_name
    id
    last_name
    links
    phone
    profile @type(name: "Profile") {
      avatar_id
      created_at
      display_name
      email
      id
      is_whitelisted
      kind
      phone
      updated_at
    }
    region_code
    updated_at
  }
`;
