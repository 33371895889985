import {
  LoadableFunctionalComponent,
  TableWithHeaderLoader,
} from '@aid-module/ui';
import loadable from '@loadable/component';

export const OutgoingRegister = loadable(() => import('./component'), {
  resolveComponent: (components) => components.OutgoingRegisterComponent,
  fallback: <TableWithHeaderLoader />,
}) as LoadableFunctionalComponent<{}>;
