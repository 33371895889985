import { useMemo } from 'react';
import { ProfileCardViewProps } from '../../../../ProfileCard/types';
import { ExtendAccountType } from '../../../types';
import { ProfileKindLabels } from './utils';

export function useDefaultNameController<T extends ExtendAccountType>(
  account: ProfileCardViewProps<T>['account']
) {
  const name = useMemo(() => {
    if (account.__typename === 'Account' || account.__typename === 'Customer') {
      return account.full_name;
    } else if (account.__typename === 'User') {
      return account.account.full_name;
    } else if (
      account.__typename === 'Profile' ||
      account.__typename === 'ProjectionProfile'
    ) {
      return account.display_name;
    }
    return;
  }, [account]);

  const subtitle = useMemo(() => {
    if (account.__typename === 'Account' || account.__typename === 'User') {
      return account.role;
    } else if (
      account.__typename === 'Profile' ||
      account.__typename === 'ProjectionProfile'
    ) {
      return ProfileKindLabels[account.kind];
    }
    return;
  }, [account]);

  return { name, subtitle };
}
