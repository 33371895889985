import { Text } from '@aid-module/ui';
import { Link } from './components';
import { NotificationTextViewProps } from './types';
import {
  getCaseStatusColorByStatus,
  getRetentionPlanStatusColorByStatus,
} from './utils';

export function NotificationText({
  type,
  value,
  payloadData,
  size,
  withoutLinks,
}: NotificationTextViewProps) {
  if (type === 'dynamic') {
    if (payloadData.body_attributes?.[value]?.type === 'CaseStatus') {
      return (
        <Text
          text={payloadData.body_attributes[value]?.display_name}
          color={getCaseStatusColorByStatus(
            payloadData.body_attributes[value]?.code
          )}
          size={size}
          weight="Bold"
        />
      );
    }
    if (payloadData.body_attributes?.[value]?.type === 'PlanStatus') {
      return (
        <Text
          text={payloadData.body_attributes[value]?.display_name}
          color={getRetentionPlanStatusColorByStatus(
            payloadData.body_attributes[value]?.code
          )}
          size={size}
          weight="Bold"
        />
      );
    }
    if (withoutLinks) {
      return (
        <Text
          size={size}
          color="main-deep-4"
          text={payloadData?.body_attributes?.[value]?.display_name}
        />
      );
    }

    return <Link size={size} value={value} payloadData={payloadData} />;
  }

  return <Text text={value} size={size} />;
}
