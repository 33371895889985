import { gql } from '@apollo/client';

export const SaveSar = gql`
  mutation SaveSar($body: Any!) {
    saveSar(body: $body)
      @rest(
        type: "SaveSar"
        path: "/sar/suspicious_activity_reports"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "SarReport") {
        activity_type
        alerts_suspicious_activity_reports @type(name: "SarEventReport") {
          alert_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        amount
        assigned_at @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        assigned_at_id
        attachment_suspicious_activity_reports
        bsa_status
        bsa_tracking_id
        cases_suspicious_activity_reports @type(name: "SarCaseReport") {
          case_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        created_at
        created_by @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        created_by_id
        deadline
        events_suspicious_activity_reports @type(name: "SarEventReport") {
          event_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        filing_institution
        filing_name
        financial_institution
        id
        narrative
        note_to_fin_cen
        prior_report_bsa_id
        queue
        status
        subject
        suspicious_activity_information
        type_of_filings
        type_of_filings_id
        updated_at
      }
      success
    }
  }
`;

export const ChangeStateSar = gql`
  mutation ChangeStateSar($id: String!, $body: Any!) {
    changeStateSar(id: $id, body: $body)
      @rest(
        type: "SaveSar"
        path: "/sar/suspicious_activity_reports/{args.id}/change_status"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "SarReport") {
        activity_type
        alerts_suspicious_activity_reports @type(name: "SarEventReport") {
          alert_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        amount
        assigned_at @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        assigned_at_id
        attachment_suspicious_activity_reports
        bsa_status
        bsa_tracking_id
        cases_suspicious_activity_reports @type(name: "SarCaseReport") {
          case_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        created_at
        created_by @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        created_by_id
        deadline
        events_suspicious_activity_reports @type(name: "SarEventReport") {
          event_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        filing_institution
        filing_name
        financial_institution
        id
        narrative
        note_to_fin_cen
        prior_report_bsa_id
        queue
        status
        subject
        suspicious_activity_information
        type_of_filings
        type_of_filings_id
        updated_at
      }
      success
    }
  }
`;

export const UpdateSar = gql`
  mutation UpdateSar($id: String!, $body: Any!) {
    updateSar(id: $id, body: $body)
      @rest(
        type: "SaveSar"
        path: "/sar/suspicious_activity_reports/{args.id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "SarReport") {
        activity_type
        alerts_suspicious_activity_reports @type(name: "SarEventReport") {
          alert_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        amount
        assigned_at @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        assigned_at_id
        attachment_suspicious_activity_reports
        bsa_status
        bsa_tracking_id
        cases_suspicious_activity_reports @type(name: "SarCaseReport") {
          case_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        created_at
        created_by @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        created_by_id
        deadline
        events_suspicious_activity_reports @type(name: "SarEventReport") {
          event_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        filing_institution
        filing_name
        financial_institution
        id
        narrative
        note_to_fin_cen
        prior_report_bsa_id
        queue
        status
        subject
        suspicious_activity_information
        type_of_filings
        type_of_filings_id
        updated_at
      }
      success
    }
  }
`;

export const AssignSar = gql`
  mutation AssignSar($id: String!, $body: AssignSarBody!) {
    assignSar(id: $id, body: $body)
      @rest(
        type: "SaveSar"
        path: "/sar/suspicious_activity_reports/{args.id}/assigned_to"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "SarReport") {
        activity_type
        alerts_suspicious_activity_reports @type(name: "SarEventReport") {
          alert_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        amount
        assigned_at @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        assigned_at_id
        attachment_suspicious_activity_reports
        bsa_status
        bsa_tracking_id
        cases_suspicious_activity_reports @type(name: "SarCaseReport") {
          case_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        created_at
        created_by @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        created_by_id
        deadline
        events_suspicious_activity_reports @type(name: "SarEventReport") {
          event_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        filing_institution
        filing_name
        financial_institution
        id
        narrative
        note_to_fin_cen
        prior_report_bsa_id
        queue
        status
        subject
        suspicious_activity_information
        type_of_filings
        type_of_filings_id
        updated_at
      }
      success
    }
  }
`;

export const SendSar = gql`
  mutation SendSar($id: String!) {
    sendSar(id: $id, body: ".")
      @rest(
        type: "SaveSar"
        path: "/sar/suspicious_activity_reports/{args.id}/send_report"
        bodyKey: "body"
        bodySerializer: "empty"
        method: "POST"
      ) {
      data @type(name: "SarReport") {
        activity_type
        alerts_suspicious_activity_reports @type(name: "SarEventReport") {
          alert_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        amount
        assigned_at @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        assigned_at_id
        attachment_suspicious_activity_reports
        bsa_status
        bsa_tracking_id
        cases_suspicious_activity_reports @type(name: "SarCaseReport") {
          case_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        created_at
        created_by @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        created_by_id
        deadline
        events_suspicious_activity_reports @type(name: "SarEventReport") {
          event_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        filing_institution
        filing_name
        financial_institution
        id
        narrative
        note_to_fin_cen
        prior_report_bsa_id
        queue
        status
        subject
        suspicious_activity_information
        type_of_filings
        type_of_filings_id
        updated_at
      }
      success
    }
  }
`;
