import { HeaderTableViewProps } from './types';
import { Container, BulkContainer } from './styles';
import { TableComponentViewProps } from '../ExtensionAsyncTable/component/types';
import { useHeaderTableController } from './controller';
import {
  ColumnsProviders,
  ConfigContext,
  PagginationProvider,
  VariablesProviders,
  VirtualizerContextProvider,
  EditProvider,
} from '../context';
import { Header, Edit } from './components';
import { VariablesProvidersViewProps } from '../context';
import { ReactElement, ReactNode, useMemo } from 'react';
import { FilterTemplatesProvider } from '../context/FilterTemplates';
import { TableWithPagginationComponentViewProps } from '../ExtensionAsyncTableWithPaggination/component/types';
import { PagginationProviderViewProps } from '../context/Paggination/types';
import { ExtensionAsyncTableWithPaggination } from '../ExtensionAsyncTableWithPaggination';
import { ExtensionAsyncTable } from '../ExtensionAsyncTable';
import { ColumnsProvidersViewProps } from '../context/Columns/types';
import { SideWidgenProvider } from '../../SideWidget';
import { TableColumn } from '../extensionComponents/types';

const bulkColumns: Record<string, TableColumn<any>> = {
  bulk: {
    header: '',
    getValue: (data: any) => data?.id && <Edit item={data} id={data?.id} />,
    key: 'bulk',
    minwidth: 36,
    maxwidth: 36,
    fixPosition: true,
  },
};

export function ExtensionHeaderTableWithPaggination<T>({
  label,
  searchable,
  filtersConfig,
  creatable,
  createLabel,
  createRef,
  href,
  subHeader,
  defaultVariables,
  parseSort,
  subLabel,
  search,
  filterTemplates,
  cacheKey,
  withPaggination,
  query,
  columnsKey,
  parseVariables,
  columns,
  bulkController,
  bulkConfig,
  canCreatable,
  ...props
}: HeaderTableViewProps & {
  bulkController?: ReactNode;
} & TableWithPagginationComponentViewProps<T> &
  PagginationProviderViewProps &
  ColumnsProvidersViewProps &
  VariablesProvidersViewProps & { fallback?: ReactElement }) {
  const { ref, onScroll } = useHeaderTableController();

  const updatedColumns = useMemo(
    () => (bulkController ? { ...bulkColumns, ...columns } : columns),
    [bulkController]
  );

  return (
    <ColumnsProviders columnsKey={columnsKey}>
      <VirtualizerContextProvider>
        <EditProvider bulkConfig={bulkConfig}>
          <FilterTemplatesProvider code={filterTemplates}>
            <ConfigContext.Provider value={filtersConfig}>
              <VariablesProviders
                defaultVariables={defaultVariables}
                parseSort={parseSort}
                cacheKey={cacheKey}
              >
                <PagginationProvider
                  query={query}
                  parseVariables={parseVariables}
                >
                  <SideWidgenProvider>
                    <Container flex={1} flexDirection="column">
                      <Header
                        ref={ref}
                        label={label}
                        canCreatable={canCreatable}
                        searchable={searchable}
                        creatable={creatable}
                        createLabel={createLabel}
                        createRef={createRef || href}
                        subHeader={subHeader}
                        subLabel={subLabel}
                        search={search}
                        columns={updatedColumns}
                        canShowColumns={!!columnsKey}
                      />
                      <Container flex={1}>
                        <ExtensionAsyncTableWithPaggination
                          onScroll={onScroll.current}
                          {...props}
                          columns={updatedColumns}
                          href={href}
                        />
                        {bulkController ? (
                          <BulkContainer justifyContent="center">
                            {bulkController}
                          </BulkContainer>
                        ) : null}
                      </Container>
                    </Container>
                  </SideWidgenProvider>
                </PagginationProvider>
              </VariablesProviders>
            </ConfigContext.Provider>
          </FilterTemplatesProvider>
        </EditProvider>
      </VirtualizerContextProvider>
    </ColumnsProviders>
  );
}

export function ExtensionHeaderTable<T>({
  label,
  searchable,
  filtersConfig,
  creatable,
  createLabel,
  createRef,
  href,
  subHeader,
  defaultVariables,
  parseSort,
  subLabel,
  search,
  filterTemplates,
  cacheKey,
  columnsKey,
  columns,
  canCreatable,
  createDeniedText,
  ...props
}: HeaderTableViewProps &
  TableComponentViewProps<T> &
  ColumnsProvidersViewProps &
  VariablesProvidersViewProps & { fallback?: ReactElement }) {
  const { ref, onScroll } = useHeaderTableController();
  return (
    <ColumnsProviders columnsKey={columnsKey}>
      <VirtualizerContextProvider>
        <FilterTemplatesProvider code={filterTemplates}>
          <ConfigContext.Provider value={filtersConfig}>
            <VariablesProviders
              defaultVariables={defaultVariables}
              parseSort={parseSort}
              cacheKey={cacheKey}
            >
              <SideWidgenProvider>
                <Container flex={1} flexDirection="column">
                  <Header
                    ref={ref}
                    label={label}
                    searchable={searchable}
                    canCreatable={canCreatable}
                    createDeniedText={createDeniedText}
                    creatable={creatable}
                    createLabel={createLabel}
                    createRef={createRef || href}
                    subHeader={subHeader}
                    subLabel={subLabel}
                    search={search}
                    columns={columns}
                    canShowColumns={!!columnsKey}
                  />
                  <Container flex={1}>
                    <ExtensionAsyncTable
                      onScroll={onScroll.current}
                      {...props}
                      columns={columns}
                      href={href}
                    />
                  </Container>
                </Container>
              </SideWidgenProvider>
            </VariablesProviders>
          </ConfigContext.Provider>
        </FilterTemplatesProvider>
      </VirtualizerContextProvider>
    </ColumnsProviders>
  );
}

export { Columns, DefaultBulkComponent } from './components';
