import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AlertsNavigationItems,
  CaseManagementNavigationItems,
  DashboardsNavigationItems,
  EmailsNavigationItems,
  TMSettingsItems,
  TmCasesNavigationItems,
  UsersNavigationItems,
  RemindersNavigationItems,
  DataRetentionNavigationItems,
  SharingNavigationItems,
} from '../constants';
import { BreadcrumbsRoutes } from './constants';
import { BreadcrumbItem } from './types';

export function useBreadcrumbsController() {
  const { pathname } = useLocation();

  const breadcrumbsItems = useMemo(() => {
    const arrayPathNames = pathname.split('/');
    return arrayPathNames.reduce(
      (acc: BreadcrumbItem[], name: string, index) => {
        const path = [...arrayPathNames].slice(0, index + 1).join('/');
        const pathName: keyof typeof BreadcrumbsRoutes = name;

        if (name === 'cases' && arrayPathNames[2] === 'transaction') {
          return [...acc, { ...BreadcrumbsRoutes['investigations'], path }];
        }

        if (BreadcrumbsRoutes[pathName]) {
          if (name === 'admin' || name === 'management') {
            if (arrayPathNames[index + 1]) {
              return [...acc, { ...BreadcrumbsRoutes[pathName], path }];
            }
            return acc;
          }
          if (
            name === 'mass_screenings_by_fincen_id' ||
            name === 'mass_screenings'
          ) {
            const path = [...arrayPathNames].slice(0, index + 2).join('/');
            const route = BreadcrumbsRoutes[pathName];

            return [...acc, { name: route.name, path }];
          }
          if (name === 'instant_checkups') {
            if (arrayPathNames[index + 1] || acc.length > 0) {
              return [...acc, { ...BreadcrumbsRoutes[pathName], path }];
            }
            return acc;
          }
          if (name === 'form' && !arrayPathNames[index + 1]) {
            const prevRoute = BreadcrumbsRoutes[arrayPathNames[index - 1]];
            const currentName =
              prevRoute?.formName || BreadcrumbsRoutes[pathName].name;
            return [...acc, { name: currentName, path }];
          } else if (name !== 'form' || !arrayPathNames[index + 1]) {
            return [...acc, { ...BreadcrumbsRoutes[pathName], path }];
          } else {
            const path = [...arrayPathNames].slice(0, index + 2).join('/');
            const prevRoute = BreadcrumbsRoutes[arrayPathNames[index - 1]];
            const currentName = prevRoute?.infoName || 'Information';

            return [...acc, { name: currentName, path }];
          }
        } else if (name) {
          const pathNamesWithDetail = [
            'verifications',
            'submissions',
            'outgoing',
            'incoming',
            'edd_reports',
            'checkup_kinds_clients',
            'providers',
            'checkup_kinds',
            'clients_providers',
            'monitoring',
            'instant_checkups',
            'checkups',
            'users',
            'invites',
            'cases',
            'cases_queues',
            'my_cases',
            'regions',
            'profiles',
            'cm',
            'identity_verifications',
            'verification_flows',
            'alerts',
            'my_alerts',
            'alerts_queues',
            'mappings',
            'customer_documents',
            'companies',
            'persons',
            'blacklist_groups',
          ];
          if (pathNamesWithDetail.includes(arrayPathNames[index - 1])) {
            const path = [...arrayPathNames].slice(0, index + 2).join('/');
            const prevRoute = BreadcrumbsRoutes[arrayPathNames[index - 1]];
            const currentName = prevRoute?.formName || 'Information';

            return [...acc, { name: currentName, path }];
          }
        }
        return acc;
      },
      []
    );
  }, [pathname]);

  const isCaseManagementNavigation = useMemo(() => {
    const arrayPathNames = pathname.split('/');

    const caseManagemntsNavNames = CaseManagementNavigationItems.map(
      (item) => item.name
    );

    if (
      arrayPathNames[2] === 'transaction' ||
      arrayPathNames[2] === 'builder' ||
      breadcrumbsItems.length !== 1
    ) {
      return false;
    }
    return caseManagemntsNavNames.includes(breadcrumbsItems[0].name || '');
  }, [breadcrumbsItems, pathname]);

  const isUsersNavigation = useMemo(() => {
    const arrayPathNames = pathname.split('/');
    const path = `/${[...arrayPathNames].slice(2).join('/')}`;
    const usersNavPaths = UsersNavigationItems.reduce<Record<string, boolean>>(
      (acc, item) => ({ ...acc, [item.path]: true }),
      {}
    );

    return !!usersNavPaths[path];
  }, [pathname]);

  const isDashboardsNavigation = useMemo(() => {
    const arrayPathNames = pathname.split('/');
    const path = `/${[...arrayPathNames].slice(2).join('/')}`;
    const dashboardsNavPaths = DashboardsNavigationItems.reduce<
      Record<string, boolean>
    >((acc, item) => ({ ...acc, [item.path]: true }), {});

    return !!dashboardsNavPaths[path];
  }, [pathname]);

  const isAlertsNavigation = useMemo(() => {
    const arrayPathNames = pathname.split('/');
    const path = `/${[...arrayPathNames].slice(2).join('/')}`;
    const alertsNavPaths = AlertsNavigationItems.reduce<
      Record<string, boolean>
    >((acc, item) => ({ ...acc, [item.path]: true }), {});

    return !!alertsNavPaths[path];
  }, [pathname]);

  const isTmCasesNavigation = useMemo(() => {
    const arrayPathNames = pathname.split('/');
    const path = `/${[...arrayPathNames].slice(2).join('/')}`;
    const TmCaseNavPaths = TmCasesNavigationItems.reduce<
      Record<string, boolean>
    >((acc, item) => ({ ...acc, [item.path]: true }), {});

    return !!TmCaseNavPaths[path];
  }, [pathname]);

  const isTMSettingsNavigation = useMemo(() => {
    const arrayPathNames = pathname.split('/');
    const path = `/${[...arrayPathNames].slice(2).join('/')}`;
    const TMSettingsPaths = TMSettingsItems.reduce<Record<string, boolean>>(
      (acc, item) => ({ ...acc, [item.path]: true }),
      {}
    );

    return !!TMSettingsPaths[path];
  }, [pathname]);

  const isRemindersNavigation = useMemo(() => {
    const arrayPathNames = pathname.split('/');
    const path = `/${[...arrayPathNames].slice(2).join('/')}`;
    const remindersPaths = RemindersNavigationItems.reduce<
      Record<string, boolean>
    >((acc, item) => ({ ...acc, [item.path]: true }), {});

    return !!remindersPaths[path];
  }, [pathname]);

  const isEmailsNavigation = useMemo(() => {
    const arrayPathNames = pathname.split('/');
    const path = `/${[...arrayPathNames].slice(2).join('/')}`;
    const EmailsPaths = EmailsNavigationItems.reduce<Record<string, boolean>>(
      (acc, item) => ({ ...acc, [item.path]: true }),
      {}
    );

    return !!EmailsPaths[path];
  }, [pathname]);

  const isDataRetentionNavigation = useMemo(() => {
    const arrayPathNames = pathname.split('/');
    const path = `/${[...arrayPathNames].slice(2).join('/')}`;
    const DataRetentionPaths = DataRetentionNavigationItems.reduce<
      Record<string, boolean>
    >((acc, item) => ({ ...acc, [item.path]: true }), {});

    return !!DataRetentionPaths[path];
  }, [pathname]);

  const isSharingNavigation = useMemo(() => {
    const arrayPathNames = pathname.split('/');
    const path = `/${[...arrayPathNames].slice(2).join('/')}`;
    const SharingPaths = SharingNavigationItems.reduce<Record<string, boolean>>(
      (acc, item) => ({ ...acc, [item.path]: true }),
      {}
    );

    return !!SharingPaths[path];
  }, [pathname]);

  return {
    breadcrumbsItems,
    isCaseManagementNavigation,
    isDashboardsNavigation,
    isTmCasesNavigation,
    isAlertsNavigation,
    isTMSettingsNavigation,
    isUsersNavigation,
    isEmailsNavigation,
    isRemindersNavigation,
    isDataRetentionNavigation,
    isSharingNavigation,
  };
}
