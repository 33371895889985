import {
  Card,
  ContentLoader,
  GridContainer,
  SecondaryButton,
  Text,
} from '@aid-module/ui';
import { BodyViewProps } from './types';
import { useBodyController } from './controller';
import { ClientPicker } from './components';

export function Body({ sharingKey, onRequestClose }: BodyViewProps) {
  const { clients, loading, isMultiple, account_id } =
    useBodyController(sharingKey);

  if (loading) {
    return (
      <GridContainer>
        <ContentLoader width="402px" height="322.75px">
          <rect rx={8} ry={8} width="402px" height="322.75px" />
        </ContentLoader>
      </GridContainer>
    );
  }

  if (!clients?.length) {
    return (
      <Card
        background="main-deep-1"
        loaderForeground="main-deep-1"
        loaderBackground="main-deep-1"
        padding="SXL"
      >
        <GridContainer gridTemplateColumns="320px" gap="20">
          <GridContainer gap="16">
            <Text
              text="You don't have permissions to accept this invite"
              size={18}
              weight="VeryBig"
              color="main-deep-4"
              align="center"
            />
          </GridContainer>
          <GridContainer gridTemplateColumns="1fr">
            <SecondaryButton text="Close" onClick={onRequestClose} />
          </GridContainer>
        </GridContainer>
      </Card>
    );
  }

  return (
    <ClientPicker
      sharingKey={sharingKey}
      onRequestClose={onRequestClose}
      clients={clients}
      isMultiple={isMultiple}
      accountId={account_id}
    />
  );
}
