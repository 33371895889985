import { gql } from '@apollo/client';
import { IDENFY_RESULT_FRAGMENT } from '../../fragment/pdf/case/identityVerifications/IdenfyResult';
import { VERIFF_RESULT_FRAGMENT } from '../../fragment/pdf/case/identityVerifications/VeriffResult';
import { CUSTOMER_FRAGMENT } from '../../fragment/cm';
import { PROFILE_FRAGMENT } from '../../fragment/profiles/profiles';
import { ATTACHMENT_FRAGMENT } from '../../fragment/attachment';

export const ChangeStateCaseProjection = gql`
  mutation ChangeStateCaseProjection(
    $id: String!
    $data_sharing_id: String!
    $body: BodyChangeStateCaseProjection!
  ) {
    changeStateCaseProjection(
      id: $id
      data_sharing_id: $data_sharing_id
      body: $body
    )
      @rest(
        type: "ChangeStateCaseProjection"
        path: "/core/client/sharing/case_projections/{args.id}/change_state?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "PATCH"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          case_sections @type(name: "ProjectionCaseSection") {
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "Customer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const LinkProfileCaseProjection = gql`
  mutation LinkProfileCaseProjection(
    $id: String!
    $data_sharing_id: String!
    $body: BodyLinkOrUnlinkProfileCaseProjection!
  ) {
    linkProfileCaseProjection(
      id: $id
      data_sharing_id: $data_sharing_id
      body: $body
    )
      @rest(
        type: "LinkProfileCaseProjection"
        path: "/core/client/sharing/case_projections/{args.id}/link_to_profile?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          form_submission_ids
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "Customer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const UnlinkProfileCaseProjection = gql`
  mutation UnlinkProfileCaseProjection(
    $id: String!
    $data_sharing_id: String!
    $body: BodyLinkOrUnlinkProfileCaseProjection!
  ) {
    unlinkProfileCaseProjection(
      id: $id
      data_sharing_id: $data_sharing_id
      body: $body
    )
      @rest(
        type: "UnlinkProfileCaseProjection"
        path: "/core/client/sharing/case_projections/{args.id}/unlink_from_profile?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          form_submission_ids
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "Customer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const LinkSubmissionCaseProjection = gql`
  mutation LinkSubmissionCaseProjection(
    $id: String!
    $data_sharing_id: String!
    $body: BodyLinkOrUnlinkSubmissionCaseProjection!
  ) {
    linkSubmissionCaseProjection(
      id: $id
      data_sharing_id: $data_sharing_id
      body: $body
    )
      @rest(
        type: "LinkSubmissionCaseProjection"
        path: "/core/client/sharing/case_projections/{args.id}/link_to_submission?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          form_submission_ids
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "Customer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const UnlinkSubmissionCaseProjection = gql`
  mutation UnlinkSubmissionCaseProjection(
    $id: String!
    $data_sharing_id: String!
    $body: BodyLinkOrUnlinkSubmissionCaseProjection!
  ) {
    unlinkSubmissionCaseProjection(
      id: $id
      data_sharing_id: $data_sharing_id
      body: $body
    )
      @rest(
        type: "UnlinkSubmissionCaseProjection"
        path: "/core/client/sharing/case_projections/{args.id}/unlink_to_submission?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          form_submission_ids
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "Customer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const ChangeStateCaseProjectionSection = gql`
  mutation ChangeStateCaseProjectionSection(
    $id: String!
    $section_id: String!
    $body: BodyChangeStateCaseProjectionSection!
  ) {
    changeStateCaseProjectionSection(
      id: $id
      section_id: $section_id
      body: $body
    )
      @rest(
        type: "ChangeStateCaseProjectionSection"
        path: "/core/client/sharing/case_projections/{args.id}/case_sections/{args.section_id}/change_state"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          case_sections @type(name: "ProjectionCaseSection") {
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "Customer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const FieldUpdateClaimProjection = gql`
  mutation FieldUpdateClaimProjection(
    $id: String!
    $data_sharing_id: String!
    $body: BodyFieldUpdateClaim
  ) {
    fieldUpdateClaimProjection(
      id: $id
      data_sharing_id: $data_sharing_id
      body: $body
    )
      @rest(
        type: "FieldUpdateClaimProjectionResponse"
        path: "/core/client/sharing/submissions/field_update_claims?data_sharing_id={args.data_sharing_id}&case_projection_id={args.id}"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "FieldUpdateItem") {
        id
        account_display_name
        account_id
        comment
        created_at
        field_submission_id
        new_uploaded_document_id
        old_uploaded_document_id
        new_value
        old_value
        status
        value_type
        field_submission @type(name: "UpdateFieldSubmission") {
          id
          submission_id
          field_update_claims_count
          form_field_id
          form_group_index
          form_group_subindex
          value
          uploaded_document_id
        }
      }
      success
      errors
    }
  }
`;

export const DeleteCaseProjectionSectionItem = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  mutation DeleteCaseProjectionSectionItem(
    $id: String!
    $data_sharing_id: String!
    $case_projection_id: String!
  ) {
    deleteCaseProjectionSectionItem(
      id: $id
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "DeleteCaseProjectionSectionItem"
        path: "/core/client/sharing/case_projections/{args.case_projection_id}/case_section_items/{args.id}?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "DELETE"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          sharing_entities
          allowed_actions
          form_submission_ids
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
              id
              field_submission_id
            }
            case_section_tabs @type(name: "ProjectionCaseSectionTab") {
              failed_questionnaire_items
                @type(name: "FailedQuestionnaireItem") {
                id
                field_submission_id
              }
              name
              case_section_items @type(name: "ProjectionCaseSectionItem") {
                ai_task
                value
                is_my
                naics_name
                audit_id
                case_section_tab_id
                checkup_id
                checkup_result
                created_at
                description
                device_info
                form_audit
                form_field
                form_group
                form_screen
                created_at
                file_name
                file_size
                form_submission_id
                id
                updated_at
                uploaded_document_id
                kind
                customer_document_id
                case_question @type(name: "CaseQuestion") {
                  id
                  answer_body
                  author @type(name: "Account") {
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    id
                    phone
                  }
                  case_id
                  case_question_config_id
                  comment
                  created_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  question_body
                  status
                  updated_at
                }
                case_summary @type(name: "AISummary") {
                  id
                  body
                  case_id
                  case_summary_config_id
                  client_id
                  created_at
                  current_revision @type(name: "AISummaryRevision") {
                    id
                    body
                    case_summary_id
                    client_id
                    comment
                    created_at
                    edited_at
                    is_marked_as_actual
                    is_marked_as_irrelevant
                    request_prompt
                    status
                    updated_at
                    edited_by @type(name: "Account") {
                      id
                      avatar_id
                      email
                      file_name
                      file_size
                      full_name
                      phone
                    }
                  }
                  revisions_count
                  updated_at
                  used_sources
                }
                customer_document @type(name: "CustomerDocument") {
                  id
                  client_id
                  veriff_result_id
                  idenfy_result_id
                  created_at
                  updated_at
                  date_of_birth
                  date_of_issue
                  expiry_date
                  first_name
                  last_name
                  gender
                  document_number
                  document_type
                  blacklisted
                  country
                  nationality
                  attachments @type(name: "Attachment") {
                    ...AttachmentFragment
                  }
                  profiles @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  customers @type(name: "Customer") {
                    avatar_id
                    company_name
                    created_at
                    customer_verification_full_name
                    email
                    first_name
                    full_name
                    id
                    last_name
                    links
                    phone
                    profile @type(name: "Profile") {
                      avatar_id
                      created_at
                      display_name
                      email
                      id
                      is_whitelisted
                      kind
                      phone
                      updated_at
                    }
                    region_code
                    updated_at
                  }
                }
                customer_message @type(name: "CustomerMessage") {
                  id
                  body
                  client_id
                  created_at
                  name
                  state
                  subject
                  updated_at
                  email
                  account @type(name: "Account") {
                    id
                    full_name
                  }
                  header
                  sent_at
                }
                idenfy_result @type(name: "IdenfyResult") {
                  ...IdenfyResultFragment
                }
                veriff_result @type(name: "VeriffResult") {
                  ...VeriffResultFragment
                }
                stripe_checkout @type(name: "StripeCheckout") {
                  amount_discount
                  amount_total
                  currency
                  id
                  session_id
                  status
                  verification_step_passing_id
                }
                case_custom_step_evaluation
                  @type(name: "CaseCustomStepEvaluation") {
                  id
                  custom_step_action_evaluations
                    @type(name: "CustomStepActionEvaluation") {
                    id
                    value
                    uploaded_document_id
                    file_name
                    file_size
                    custom_step_action @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                  custom_step @type(name: "CustomStep") {
                    id
                    name
                    custom_actions @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                }
                case_section_tab_id
                description
              }
              case_section_items_count
              created_at
              id
              section_info {
                is_always_exists
                is_section_approvable
                is_section_approved_by_default
                is_tabs_enabled
                kind
              }
              is_tabs_enabled
              kind
              state
              updated_at
            }
            case_section_items @type(name: "ProjectionCaseSectionItem") {
              ai_task
              value
              is_my
              naics_name
              audit_id
              case_section_tab_id
              checkup_id
              checkup_result
              created_at
              description
              device_info
              form_audit
              form_field
              form_group
              form_screen
              created_at
              file_name
              file_size
              form_submission_id
              id
              updated_at
              uploaded_document_id
              kind
              customer_document_id
              case_question @type(name: "CaseQuestion") {
                id
                answer_body
                author @type(name: "Account") {
                  avatar_id
                  email
                  file_name
                  file_size
                  full_name
                  id
                  phone
                }
                case_id
                case_question_config_id
                comment
                created_at
                is_marked_as_actual
                is_marked_as_irrelevant
                question_body
                status
                updated_at
              }
              case_summary @type(name: "AISummary") {
                id
                body
                case_id
                case_summary_config_id
                client_id
                created_at
                current_revision @type(name: "AISummaryRevision") {
                  id
                  body
                  case_summary_id
                  client_id
                  comment
                  created_at
                  edited_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  request_prompt
                  status
                  updated_at
                  edited_by @type(name: "Account") {
                    id
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    phone
                  }
                }
                revisions_count
                updated_at
                used_sources
              }
              customer_document @type(name: "CustomerDocument") {
                id
                client_id
                veriff_result_id
                idenfy_result_id
                created_at
                updated_at
                date_of_birth
                date_of_issue
                expiry_date
                first_name
                last_name
                gender
                document_number
                document_type
                blacklisted
                country
                nationality
                attachments @type(name: "Attachment") {
                  ...AttachmentFragment
                }
                profiles @type(name: "Profile") {
                  avatar_id
                  created_at
                  display_name
                  email
                  id
                  is_whitelisted
                  kind
                  phone
                  updated_at
                }
                customers @type(name: "Customer") {
                  avatar_id
                  company_name
                  created_at
                  customer_verification_full_name
                  email
                  first_name
                  full_name
                  id
                  last_name
                  links
                  phone
                  profile @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  region_code
                  updated_at
                }
              }
              customer_message @type(name: "CustomerMessage") {
                id
                body
                client_id
                created_at
                name
                state
                subject
                updated_at
                email
                account @type(name: "Account") {
                  id
                  full_name
                }
                header
                sent_at
              }
              idenfy_result @type(name: "IdenfyResult") {
                ...IdenfyResultFragment
              }
              veriff_result @type(name: "VeriffResult") {
                ...VeriffResultFragment
              }
              stripe_checkout @type(name: "StripeCheckout") {
                amount_discount
                amount_total
                currency
                id
                session_id
                status
                verification_step_passing_id
              }
              case_custom_step_evaluation
                @type(name: "CaseCustomStepEvaluation") {
                id
                custom_step_action_evaluations
                  @type(name: "CustomStepActionEvaluation") {
                  id
                  value
                  uploaded_document_id
                  file_name
                  file_size
                  custom_step_action @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
                custom_step @type(name: "CustomStep") {
                  id
                  name
                  custom_actions @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
              }
              case_section_tab_id
              description
            }
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
            is_my
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const CreateCaseProjectionSectionItem = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  mutation CreateCaseProjectionSectionItem(
    $data_sharing_id: String!
    $case_projection_id: String!
    $body: BodyCreateCaseSectionItem
  ) {
    createCaseProjectionSectionItem(
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
      body: $body
    )
      @rest(
        type: "CreateCaseProjectionSectionItem"
        path: "/core/client/sharing/case_projections/{args.case_projection_id}/case_section_items/{args.id}?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          sharing_entities
          allowed_actions
          form_submission_ids
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
              id
              field_submission_id
            }
            case_section_tabs @type(name: "ProjectionCaseSectionTab") {
              failed_questionnaire_items
                @type(name: "FailedQuestionnaireItem") {
                id
                field_submission_id
              }
              name
              case_section_items @type(name: "ProjectionCaseSectionItem") {
                ai_task
                value
                is_my
                naics_name
                audit_id
                case_section_tab_id
                checkup_id
                checkup_result
                created_at
                description
                device_info
                form_audit
                form_field
                form_group
                form_screen
                created_at
                file_name
                file_size
                form_submission_id
                id
                updated_at
                uploaded_document_id
                kind
                customer_document_id
                case_question @type(name: "CaseQuestion") {
                  id
                  answer_body
                  author @type(name: "Account") {
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    id
                    phone
                  }
                  case_id
                  case_question_config_id
                  comment
                  created_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  question_body
                  status
                  updated_at
                }
                case_summary @type(name: "AISummary") {
                  id
                  body
                  case_id
                  case_summary_config_id
                  client_id
                  created_at
                  current_revision @type(name: "AISummaryRevision") {
                    id
                    body
                    case_summary_id
                    client_id
                    comment
                    created_at
                    edited_at
                    is_marked_as_actual
                    is_marked_as_irrelevant
                    request_prompt
                    status
                    updated_at
                    edited_by @type(name: "Account") {
                      id
                      avatar_id
                      email
                      file_name
                      file_size
                      full_name
                      phone
                    }
                  }
                  revisions_count
                  updated_at
                  used_sources
                }
                customer_document @type(name: "CustomerDocument") {
                  id
                  client_id
                  veriff_result_id
                  idenfy_result_id
                  created_at
                  updated_at
                  date_of_birth
                  date_of_issue
                  expiry_date
                  first_name
                  last_name
                  gender
                  document_number
                  document_type
                  blacklisted
                  country
                  nationality
                  attachments @type(name: "Attachment") {
                    ...AttachmentFragment
                  }
                  profiles @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  customers @type(name: "Customer") {
                    avatar_id
                    company_name
                    created_at
                    customer_verification_full_name
                    email
                    first_name
                    full_name
                    id
                    last_name
                    links
                    phone
                    profile @type(name: "Profile") {
                      avatar_id
                      created_at
                      display_name
                      email
                      id
                      is_whitelisted
                      kind
                      phone
                      updated_at
                    }
                    region_code
                    updated_at
                  }
                }
                customer_message @type(name: "CustomerMessage") {
                  id
                  body
                  client_id
                  created_at
                  name
                  state
                  subject
                  updated_at
                  email
                  account @type(name: "Account") {
                    id
                    full_name
                  }
                  header
                  sent_at
                }
                idenfy_result @type(name: "IdenfyResult") {
                  ...IdenfyResultFragment
                }
                veriff_result @type(name: "VeriffResult") {
                  ...VeriffResultFragment
                }
                stripe_checkout @type(name: "StripeCheckout") {
                  amount_discount
                  amount_total
                  currency
                  id
                  session_id
                  status
                  verification_step_passing_id
                }
                case_custom_step_evaluation
                  @type(name: "CaseCustomStepEvaluation") {
                  id
                  custom_step_action_evaluations
                    @type(name: "CustomStepActionEvaluation") {
                    id
                    value
                    uploaded_document_id
                    file_name
                    file_size
                    custom_step_action @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                  custom_step @type(name: "CustomStep") {
                    id
                    name
                    custom_actions @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                }
                case_section_tab_id
                description
              }
              case_section_items_count
              created_at
              id
              section_info {
                is_always_exists
                is_section_approvable
                is_section_approved_by_default
                is_tabs_enabled
                kind
              }
              is_tabs_enabled
              kind
              state
              updated_at
            }
            case_section_items @type(name: "ProjectionCaseSectionItem") {
              ai_task
              value
              is_my
              naics_name
              audit_id
              case_section_tab_id
              checkup_id
              checkup_result
              created_at
              description
              device_info
              form_audit
              form_field
              form_group
              form_screen
              created_at
              file_name
              file_size
              form_submission_id
              id
              updated_at
              uploaded_document_id
              kind
              customer_document_id
              case_question @type(name: "CaseQuestion") {
                id
                answer_body
                author @type(name: "Account") {
                  avatar_id
                  email
                  file_name
                  file_size
                  full_name
                  id
                  phone
                }
                case_id
                case_question_config_id
                comment
                created_at
                is_marked_as_actual
                is_marked_as_irrelevant
                question_body
                status
                updated_at
              }
              case_summary @type(name: "AISummary") {
                id
                body
                case_id
                case_summary_config_id
                client_id
                created_at
                current_revision @type(name: "AISummaryRevision") {
                  id
                  body
                  case_summary_id
                  client_id
                  comment
                  created_at
                  edited_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  request_prompt
                  status
                  updated_at
                  edited_by @type(name: "Account") {
                    id
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    phone
                  }
                }
                revisions_count
                updated_at
                used_sources
              }
              customer_document @type(name: "CustomerDocument") {
                id
                client_id
                veriff_result_id
                idenfy_result_id
                created_at
                updated_at
                date_of_birth
                date_of_issue
                expiry_date
                first_name
                last_name
                gender
                document_number
                document_type
                blacklisted
                country
                nationality
                attachments @type(name: "Attachment") {
                  ...AttachmentFragment
                }
                profiles @type(name: "Profile") {
                  avatar_id
                  created_at
                  display_name
                  email
                  id
                  is_whitelisted
                  kind
                  phone
                  updated_at
                }
                customers @type(name: "Customer") {
                  avatar_id
                  company_name
                  created_at
                  customer_verification_full_name
                  email
                  first_name
                  full_name
                  id
                  last_name
                  links
                  phone
                  profile @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  region_code
                  updated_at
                }
              }
              customer_message @type(name: "CustomerMessage") {
                id
                body
                client_id
                created_at
                name
                state
                subject
                updated_at
                email
                account @type(name: "Account") {
                  id
                  full_name
                }
                header
                sent_at
              }
              idenfy_result @type(name: "IdenfyResult") {
                ...IdenfyResultFragment
              }
              veriff_result @type(name: "VeriffResult") {
                ...VeriffResultFragment
              }
              stripe_checkout @type(name: "StripeCheckout") {
                amount_discount
                amount_total
                currency
                id
                session_id
                status
                verification_step_passing_id
              }
              case_custom_step_evaluation
                @type(name: "CaseCustomStepEvaluation") {
                id
                custom_step_action_evaluations
                  @type(name: "CustomStepActionEvaluation") {
                  id
                  value
                  uploaded_document_id
                  file_name
                  file_size
                  custom_step_action @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
                custom_step @type(name: "CustomStep") {
                  id
                  name
                  custom_actions @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
              }
              case_section_tab_id
              description
            }
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
            is_my
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const UpdateCaseProjectionSectionItem = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  mutation UpdateCaseProjectionSectionItem(
    $id: String!
    $data_sharing_id: String!
    $case_projection_id: String!
    $body: BodyUpdateCaseSectionItem!
  ) {
    updateCaseProjectionSectionItem(
      id: $id
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
      body: $body
    )
      @rest(
        type: "UpdateCaseSectionItem"
        path: "/core/client/sharing/case_projections/{args.case_projection_id}/case_section_items/{args.id}?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          sharing_entities
          allowed_actions
          form_submission_ids
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
              id
              field_submission_id
            }
            case_section_tabs @type(name: "ProjectionCaseSectionTab") {
              failed_questionnaire_items
                @type(name: "FailedQuestionnaireItem") {
                id
                field_submission_id
              }
              name
              case_section_items @type(name: "ProjectionCaseSectionItem") {
                ai_task
                value
                is_my
                naics_name
                audit_id
                case_section_tab_id
                checkup_id
                checkup_result
                created_at
                description
                device_info
                form_audit
                form_field
                form_group
                form_screen
                created_at
                file_name
                file_size
                form_submission_id
                id
                updated_at
                uploaded_document_id
                kind
                customer_document_id
                case_question @type(name: "CaseQuestion") {
                  id
                  answer_body
                  author @type(name: "Account") {
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    id
                    phone
                  }
                  case_id
                  case_question_config_id
                  comment
                  created_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  question_body
                  status
                  updated_at
                }
                case_summary @type(name: "AISummary") {
                  id
                  body
                  case_id
                  case_summary_config_id
                  client_id
                  created_at
                  current_revision @type(name: "AISummaryRevision") {
                    id
                    body
                    case_summary_id
                    client_id
                    comment
                    created_at
                    edited_at
                    is_marked_as_actual
                    is_marked_as_irrelevant
                    request_prompt
                    status
                    updated_at
                    edited_by @type(name: "Account") {
                      id
                      avatar_id
                      email
                      file_name
                      file_size
                      full_name
                      phone
                    }
                  }
                  revisions_count
                  updated_at
                  used_sources
                }
                customer_document @type(name: "CustomerDocument") {
                  id
                  client_id
                  veriff_result_id
                  idenfy_result_id
                  created_at
                  updated_at
                  date_of_birth
                  date_of_issue
                  expiry_date
                  first_name
                  last_name
                  gender
                  document_number
                  document_type
                  blacklisted
                  country
                  nationality
                  attachments @type(name: "Attachment") {
                    ...AttachmentFragment
                  }
                  profiles @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  customers @type(name: "Customer") {
                    avatar_id
                    company_name
                    created_at
                    customer_verification_full_name
                    email
                    first_name
                    full_name
                    id
                    last_name
                    links
                    phone
                    profile @type(name: "Profile") {
                      avatar_id
                      created_at
                      display_name
                      email
                      id
                      is_whitelisted
                      kind
                      phone
                      updated_at
                    }
                    region_code
                    updated_at
                  }
                }
                customer_message @type(name: "CustomerMessage") {
                  id
                  body
                  client_id
                  created_at
                  name
                  state
                  subject
                  updated_at
                  email
                  account @type(name: "Account") {
                    id
                    full_name
                  }
                  header
                  sent_at
                }
                idenfy_result @type(name: "IdenfyResult") {
                  ...IdenfyResultFragment
                }
                veriff_result @type(name: "VeriffResult") {
                  ...VeriffResultFragment
                }
                stripe_checkout @type(name: "StripeCheckout") {
                  amount_discount
                  amount_total
                  currency
                  id
                  session_id
                  status
                  verification_step_passing_id
                }
                case_custom_step_evaluation
                  @type(name: "CaseCustomStepEvaluation") {
                  id
                  custom_step_action_evaluations
                    @type(name: "CustomStepActionEvaluation") {
                    id
                    value
                    uploaded_document_id
                    file_name
                    file_size
                    custom_step_action @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                  custom_step @type(name: "CustomStep") {
                    id
                    name
                    custom_actions @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                }
                case_section_tab_id
                description
              }
              case_section_items_count
              created_at
              id
              section_info {
                is_always_exists
                is_section_approvable
                is_section_approved_by_default
                is_tabs_enabled
                kind
              }
              is_tabs_enabled
              kind
              state
              updated_at
            }
            case_section_items @type(name: "ProjectionCaseSectionItem") {
              ai_task
              value
              is_my
              naics_name
              audit_id
              case_section_tab_id
              checkup_id
              checkup_result
              created_at
              description
              device_info
              form_audit
              form_field
              form_group
              form_screen
              created_at
              file_name
              file_size
              form_submission_id
              id
              updated_at
              uploaded_document_id
              kind
              customer_document_id
              case_question @type(name: "CaseQuestion") {
                id
                answer_body
                author @type(name: "Account") {
                  avatar_id
                  email
                  file_name
                  file_size
                  full_name
                  id
                  phone
                }
                case_id
                case_question_config_id
                comment
                created_at
                is_marked_as_actual
                is_marked_as_irrelevant
                question_body
                status
                updated_at
              }
              case_summary @type(name: "AISummary") {
                id
                body
                case_id
                case_summary_config_id
                client_id
                created_at
                current_revision @type(name: "AISummaryRevision") {
                  id
                  body
                  case_summary_id
                  client_id
                  comment
                  created_at
                  edited_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  request_prompt
                  status
                  updated_at
                  edited_by @type(name: "Account") {
                    id
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    phone
                  }
                }
                revisions_count
                updated_at
                used_sources
              }
              customer_document @type(name: "CustomerDocument") {
                id
                client_id
                veriff_result_id
                idenfy_result_id
                created_at
                updated_at
                date_of_birth
                date_of_issue
                expiry_date
                first_name
                last_name
                gender
                document_number
                document_type
                blacklisted
                country
                nationality
                attachments @type(name: "Attachment") {
                  ...AttachmentFragment
                }
                profiles @type(name: "Profile") {
                  avatar_id
                  created_at
                  display_name
                  email
                  id
                  is_whitelisted
                  kind
                  phone
                  updated_at
                }
                customers @type(name: "Customer") {
                  avatar_id
                  company_name
                  created_at
                  customer_verification_full_name
                  email
                  first_name
                  full_name
                  id
                  last_name
                  links
                  phone
                  profile @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  region_code
                  updated_at
                }
              }
              customer_message @type(name: "CustomerMessage") {
                id
                body
                client_id
                created_at
                name
                state
                subject
                updated_at
                email
                account @type(name: "Account") {
                  id
                  full_name
                }
                header
                sent_at
              }
              idenfy_result @type(name: "IdenfyResult") {
                ...IdenfyResultFragment
              }
              veriff_result @type(name: "VeriffResult") {
                ...VeriffResultFragment
              }
              stripe_checkout @type(name: "StripeCheckout") {
                amount_discount
                amount_total
                currency
                id
                session_id
                status
                verification_step_passing_id
              }
              case_custom_step_evaluation
                @type(name: "CaseCustomStepEvaluation") {
                id
                custom_step_action_evaluations
                  @type(name: "CustomStepActionEvaluation") {
                  id
                  value
                  uploaded_document_id
                  file_name
                  file_size
                  custom_step_action @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
                custom_step @type(name: "CustomStep") {
                  id
                  name
                  custom_actions @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
              }
              case_section_tab_id
              description
            }
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
            is_my
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const UploadCaseProjectionSectionDocument = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  mutation UploadCaseProjectionSectionDocument(
    $id: String!
    $data_sharing_id: String!
    $case_projection_id: String!
    $body: BodyUploadCaseSectionDocument!
  ) {
    uploadCaseProjectionSectionDocument(
      id: $id
      body: $body
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "UploadProjectionCaseSectionDocument"
        path: "/core/client/sharing/case_projections/{args.case_projection_id}/case_sections/{args.id}/upload_document?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          sharing_entities
          allowed_actions
          form_submission_ids
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
              id
              field_submission_id
            }
            case_section_tabs @type(name: "ProjectionCaseSectionTab") {
              failed_questionnaire_items
                @type(name: "FailedQuestionnaireItem") {
                id
                field_submission_id
              }
              name
              case_section_items @type(name: "ProjectionCaseSectionItem") {
                ai_task
                value
                is_my
                naics_name
                audit_id
                case_section_tab_id
                checkup_id
                checkup_result
                created_at
                description
                device_info
                form_audit
                form_field
                form_group
                form_screen
                created_at
                file_name
                file_size
                form_submission_id
                id
                updated_at
                uploaded_document_id
                kind
                customer_document_id
                case_question @type(name: "CaseQuestion") {
                  id
                  answer_body
                  author @type(name: "Account") {
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    id
                    phone
                  }
                  case_id
                  case_question_config_id
                  comment
                  created_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  question_body
                  status
                  updated_at
                }
                case_summary @type(name: "AISummary") {
                  id
                  body
                  case_id
                  case_summary_config_id
                  client_id
                  created_at
                  current_revision @type(name: "AISummaryRevision") {
                    id
                    body
                    case_summary_id
                    client_id
                    comment
                    created_at
                    edited_at
                    is_marked_as_actual
                    is_marked_as_irrelevant
                    request_prompt
                    status
                    updated_at
                    edited_by @type(name: "Account") {
                      id
                      avatar_id
                      email
                      file_name
                      file_size
                      full_name
                      phone
                    }
                  }
                  revisions_count
                  updated_at
                  used_sources
                }
                customer_document @type(name: "CustomerDocument") {
                  id
                  client_id
                  veriff_result_id
                  idenfy_result_id
                  created_at
                  updated_at
                  date_of_birth
                  date_of_issue
                  expiry_date
                  first_name
                  last_name
                  gender
                  document_number
                  document_type
                  blacklisted
                  country
                  nationality
                  attachments @type(name: "Attachment") {
                    ...AttachmentFragment
                  }
                  profiles @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  customers @type(name: "Customer") {
                    avatar_id
                    company_name
                    created_at
                    customer_verification_full_name
                    email
                    first_name
                    full_name
                    id
                    last_name
                    links
                    phone
                    profile @type(name: "Profile") {
                      avatar_id
                      created_at
                      display_name
                      email
                      id
                      is_whitelisted
                      kind
                      phone
                      updated_at
                    }
                    region_code
                    updated_at
                  }
                }
                customer_message @type(name: "CustomerMessage") {
                  id
                  body
                  client_id
                  created_at
                  name
                  state
                  subject
                  updated_at
                  email
                  account @type(name: "Account") {
                    id
                    full_name
                  }
                  header
                  sent_at
                }
                idenfy_result @type(name: "IdenfyResult") {
                  ...IdenfyResultFragment
                }
                veriff_result @type(name: "VeriffResult") {
                  ...VeriffResultFragment
                }
                stripe_checkout @type(name: "StripeCheckout") {
                  amount_discount
                  amount_total
                  currency
                  id
                  session_id
                  status
                  verification_step_passing_id
                }
                case_custom_step_evaluation
                  @type(name: "CaseCustomStepEvaluation") {
                  id
                  custom_step_action_evaluations
                    @type(name: "CustomStepActionEvaluation") {
                    id
                    value
                    uploaded_document_id
                    file_name
                    file_size
                    custom_step_action @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                  custom_step @type(name: "CustomStep") {
                    id
                    name
                    custom_actions @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                }
                case_section_tab_id
                description
              }
              case_section_items_count
              created_at
              id
              section_info {
                is_always_exists
                is_section_approvable
                is_section_approved_by_default
                is_tabs_enabled
                kind
              }
              is_tabs_enabled
              kind
              state
              updated_at
            }
            case_section_items @type(name: "ProjectionCaseSectionItem") {
              ai_task
              value
              is_my
              naics_name
              audit_id
              case_section_tab_id
              checkup_id
              checkup_result
              created_at
              description
              device_info
              form_audit
              form_field
              form_group
              form_screen
              created_at
              file_name
              file_size
              form_submission_id
              id
              updated_at
              uploaded_document_id
              kind
              customer_document_id
              case_question @type(name: "CaseQuestion") {
                id
                answer_body
                author @type(name: "Account") {
                  avatar_id
                  email
                  file_name
                  file_size
                  full_name
                  id
                  phone
                }
                case_id
                case_question_config_id
                comment
                created_at
                is_marked_as_actual
                is_marked_as_irrelevant
                question_body
                status
                updated_at
              }
              case_summary @type(name: "AISummary") {
                id
                body
                case_id
                case_summary_config_id
                client_id
                created_at
                current_revision @type(name: "AISummaryRevision") {
                  id
                  body
                  case_summary_id
                  client_id
                  comment
                  created_at
                  edited_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  request_prompt
                  status
                  updated_at
                  edited_by @type(name: "Account") {
                    id
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    phone
                  }
                }
                revisions_count
                updated_at
                used_sources
              }
              customer_document @type(name: "CustomerDocument") {
                id
                client_id
                veriff_result_id
                idenfy_result_id
                created_at
                updated_at
                date_of_birth
                date_of_issue
                expiry_date
                first_name
                last_name
                gender
                document_number
                document_type
                blacklisted
                country
                nationality
                attachments @type(name: "Attachment") {
                  ...AttachmentFragment
                }
                profiles @type(name: "Profile") {
                  avatar_id
                  created_at
                  display_name
                  email
                  id
                  is_whitelisted
                  kind
                  phone
                  updated_at
                }
                customers @type(name: "Customer") {
                  avatar_id
                  company_name
                  created_at
                  customer_verification_full_name
                  email
                  first_name
                  full_name
                  id
                  last_name
                  links
                  phone
                  profile @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  region_code
                  updated_at
                }
              }
              customer_message @type(name: "CustomerMessage") {
                id
                body
                client_id
                created_at
                name
                state
                subject
                updated_at
                email
                account @type(name: "Account") {
                  id
                  full_name
                }
                header
                sent_at
              }
              idenfy_result @type(name: "IdenfyResult") {
                ...IdenfyResultFragment
              }
              veriff_result @type(name: "VeriffResult") {
                ...VeriffResultFragment
              }
              stripe_checkout @type(name: "StripeCheckout") {
                amount_discount
                amount_total
                currency
                id
                session_id
                status
                verification_step_passing_id
              }
              case_custom_step_evaluation
                @type(name: "CaseCustomStepEvaluation") {
                id
                custom_step_action_evaluations
                  @type(name: "CustomStepActionEvaluation") {
                  id
                  value
                  uploaded_document_id
                  file_name
                  file_size
                  custom_step_action @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
                custom_step @type(name: "CustomStep") {
                  id
                  name
                  custom_actions @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
              }
              case_section_tab_id
              description
            }
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
            is_my
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const CreateCaseProjectionSectionTab = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  mutation CreateCaseProjectionSectionTab(
    $data_sharing_id: String!
    $case_projection_id: String!
    $body: BodyCreateOrUpdateCaseSectionTab
  ) {
    createCaseProjectionSectionTab(
      body: $body
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "CreateCaseProjectionSectionTab"
        path: "/core/client/sharing/case_projections/{args.case_projection_id}/case_section_tabs?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          sharing_entities
          allowed_actions
          form_submission_ids
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
              id
              field_submission_id
            }
            case_section_tabs @type(name: "ProjectionCaseSectionTab") {
              failed_questionnaire_items
                @type(name: "FailedQuestionnaireItem") {
                id
                field_submission_id
              }
              name
              case_section_items @type(name: "ProjectionCaseSectionItem") {
                ai_task
                value
                is_my
                naics_name
                audit_id
                case_section_tab_id
                checkup_id
                checkup_result
                created_at
                description
                device_info
                form_audit
                form_field
                form_group
                form_screen
                created_at
                file_name
                file_size
                form_submission_id
                id
                updated_at
                uploaded_document_id
                kind
                customer_document_id
                case_question @type(name: "CaseQuestion") {
                  id
                  answer_body
                  author @type(name: "Account") {
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    id
                    phone
                  }
                  case_id
                  case_question_config_id
                  comment
                  created_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  question_body
                  status
                  updated_at
                }
                case_summary @type(name: "AISummary") {
                  id
                  body
                  case_id
                  case_summary_config_id
                  client_id
                  created_at
                  current_revision @type(name: "AISummaryRevision") {
                    id
                    body
                    case_summary_id
                    client_id
                    comment
                    created_at
                    edited_at
                    is_marked_as_actual
                    is_marked_as_irrelevant
                    request_prompt
                    status
                    updated_at
                    edited_by @type(name: "Account") {
                      id
                      avatar_id
                      email
                      file_name
                      file_size
                      full_name
                      phone
                    }
                  }
                  revisions_count
                  updated_at
                  used_sources
                }
                customer_document @type(name: "CustomerDocument") {
                  id
                  client_id
                  veriff_result_id
                  idenfy_result_id
                  created_at
                  updated_at
                  date_of_birth
                  date_of_issue
                  expiry_date
                  first_name
                  last_name
                  gender
                  document_number
                  document_type
                  blacklisted
                  country
                  nationality
                  attachments @type(name: "Attachment") {
                    ...AttachmentFragment
                  }
                  profiles @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  customers @type(name: "Customer") {
                    avatar_id
                    company_name
                    created_at
                    customer_verification_full_name
                    email
                    first_name
                    full_name
                    id
                    last_name
                    links
                    phone
                    profile @type(name: "Profile") {
                      avatar_id
                      created_at
                      display_name
                      email
                      id
                      is_whitelisted
                      kind
                      phone
                      updated_at
                    }
                    region_code
                    updated_at
                  }
                }
                customer_message @type(name: "CustomerMessage") {
                  id
                  body
                  client_id
                  created_at
                  name
                  state
                  subject
                  updated_at
                  email
                  account @type(name: "Account") {
                    id
                    full_name
                  }
                  header
                  sent_at
                }
                idenfy_result @type(name: "IdenfyResult") {
                  ...IdenfyResultFragment
                }
                veriff_result @type(name: "VeriffResult") {
                  ...VeriffResultFragment
                }
                stripe_checkout @type(name: "StripeCheckout") {
                  amount_discount
                  amount_total
                  currency
                  id
                  session_id
                  status
                  verification_step_passing_id
                }
                case_custom_step_evaluation
                  @type(name: "CaseCustomStepEvaluation") {
                  id
                  custom_step_action_evaluations
                    @type(name: "CustomStepActionEvaluation") {
                    id
                    value
                    uploaded_document_id
                    file_name
                    file_size
                    custom_step_action @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                  custom_step @type(name: "CustomStep") {
                    id
                    name
                    custom_actions @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                }
                case_section_tab_id
                description
              }
              case_section_items_count
              created_at
              id
              section_info {
                is_always_exists
                is_section_approvable
                is_section_approved_by_default
                is_tabs_enabled
                kind
              }
              is_tabs_enabled
              kind
              state
              updated_at
            }
            case_section_items @type(name: "ProjectionCaseSectionItem") {
              ai_task
              value
              is_my
              naics_name
              audit_id
              case_section_tab_id
              checkup_id
              checkup_result
              created_at
              description
              device_info
              form_audit
              form_field
              form_group
              form_screen
              created_at
              file_name
              file_size
              form_submission_id
              id
              updated_at
              uploaded_document_id
              kind
              customer_document_id
              case_question @type(name: "CaseQuestion") {
                id
                answer_body
                author @type(name: "Account") {
                  avatar_id
                  email
                  file_name
                  file_size
                  full_name
                  id
                  phone
                }
                case_id
                case_question_config_id
                comment
                created_at
                is_marked_as_actual
                is_marked_as_irrelevant
                question_body
                status
                updated_at
              }
              case_summary @type(name: "AISummary") {
                id
                body
                case_id
                case_summary_config_id
                client_id
                created_at
                current_revision @type(name: "AISummaryRevision") {
                  id
                  body
                  case_summary_id
                  client_id
                  comment
                  created_at
                  edited_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  request_prompt
                  status
                  updated_at
                  edited_by @type(name: "Account") {
                    id
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    phone
                  }
                }
                revisions_count
                updated_at
                used_sources
              }
              customer_document @type(name: "CustomerDocument") {
                id
                client_id
                veriff_result_id
                idenfy_result_id
                created_at
                updated_at
                date_of_birth
                date_of_issue
                expiry_date
                first_name
                last_name
                gender
                document_number
                document_type
                blacklisted
                country
                nationality
                attachments @type(name: "Attachment") {
                  ...AttachmentFragment
                }
                profiles @type(name: "Profile") {
                  avatar_id
                  created_at
                  display_name
                  email
                  id
                  is_whitelisted
                  kind
                  phone
                  updated_at
                }
                customers @type(name: "Customer") {
                  avatar_id
                  company_name
                  created_at
                  customer_verification_full_name
                  email
                  first_name
                  full_name
                  id
                  last_name
                  links
                  phone
                  profile @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  region_code
                  updated_at
                }
              }
              customer_message @type(name: "CustomerMessage") {
                id
                body
                client_id
                created_at
                name
                state
                subject
                updated_at
                email
                account @type(name: "Account") {
                  id
                  full_name
                }
                header
                sent_at
              }
              idenfy_result @type(name: "IdenfyResult") {
                ...IdenfyResultFragment
              }
              veriff_result @type(name: "VeriffResult") {
                ...VeriffResultFragment
              }
              stripe_checkout @type(name: "StripeCheckout") {
                amount_discount
                amount_total
                currency
                id
                session_id
                status
                verification_step_passing_id
              }
              case_custom_step_evaluation
                @type(name: "CaseCustomStepEvaluation") {
                id
                custom_step_action_evaluations
                  @type(name: "CustomStepActionEvaluation") {
                  id
                  value
                  uploaded_document_id
                  file_name
                  file_size
                  custom_step_action @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
                custom_step @type(name: "CustomStep") {
                  id
                  name
                  custom_actions @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
              }
              case_section_tab_id
              description
            }
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
            is_my
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const UpdateCaseProjectionSectionTab = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  mutation UpdateCaseProjectionSectionTab(
    $id: String!
    $data_sharing_id: String!
    $case_projection_id: String!
    $body: BodyCreateOrUpdateCaseSectionTab
  ) {
    updateCaseProjectionSectionTab(
      id: $id
      body: $body
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "UpdateCaseProjectionSectionTab"
        path: "/core/client/sharing/case_projections/{args.case_projection_id}/case_section_tabs/{args.id}?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          sharing_entities
          allowed_actions
          form_submission_ids
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
              id
              field_submission_id
            }
            case_section_tabs @type(name: "ProjectionCaseSectionTab") {
              failed_questionnaire_items
                @type(name: "FailedQuestionnaireItem") {
                id
                field_submission_id
              }
              name
              case_section_items @type(name: "ProjectionCaseSectionItem") {
                ai_task
                value
                is_my
                naics_name
                audit_id
                case_section_tab_id
                checkup_id
                checkup_result
                created_at
                description
                device_info
                form_audit
                form_field
                form_group
                form_screen
                created_at
                file_name
                file_size
                form_submission_id
                id
                updated_at
                uploaded_document_id
                kind
                customer_document_id
                case_question @type(name: "CaseQuestion") {
                  id
                  answer_body
                  author @type(name: "Account") {
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    id
                    phone
                  }
                  case_id
                  case_question_config_id
                  comment
                  created_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  question_body
                  status
                  updated_at
                }
                case_summary @type(name: "AISummary") {
                  id
                  body
                  case_id
                  case_summary_config_id
                  client_id
                  created_at
                  current_revision @type(name: "AISummaryRevision") {
                    id
                    body
                    case_summary_id
                    client_id
                    comment
                    created_at
                    edited_at
                    is_marked_as_actual
                    is_marked_as_irrelevant
                    request_prompt
                    status
                    updated_at
                    edited_by @type(name: "Account") {
                      id
                      avatar_id
                      email
                      file_name
                      file_size
                      full_name
                      phone
                    }
                  }
                  revisions_count
                  updated_at
                  used_sources
                }
                customer_document @type(name: "CustomerDocument") {
                  id
                  client_id
                  veriff_result_id
                  idenfy_result_id
                  created_at
                  updated_at
                  date_of_birth
                  date_of_issue
                  expiry_date
                  first_name
                  last_name
                  gender
                  document_number
                  document_type
                  blacklisted
                  country
                  nationality
                  attachments @type(name: "Attachment") {
                    ...AttachmentFragment
                  }
                  profiles @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  customers @type(name: "Customer") {
                    avatar_id
                    company_name
                    created_at
                    customer_verification_full_name
                    email
                    first_name
                    full_name
                    id
                    last_name
                    links
                    phone
                    profile @type(name: "Profile") {
                      avatar_id
                      created_at
                      display_name
                      email
                      id
                      is_whitelisted
                      kind
                      phone
                      updated_at
                    }
                    region_code
                    updated_at
                  }
                }
                customer_message @type(name: "CustomerMessage") {
                  id
                  body
                  client_id
                  created_at
                  name
                  state
                  subject
                  updated_at
                  email
                  account @type(name: "Account") {
                    id
                    full_name
                  }
                  header
                  sent_at
                }
                idenfy_result @type(name: "IdenfyResult") {
                  ...IdenfyResultFragment
                }
                veriff_result @type(name: "VeriffResult") {
                  ...VeriffResultFragment
                }
                stripe_checkout @type(name: "StripeCheckout") {
                  amount_discount
                  amount_total
                  currency
                  id
                  session_id
                  status
                  verification_step_passing_id
                }
                case_custom_step_evaluation
                  @type(name: "CaseCustomStepEvaluation") {
                  id
                  custom_step_action_evaluations
                    @type(name: "CustomStepActionEvaluation") {
                    id
                    value
                    uploaded_document_id
                    file_name
                    file_size
                    custom_step_action @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                  custom_step @type(name: "CustomStep") {
                    id
                    name
                    custom_actions @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                }
                case_section_tab_id
                description
              }
              case_section_items_count
              created_at
              id
              section_info {
                is_always_exists
                is_section_approvable
                is_section_approved_by_default
                is_tabs_enabled
                kind
              }
              is_tabs_enabled
              kind
              state
              updated_at
            }
            case_section_items @type(name: "ProjectionCaseSectionItem") {
              ai_task
              value
              is_my
              naics_name
              audit_id
              case_section_tab_id
              checkup_id
              checkup_result
              created_at
              description
              device_info
              form_audit
              form_field
              form_group
              form_screen
              created_at
              file_name
              file_size
              form_submission_id
              id
              updated_at
              uploaded_document_id
              kind
              customer_document_id
              case_question @type(name: "CaseQuestion") {
                id
                answer_body
                author @type(name: "Account") {
                  avatar_id
                  email
                  file_name
                  file_size
                  full_name
                  id
                  phone
                }
                case_id
                case_question_config_id
                comment
                created_at
                is_marked_as_actual
                is_marked_as_irrelevant
                question_body
                status
                updated_at
              }
              case_summary @type(name: "AISummary") {
                id
                body
                case_id
                case_summary_config_id
                client_id
                created_at
                current_revision @type(name: "AISummaryRevision") {
                  id
                  body
                  case_summary_id
                  client_id
                  comment
                  created_at
                  edited_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  request_prompt
                  status
                  updated_at
                  edited_by @type(name: "Account") {
                    id
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    phone
                  }
                }
                revisions_count
                updated_at
                used_sources
              }
              customer_document @type(name: "CustomerDocument") {
                id
                client_id
                veriff_result_id
                idenfy_result_id
                created_at
                updated_at
                date_of_birth
                date_of_issue
                expiry_date
                first_name
                last_name
                gender
                document_number
                document_type
                blacklisted
                country
                nationality
                attachments @type(name: "Attachment") {
                  ...AttachmentFragment
                }
                profiles @type(name: "Profile") {
                  avatar_id
                  created_at
                  display_name
                  email
                  id
                  is_whitelisted
                  kind
                  phone
                  updated_at
                }
                customers @type(name: "Customer") {
                  avatar_id
                  company_name
                  created_at
                  customer_verification_full_name
                  email
                  first_name
                  full_name
                  id
                  last_name
                  links
                  phone
                  profile @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  region_code
                  updated_at
                }
              }
              customer_message @type(name: "CustomerMessage") {
                id
                body
                client_id
                created_at
                name
                state
                subject
                updated_at
                email
                account @type(name: "Account") {
                  id
                  full_name
                }
                header
                sent_at
              }
              idenfy_result @type(name: "IdenfyResult") {
                ...IdenfyResultFragment
              }
              veriff_result @type(name: "VeriffResult") {
                ...VeriffResultFragment
              }
              stripe_checkout @type(name: "StripeCheckout") {
                amount_discount
                amount_total
                currency
                id
                session_id
                status
                verification_step_passing_id
              }
              case_custom_step_evaluation
                @type(name: "CaseCustomStepEvaluation") {
                id
                custom_step_action_evaluations
                  @type(name: "CustomStepActionEvaluation") {
                  id
                  value
                  uploaded_document_id
                  file_name
                  file_size
                  custom_step_action @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
                custom_step @type(name: "CustomStep") {
                  id
                  name
                  custom_actions @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
              }
              case_section_tab_id
              description
            }
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
            is_my
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const CreateProjectionCheckup = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  mutation CreateProjectionCheckup(
    $data_sharing_id: String!
    $case_projection_id: String!
    $body: RequestCheckupCreate!
  ) {
    createProjectionCheckup(
      body: $body
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "CreateProjectionCheckup"
        path: "/core/client/sharing/case_projections/{args.case_projection_id}/add_screening?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          sharing_entities
          allowed_actions
          form_submission_ids
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
              id
              field_submission_id
            }
            case_section_tabs @type(name: "ProjectionCaseSectionTab") {
              failed_questionnaire_items
                @type(name: "FailedQuestionnaireItem") {
                id
                field_submission_id
              }
              name
              case_section_items @type(name: "ProjectionCaseSectionItem") {
                ai_task
                value
                is_my
                naics_name
                audit_id
                case_section_tab_id
                checkup_id
                checkup_result
                created_at
                description
                device_info
                form_audit
                form_field
                form_group
                form_screen
                created_at
                file_name
                file_size
                form_submission_id
                id
                updated_at
                uploaded_document_id
                kind
                customer_document_id
                case_question @type(name: "CaseQuestion") {
                  id
                  answer_body
                  author @type(name: "Account") {
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    id
                    phone
                  }
                  case_id
                  case_question_config_id
                  comment
                  created_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  question_body
                  status
                  updated_at
                }
                case_summary @type(name: "AISummary") {
                  id
                  body
                  case_id
                  case_summary_config_id
                  client_id
                  created_at
                  current_revision @type(name: "AISummaryRevision") {
                    id
                    body
                    case_summary_id
                    client_id
                    comment
                    created_at
                    edited_at
                    is_marked_as_actual
                    is_marked_as_irrelevant
                    request_prompt
                    status
                    updated_at
                    edited_by @type(name: "Account") {
                      id
                      avatar_id
                      email
                      file_name
                      file_size
                      full_name
                      phone
                    }
                  }
                  revisions_count
                  updated_at
                  used_sources
                }
                customer_document @type(name: "CustomerDocument") {
                  id
                  client_id
                  veriff_result_id
                  idenfy_result_id
                  created_at
                  updated_at
                  date_of_birth
                  date_of_issue
                  expiry_date
                  first_name
                  last_name
                  gender
                  document_number
                  document_type
                  blacklisted
                  country
                  nationality
                  attachments @type(name: "Attachment") {
                    ...AttachmentFragment
                  }
                  profiles @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  customers @type(name: "Customer") {
                    avatar_id
                    company_name
                    created_at
                    customer_verification_full_name
                    email
                    first_name
                    full_name
                    id
                    last_name
                    links
                    phone
                    profile @type(name: "Profile") {
                      avatar_id
                      created_at
                      display_name
                      email
                      id
                      is_whitelisted
                      kind
                      phone
                      updated_at
                    }
                    region_code
                    updated_at
                  }
                }
                customer_message @type(name: "CustomerMessage") {
                  id
                  body
                  client_id
                  created_at
                  name
                  state
                  subject
                  updated_at
                  email
                  account @type(name: "Account") {
                    id
                    full_name
                  }
                  header
                  sent_at
                }
                idenfy_result @type(name: "IdenfyResult") {
                  ...IdenfyResultFragment
                }
                veriff_result @type(name: "VeriffResult") {
                  ...VeriffResultFragment
                }
                stripe_checkout @type(name: "StripeCheckout") {
                  amount_discount
                  amount_total
                  currency
                  id
                  session_id
                  status
                  verification_step_passing_id
                }
                case_custom_step_evaluation
                  @type(name: "CaseCustomStepEvaluation") {
                  id
                  custom_step_action_evaluations
                    @type(name: "CustomStepActionEvaluation") {
                    id
                    value
                    uploaded_document_id
                    file_name
                    file_size
                    custom_step_action @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                  custom_step @type(name: "CustomStep") {
                    id
                    name
                    custom_actions @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                }
                case_section_tab_id
                description
              }
              case_section_items_count
              created_at
              id
              section_info {
                is_always_exists
                is_section_approvable
                is_section_approved_by_default
                is_tabs_enabled
                kind
              }
              is_tabs_enabled
              kind
              state
              updated_at
            }
            case_section_items @type(name: "ProjectionCaseSectionItem") {
              ai_task
              value
              is_my
              naics_name
              audit_id
              case_section_tab_id
              checkup_id
              checkup_result
              created_at
              description
              device_info
              form_audit
              form_field
              form_group
              form_screen
              created_at
              file_name
              file_size
              form_submission_id
              id
              updated_at
              uploaded_document_id
              kind
              customer_document_id
              case_question @type(name: "CaseQuestion") {
                id
                answer_body
                author @type(name: "Account") {
                  avatar_id
                  email
                  file_name
                  file_size
                  full_name
                  id
                  phone
                }
                case_id
                case_question_config_id
                comment
                created_at
                is_marked_as_actual
                is_marked_as_irrelevant
                question_body
                status
                updated_at
              }
              case_summary @type(name: "AISummary") {
                id
                body
                case_id
                case_summary_config_id
                client_id
                created_at
                current_revision @type(name: "AISummaryRevision") {
                  id
                  body
                  case_summary_id
                  client_id
                  comment
                  created_at
                  edited_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  request_prompt
                  status
                  updated_at
                  edited_by @type(name: "Account") {
                    id
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    phone
                  }
                }
                revisions_count
                updated_at
                used_sources
              }
              customer_document @type(name: "CustomerDocument") {
                id
                client_id
                veriff_result_id
                idenfy_result_id
                created_at
                updated_at
                date_of_birth
                date_of_issue
                expiry_date
                first_name
                last_name
                gender
                document_number
                document_type
                blacklisted
                country
                nationality
                attachments @type(name: "Attachment") {
                  ...AttachmentFragment
                }
                profiles @type(name: "Profile") {
                  avatar_id
                  created_at
                  display_name
                  email
                  id
                  is_whitelisted
                  kind
                  phone
                  updated_at
                }
                customers @type(name: "Customer") {
                  avatar_id
                  company_name
                  created_at
                  customer_verification_full_name
                  email
                  first_name
                  full_name
                  id
                  last_name
                  links
                  phone
                  profile @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  region_code
                  updated_at
                }
              }
              customer_message @type(name: "CustomerMessage") {
                id
                body
                client_id
                created_at
                name
                state
                subject
                updated_at
                email
                account @type(name: "Account") {
                  id
                  full_name
                }
                header
                sent_at
              }
              idenfy_result @type(name: "IdenfyResult") {
                ...IdenfyResultFragment
              }
              veriff_result @type(name: "VeriffResult") {
                ...VeriffResultFragment
              }
              stripe_checkout @type(name: "StripeCheckout") {
                amount_discount
                amount_total
                currency
                id
                session_id
                status
                verification_step_passing_id
              }
              case_custom_step_evaluation
                @type(name: "CaseCustomStepEvaluation") {
                id
                custom_step_action_evaluations
                  @type(name: "CustomStepActionEvaluation") {
                  id
                  value
                  uploaded_document_id
                  file_name
                  file_size
                  custom_step_action @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
                custom_step @type(name: "CustomStep") {
                  id
                  name
                  custom_actions @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
              }
              case_section_tab_id
              description
            }
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
            is_my
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const CreateProjectionEDDReport = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  mutation CreateProjectionEDDReport(
    $data_sharing_id: String!
    $case_projection_id: String!
    $body: BodyCreateEDDReport!
  ) {
    createProjectionEDDReport(
      body: $body
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "CreateProjectionEDDReport"
        path: "/core/client/sharing/case_projections/{args.case_projection_id}/add_edd_report?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          sharing_entities
          allowed_actions
          form_submission_ids
          edd_reports @type(name: "EDDReport") {
            assigned_to_id
            case_ids
            client_id
            completed_at
            created_at
            description
            form_configuration_id
            form_submission_id
            id
            is_my
            main_profile_id
            main_profile @type(name: "Profile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            profile_kind
            status
            updated_at
          }
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
              id
              field_submission_id
            }
            case_section_tabs @type(name: "ProjectionCaseSectionTab") {
              failed_questionnaire_items
                @type(name: "FailedQuestionnaireItem") {
                id
                field_submission_id
              }
              name
              case_section_items @type(name: "ProjectionCaseSectionItem") {
                ai_task
                value
                is_my
                naics_name
                audit_id
                case_section_tab_id
                checkup_id
                checkup_result
                created_at
                description
                device_info
                form_audit
                form_field
                form_group
                form_screen
                created_at
                file_name
                file_size
                form_submission_id
                id
                updated_at
                uploaded_document_id
                kind
                customer_document_id
                case_question @type(name: "CaseQuestion") {
                  id
                  answer_body
                  author @type(name: "Account") {
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    id
                    phone
                  }
                  case_id
                  case_question_config_id
                  comment
                  created_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  question_body
                  status
                  updated_at
                }
                case_summary @type(name: "AISummary") {
                  id
                  body
                  case_id
                  case_summary_config_id
                  client_id
                  created_at
                  current_revision @type(name: "AISummaryRevision") {
                    id
                    body
                    case_summary_id
                    client_id
                    comment
                    created_at
                    edited_at
                    is_marked_as_actual
                    is_marked_as_irrelevant
                    request_prompt
                    status
                    updated_at
                    edited_by @type(name: "Account") {
                      id
                      avatar_id
                      email
                      file_name
                      file_size
                      full_name
                      phone
                    }
                  }
                  revisions_count
                  updated_at
                  used_sources
                }
                customer_document @type(name: "CustomerDocument") {
                  id
                  client_id
                  veriff_result_id
                  idenfy_result_id
                  created_at
                  updated_at
                  date_of_birth
                  date_of_issue
                  expiry_date
                  first_name
                  last_name
                  gender
                  document_number
                  document_type
                  blacklisted
                  country
                  nationality
                  attachments @type(name: "Attachment") {
                    ...AttachmentFragment
                  }
                  profiles @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  customers @type(name: "Customer") {
                    avatar_id
                    company_name
                    created_at
                    customer_verification_full_name
                    email
                    first_name
                    full_name
                    id
                    last_name
                    links
                    phone
                    profile @type(name: "Profile") {
                      avatar_id
                      created_at
                      display_name
                      email
                      id
                      is_whitelisted
                      kind
                      phone
                      updated_at
                    }
                    region_code
                    updated_at
                  }
                }
                customer_message @type(name: "CustomerMessage") {
                  id
                  body
                  client_id
                  created_at
                  name
                  state
                  subject
                  updated_at
                  email
                  account @type(name: "Account") {
                    id
                    full_name
                  }
                  header
                  sent_at
                }
                idenfy_result @type(name: "IdenfyResult") {
                  ...IdenfyResultFragment
                }
                veriff_result @type(name: "VeriffResult") {
                  ...VeriffResultFragment
                }
                stripe_checkout @type(name: "StripeCheckout") {
                  amount_discount
                  amount_total
                  currency
                  id
                  session_id
                  status
                  verification_step_passing_id
                }
                case_custom_step_evaluation
                  @type(name: "CaseCustomStepEvaluation") {
                  id
                  custom_step_action_evaluations
                    @type(name: "CustomStepActionEvaluation") {
                    id
                    value
                    uploaded_document_id
                    file_name
                    file_size
                    custom_step_action @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                  custom_step @type(name: "CustomStep") {
                    id
                    name
                    custom_actions @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                }
                case_section_tab_id
                description
              }
              case_section_items_count
              created_at
              id
              section_info {
                is_always_exists
                is_section_approvable
                is_section_approved_by_default
                is_tabs_enabled
                kind
              }
              is_tabs_enabled
              kind
              state
              updated_at
            }
            case_section_items @type(name: "ProjectionCaseSectionItem") {
              ai_task
              value
              is_my
              naics_name
              audit_id
              case_section_tab_id
              checkup_id
              checkup_result
              created_at
              description
              device_info
              form_audit
              form_field
              form_group
              form_screen
              created_at
              file_name
              file_size
              form_submission_id
              id
              updated_at
              uploaded_document_id
              kind
              customer_document_id
              case_question @type(name: "CaseQuestion") {
                id
                answer_body
                author @type(name: "Account") {
                  avatar_id
                  email
                  file_name
                  file_size
                  full_name
                  id
                  phone
                }
                case_id
                case_question_config_id
                comment
                created_at
                is_marked_as_actual
                is_marked_as_irrelevant
                question_body
                status
                updated_at
              }
              case_summary @type(name: "AISummary") {
                id
                body
                case_id
                case_summary_config_id
                client_id
                created_at
                current_revision @type(name: "AISummaryRevision") {
                  id
                  body
                  case_summary_id
                  client_id
                  comment
                  created_at
                  edited_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  request_prompt
                  status
                  updated_at
                  edited_by @type(name: "Account") {
                    id
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    phone
                  }
                }
                revisions_count
                updated_at
                used_sources
              }
              customer_document @type(name: "CustomerDocument") {
                id
                client_id
                veriff_result_id
                idenfy_result_id
                created_at
                updated_at
                date_of_birth
                date_of_issue
                expiry_date
                first_name
                last_name
                gender
                document_number
                document_type
                blacklisted
                country
                nationality
                attachments @type(name: "Attachment") {
                  ...AttachmentFragment
                }
                profiles @type(name: "Profile") {
                  avatar_id
                  created_at
                  display_name
                  email
                  id
                  is_whitelisted
                  kind
                  phone
                  updated_at
                }
                customers @type(name: "Customer") {
                  avatar_id
                  company_name
                  created_at
                  customer_verification_full_name
                  email
                  first_name
                  full_name
                  id
                  last_name
                  links
                  phone
                  profile @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  region_code
                  updated_at
                }
              }
              customer_message @type(name: "CustomerMessage") {
                id
                body
                client_id
                created_at
                name
                state
                subject
                updated_at
                email
                account @type(name: "Account") {
                  id
                  full_name
                }
                header
                sent_at
              }
              idenfy_result @type(name: "IdenfyResult") {
                ...IdenfyResultFragment
              }
              veriff_result @type(name: "VeriffResult") {
                ...VeriffResultFragment
              }
              stripe_checkout @type(name: "StripeCheckout") {
                amount_discount
                amount_total
                currency
                id
                session_id
                status
                verification_step_passing_id
              }
              case_custom_step_evaluation
                @type(name: "CaseCustomStepEvaluation") {
                id
                custom_step_action_evaluations
                  @type(name: "CustomStepActionEvaluation") {
                  id
                  value
                  uploaded_document_id
                  file_name
                  file_size
                  custom_step_action @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
                custom_step @type(name: "CustomStep") {
                  id
                  name
                  custom_actions @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
              }
              case_section_tab_id
              description
            }
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
            is_my
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const UnlinkFromCaseProjectionEDDReport = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  mutation UnlinkFromCaseProjectionEDDReport(
    $id: String!
    $data_sharing_id: String!
    $case_projection_id: String!
    $body: BodyLinkOrUnlinkToEDDReport!
  ) {
    unlinkFromCaseProjectionEDDReport(
      id: $id
      body: $body
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "CreateProjectionEDDReport"
        path: "/core/client/sharing/case_projections/{args.case_projection_id}/edd_reports/{args.id}/unlink_from_edd_report?data_sharing_id={args.data_sharing_id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          sharing_entities
          allowed_actions
          form_submission_ids
          edd_reports @type(name: "EDDReport") {
            assigned_to_id
            case_ids
            client_id
            completed_at
            created_at
            description
            form_configuration_id
            form_submission_id
            id
            is_my
            main_profile_id
            main_profile @type(name: "Profile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            profile_kind
            status
            updated_at
          }
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
              id
              field_submission_id
            }
            case_section_tabs @type(name: "ProjectionCaseSectionTab") {
              failed_questionnaire_items
                @type(name: "FailedQuestionnaireItem") {
                id
                field_submission_id
              }
              name
              case_section_items @type(name: "ProjectionCaseSectionItem") {
                ai_task
                value
                is_my
                naics_name
                audit_id
                case_section_tab_id
                checkup_id
                checkup_result
                created_at
                description
                device_info
                form_audit
                form_field
                form_group
                form_screen
                created_at
                file_name
                file_size
                form_submission_id
                id
                updated_at
                uploaded_document_id
                kind
                customer_document_id
                case_question @type(name: "CaseQuestion") {
                  id
                  answer_body
                  author @type(name: "Account") {
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    id
                    phone
                  }
                  case_id
                  case_question_config_id
                  comment
                  created_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  question_body
                  status
                  updated_at
                }
                case_summary @type(name: "AISummary") {
                  id
                  body
                  case_id
                  case_summary_config_id
                  client_id
                  created_at
                  current_revision @type(name: "AISummaryRevision") {
                    id
                    body
                    case_summary_id
                    client_id
                    comment
                    created_at
                    edited_at
                    is_marked_as_actual
                    is_marked_as_irrelevant
                    request_prompt
                    status
                    updated_at
                    edited_by @type(name: "Account") {
                      id
                      avatar_id
                      email
                      file_name
                      file_size
                      full_name
                      phone
                    }
                  }
                  revisions_count
                  updated_at
                  used_sources
                }
                customer_document @type(name: "CustomerDocument") {
                  id
                  client_id
                  veriff_result_id
                  idenfy_result_id
                  created_at
                  updated_at
                  date_of_birth
                  date_of_issue
                  expiry_date
                  first_name
                  last_name
                  gender
                  document_number
                  document_type
                  blacklisted
                  country
                  nationality
                  attachments @type(name: "Attachment") {
                    ...AttachmentFragment
                  }
                  profiles @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  customers @type(name: "Customer") {
                    avatar_id
                    company_name
                    created_at
                    customer_verification_full_name
                    email
                    first_name
                    full_name
                    id
                    last_name
                    links
                    phone
                    profile @type(name: "Profile") {
                      avatar_id
                      created_at
                      display_name
                      email
                      id
                      is_whitelisted
                      kind
                      phone
                      updated_at
                    }
                    region_code
                    updated_at
                  }
                }
                customer_message @type(name: "CustomerMessage") {
                  id
                  body
                  client_id
                  created_at
                  name
                  state
                  subject
                  updated_at
                  email
                  account @type(name: "Account") {
                    id
                    full_name
                  }
                  header
                  sent_at
                }
                idenfy_result @type(name: "IdenfyResult") {
                  ...IdenfyResultFragment
                }
                veriff_result @type(name: "VeriffResult") {
                  ...VeriffResultFragment
                }
                stripe_checkout @type(name: "StripeCheckout") {
                  amount_discount
                  amount_total
                  currency
                  id
                  session_id
                  status
                  verification_step_passing_id
                }
                case_custom_step_evaluation
                  @type(name: "CaseCustomStepEvaluation") {
                  id
                  custom_step_action_evaluations
                    @type(name: "CustomStepActionEvaluation") {
                    id
                    value
                    uploaded_document_id
                    file_name
                    file_size
                    custom_step_action @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                  custom_step @type(name: "CustomStep") {
                    id
                    name
                    custom_actions @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                }
                case_section_tab_id
                description
              }
              case_section_items_count
              created_at
              id
              section_info {
                is_always_exists
                is_section_approvable
                is_section_approved_by_default
                is_tabs_enabled
                kind
              }
              is_tabs_enabled
              kind
              state
              updated_at
            }
            case_section_items @type(name: "ProjectionCaseSectionItem") {
              ai_task
              value
              is_my
              naics_name
              audit_id
              case_section_tab_id
              checkup_id
              checkup_result
              created_at
              description
              device_info
              form_audit
              form_field
              form_group
              form_screen
              created_at
              file_name
              file_size
              form_submission_id
              id
              updated_at
              uploaded_document_id
              kind
              customer_document_id
              case_question @type(name: "CaseQuestion") {
                id
                answer_body
                author @type(name: "Account") {
                  avatar_id
                  email
                  file_name
                  file_size
                  full_name
                  id
                  phone
                }
                case_id
                case_question_config_id
                comment
                created_at
                is_marked_as_actual
                is_marked_as_irrelevant
                question_body
                status
                updated_at
              }
              case_summary @type(name: "AISummary") {
                id
                body
                case_id
                case_summary_config_id
                client_id
                created_at
                current_revision @type(name: "AISummaryRevision") {
                  id
                  body
                  case_summary_id
                  client_id
                  comment
                  created_at
                  edited_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  request_prompt
                  status
                  updated_at
                  edited_by @type(name: "Account") {
                    id
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    phone
                  }
                }
                revisions_count
                updated_at
                used_sources
              }
              customer_document @type(name: "CustomerDocument") {
                id
                client_id
                veriff_result_id
                idenfy_result_id
                created_at
                updated_at
                date_of_birth
                date_of_issue
                expiry_date
                first_name
                last_name
                gender
                document_number
                document_type
                blacklisted
                country
                nationality
                attachments @type(name: "Attachment") {
                  ...AttachmentFragment
                }
                profiles @type(name: "Profile") {
                  avatar_id
                  created_at
                  display_name
                  email
                  id
                  is_whitelisted
                  kind
                  phone
                  updated_at
                }
                customers @type(name: "Customer") {
                  avatar_id
                  company_name
                  created_at
                  customer_verification_full_name
                  email
                  first_name
                  full_name
                  id
                  last_name
                  links
                  phone
                  profile @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  region_code
                  updated_at
                }
              }
              customer_message @type(name: "CustomerMessage") {
                id
                body
                client_id
                created_at
                name
                state
                subject
                updated_at
                email
                account @type(name: "Account") {
                  id
                  full_name
                }
                header
                sent_at
              }
              idenfy_result @type(name: "IdenfyResult") {
                ...IdenfyResultFragment
              }
              veriff_result @type(name: "VeriffResult") {
                ...VeriffResultFragment
              }
              stripe_checkout @type(name: "StripeCheckout") {
                amount_discount
                amount_total
                currency
                id
                session_id
                status
                verification_step_passing_id
              }
              case_custom_step_evaluation
                @type(name: "CaseCustomStepEvaluation") {
                id
                custom_step_action_evaluations
                  @type(name: "CustomStepActionEvaluation") {
                  id
                  value
                  uploaded_document_id
                  file_name
                  file_size
                  custom_step_action @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
                custom_step @type(name: "CustomStep") {
                  id
                  name
                  custom_actions @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
              }
              case_section_tab_id
              description
            }
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
            is_my
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;
