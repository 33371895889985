import { useCallback, useMemo, useState } from 'react';
import { ClientPickerViewProps } from '../types';
import { SelectOption, SingleValue } from '@aid-module/ui';
import { useClientPickerModel } from '../model';

export function useClientPickerController(
  sharingKey: ClientPickerViewProps['sharingKey'],
  onRequestClose: ClientPickerViewProps['onRequestClose'],
  clients: ClientPickerViewProps['clients'],
  accountId: ClientPickerViewProps['accountId']
) {
  const { change, loading } = useClientPickerModel();
  const [client, setClient] = useState({
    label: clients[0].name,
    value: clients[0].id,
  });

  const options = useMemo(
    () => clients.map((client) => ({ label: client.name, value: client.id })),
    [clients]
  );

  const onChange = useCallback((value: SingleValue<SelectOption>) => {
    if (value) {
      setClient(value);
    }
  }, []);

  const onClickDecline = useCallback(async () => {
    const res = await change(sharingKey, accountId, 'declined');
    if (res) {
      onRequestClose();
    }
  }, [sharingKey, accountId, change, onRequestClose]);

  const onClickAccept = useCallback(async () => {
    const res = await change(sharingKey, accountId, 'invite_accepted', client.value);
    if (res) {
      onRequestClose();
    }
  }, [sharingKey, accountId, change, client, onRequestClose]);

  return { client, onChange, options, onClickDecline, loading, onClickAccept };
}
