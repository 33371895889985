import { gql } from '@apollo/client';

export const SarReports = gql`
  query SarReports(
    $page: Int
    $q: String
    $created_by_id: String
    $assigned_at_id: String
    $created_at_from: String
    $created_at_to: String
    $updated_at_from: String
    $updated_at_to: String
    $status: String
    $case_id: String
    $alert_id: String
    $filing_type: String
    $sort_field: String
    $sort_order: String
  ) {
    sarReports(
      page: $page
      per: 20
      q: $q
      filing_type: $filing_type
      created_by_id: $created_by_id
      assigned_at_id: $assigned_at_id
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      updated_at_from: $updated_at_from
      updated_at_to: $updated_at_to
      status: $status
      case_id: $case_id
      alert_id: $alert_id
      sort_field: $sort_field
      sort_order: $sort_order
    )
      @rest(
        type: "ResponseSarReports"
        path: "/sar/suspicious_activity_reports?{args}"
      ) {
      data @type(name: "SarReport") {
        activity_type
        amount
        assigned_at @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        assigned_at_id
        attachment_suspicious_activity_reports
        bsa_status
        bsa_tracking_id
        created_at
        created_by @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        created_by_id
        deadline
        filing_name
        id
        note_to_fin_cen
        prior_report_bsa_id
        queue
        status
        type_of_filings
        type_of_filings_id
        updated_at
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const SarReport = gql`
  query SarReport($id: String!) {
    sarReport(id: $id)
      @rest(
        type: "ResponseSarReports"
        path: "/sar/suspicious_activity_reports/{args.id}"
      ) {
      data @type(name: "SarReport") {
        activity_type
        alerts_suspicious_activity_reports @type(name: "SarEventReport") {
          alert_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        amount
        assigned_at @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        assigned_at_id
        attachment_suspicious_activity_reports
        bsa_status
        bsa_tracking_id
        cases_suspicious_activity_reports @type(name: "SarCaseReport") {
          case_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        created_at
        created_by @type(name: "Account") {
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        created_by_id
        deadline
        events_suspicious_activity_reports @type(name: "SarEventReport") {
          event_id
          created_at
          id
          name
          suspicious_activity_report_id
          updated_at
        }
        filing_institution
        filing_name
        financial_institution
        id
        narrative
        note_to_fin_cen
        prior_report_bsa_id
        queue
        status
        subject
        suspicious_activity_information
        type_of_filings
        type_of_filings_id
        updated_at
      }
      success
    }
  }
`;

export const SarStats = gql`
  query SarStats(
    $q: String
    $created_by_id: String
    $assigned_at_id: String
    $created_at_from: String
    $created_at_to: String
    $updated_at_from: String
    $updated_at_to: String
    $case_id: String
    $alert_id: String
    $filing_type: String
  ) {
    sarStats(
      q: $q
      filing_type: $filing_type
      created_by_id: $created_by_id
      assigned_at_id: $assigned_at_id
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      updated_at_from: $updated_at_from
      updated_at_to: $updated_at_to
      case_id: $case_id
      alert_id: $alert_id
    )
      @rest(
        type: "ResponseSarReports"
        path: "/sar/suspicious_activity_reports/stats?{args}"
      ) {
      data {
        status {
          in_progress
          ready_for_review
          submitting
          submission_accepted
          submission_successful
          submission_failed
          archived
        }
      }
      success
    }
  }
`;
