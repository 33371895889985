import { gql } from '@apollo/client';

export const DataSharings = gql`
  query DataSharings(
    $page: Int
    $per: Int
    $q: String
    $as_sender: Boolean
    $as_receiver: Boolean
    $states: [String!]
    $created_at_from: String
    $created_at_to: String
    $expired_at_from: String
    $expired_at_to: String
    $accepted_at_from: String
    $accepted_at_to: String
    $sender_account_id: String
    $receiver_account_id: String
    $sort_field: String
    $sort_order: String
    $receiver_email_is_null: Boolean
  ) {
    dataSharings(
      q: $q
      page: $page
      per: $per
      as_sender: $as_sender
      as_receiver: $as_receiver
      states: $states
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      expired_at_from: $expired_at_from
      expired_at_to: $expired_at_to
      accepted_at_from: $accepted_at_from
      accepted_at_to: $accepted_at_to
      sender_account_id: $sender_account_id
      receiver_account_id: $receiver_account_id
      sort_field: $sort_field
      sort_order: $sort_order
      receiver_email_is_null: $receiver_email_is_null
    )
      @rest(
        type: "ResponseDataSharing"
        path: "/core/client/data_sharings?{args}"
      ) {
      data @type(name: "DataSharing") {
        accepted_at
        case_projections_count
        created_at
        data_sharing_key @type(name: "DataSharingKey") {
          created_at
          expired_at
          id
          updated_at
          value
        }
        data_sharing_rules @type(name: "DataSharingRule") {
          allowed_actions
          created_at
          data_sharing_id
          data_sharing_rule_cases_count
          enabled
          frontend_type
          id
          name
          sharing_additional_case_ids
          sharing_entities
          sharing_filters
          updated_at
        }
        expired_at
        id
        message
        receiver_email
        sender_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        sender_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        receiver_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        receiver_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        state
        updated_at
        warning_code
        warning_title
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const DataSharing = gql`
  query DataSharing($id: String!) {
    dataSharing(id: $id)
      @rest(
        type: "ResponseDataSharing"
        path: "/core/client/data_sharings/{args.id}"
      ) {
      data @type(name: "DataSharing") {
        accepted_at
        case_projections_count
        created_at
        data_sharing_key @type(name: "DataSharingKey") {
          created_at
          expired_at
          id
          updated_at
          value
        }
        data_sharing_rules @type(name: "DataSharingRule") {
          allowed_actions
          created_at
          data_sharing_id
          data_sharing_rule_cases_count
          enabled
          frontend_type
          id
          name
          sharing_additional_case_ids
          sharing_entities
          sharing_filters
          updated_at
        }
        expired_at
        id
        message
        receiver_email
        sender_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        sender_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        receiver_account @type(name: "Account") {
          avatar_id
          email
          file_name
          file_size
          full_name
          id
          phone
        }
        receiver_client @type(name: "Client") {
          file_name
          file_size
          id
          logo_id
          name
        }
        state
        updated_at
        warning_code
        warning_title
      }
      success
    }
  }
`;

export const DataSharingStats = gql`
  query DataSharingStats(
    $as_sender: Boolean
    $as_receiver: Boolean
    $created_at_from: String
    $created_at_to: String
    $expired_at_from: String
    $expired_at_to: String
    $accepted_at_from: String
    $accepted_at_to: String
    $sender_account_id: String
    $receiver_account_id: String
    $receiver_email_is_null: Boolean
  ) {
    dataSharingStats(
      as_sender: $as_sender
      as_receiver: $as_receiver
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      expired_at_from: $expired_at_from
      expired_at_to: $expired_at_to
      accepted_at_from: $accepted_at_from
      accepted_at_to: $accepted_at_to
      sender_account_id: $sender_account_id
      receiver_account_id: $receiver_account_id
      receiver_email_is_null: $receiver_email_is_null
    )
      @rest(
        type: "ResponseDataSharing"
        path: "/core/client/data_sharings/stats?{args}"
      ) {
      data
      success
    }
  }
`;

export const DataSharingAvailableClients = gql`
  query DataSharingAvailableClients($id: String!) {
    dataSharingAvailableClients(id: $id)
      @rest(
        type: "ResponseDataSharing"
        path: "/core/customer/data_sharings/{args.id}/get_available_clients"
      ) {
      data
      success
    }
  }
`;

export const DataSharingAITasks = gql`
  query DataSharingAITasks(
    $page: Int
    $per: Int
    $case_projection_id: String
    $ai_task_config_id: String
    $processable_entity_type: String
    $processable_entity_id: String
    $status: String
    $kinds: String!
  ) {
    dataSharingAiTasks(
      page: $page
      per: $per
      case_projection_id: $case_projection_id
      ai_task_config_id: $ai_task_config_id
      processable_entity_type: $processable_entity_type
      processable_entity_id: $processable_entity_id
      status: $status
      kinds: $kinds
    ) @rest(type: "ResponseAITasks", path: "/core/client/sharing/ai_tasks?{args}") {
      data @type(name: "AITask") {
        id
        ai_task_config_id
        body
        structured_body
        created_by @type(name: "Account") {
          id
          first_name
          full_name
          last_name
          avatar_id
          email
          phone
        }
        client_id
        created_at
        current_ai_task_result_id
        kind
        options
        processable_entity_id
        processable_entity_type
        status
        updated_at
        current_ai_task_result @type(name: "AITaskResult") {
          id
          ai_task_id
          client_id
          comment
          created_at
          edited_at
          edited_by @type(name: "Account") {
            id
            email
            first_name
            full_name
            last_name
            region_code
            status
          }
          edited_by_id
          is_marked_as_actual
          is_marked_as_irrelevant
          status
          body
          updated_at
          llm_calls @type(name: "AITaskResultLLMCall") {
            response
            status
          }
          options
        }
        ai_task_results @type(name: "AITaskReuslt") {
          id
          ai_task_id
          client_id
          comment
          created_at
          edited_at
          edited_by @type(name: "Account") {
            id
            email
            first_name
            full_name
            last_name
            region_code
            status
          }
          edited_by_id
          is_marked_as_actual
          is_marked_as_irrelevant
          status
          body
          structured_body
          updated_at
          llm_calls @type(name: "AITaskResultLLMCall") {
            response
            status
          }
          options
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
