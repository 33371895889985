import { gql } from '@apollo/client';
import { CASE_FRAGMENT } from './Case';

export const CASE_DESCRIPTION_FRAGMENT = gql`
  ${CASE_FRAGMENT}
  fragment CaseDescriptionFragment on Case {
    ...CaseFragment
    enabled_features_codes
    form_submission_ids
    form_submissions @type(name: "CaseSubmission") {
      id
      form_configuration_id
    }
    main_profile @type(name: "Profile") {
      avatar_id
      created_at
      display_name
      id
      is_whitelisted
      kind
      updated_at
    }
    main_profile_id
    rejection_reason @type(name: "RejectionReason") {
      code
      id
      name
    }
  }
`;
