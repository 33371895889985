import { THEME_COLORS } from '../../shared';

export enum SIZES {
  'XXXXS',
  'XXXS',
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'SXL',
}

export interface CardViewProps {
  border?: keyof THEME_COLORS;
  background?: keyof THEME_COLORS;
  padding?: keyof typeof SIZES;
  className?: string;
  loaderBackground?: keyof THEME_COLORS;
  loaderForeground?: keyof THEME_COLORS;
  borderRadius?: number;
}

export interface EmotionCardViewProps extends CardViewProps {}
