import { ContentLoader, GridContainer } from '@aid-module/ui';

export function Fallback() {
  return (
    <GridContainer gap="12">
      <ContentLoader width="100%" height="26px">
        <rect rx={4} ry={4} width="100%" height="26px" />
      </ContentLoader>
      <ContentLoader width="100%" height="229.38px">
        <rect rx={4} ry={4} width="100%" height="229.38px" />
      </ContentLoader>
      <ContentLoader width="100%" height="300px">
        <rect rx={4} ry={4} width="100%" height="300px" />
      </ContentLoader>
    </GridContainer>
  );
}
