import gql from 'graphql-tag';
import { COMMENT_FRAGMENT } from '../../fragment/comment';

export const CreateComment = gql`
  ${COMMENT_FRAGMENT}
  mutation CreateComment(
    $type: CommentType!
    $data_sharing_id: String = ""
    $case_projection_id: String = ""
    $body: BodyCreateOrUpdateComment!
  ) {
    createComment(
      type: $type
      body: $body
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "CommentCreate"
        path: "{args.type}?data_sharing_id={args.data_sharing_id}&case_projection_id={args.case_projection_id}"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Comment") {
        ...CommentFragment
      }
      success
    }
  }
`;

export const CreateCommentBulk = gql`
  ${COMMENT_FRAGMENT}
  mutation CreateCommentBulk(
    $type: CommentType!
    $data_sharing_id: String
    $case_projection_id: String
    $body: BodyCreateCommentBulk!
  ) {
    createCommentBulk(
      type: $type
      body: $body
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "CommentCreateBulk"
        path: "{args.type}?data_sharing_id={args.data_sharing_id}&case_projection_id={args.case_projection_id}"
        bodyKey: "body"
        method: "POST"
      ) {
      data @type(name: "Comment") {
        ...CommentFragment
      }
      success
    }
  }
`;

export const UpdateComment = gql`
  ${COMMENT_FRAGMENT}
  mutation UpdateComment(
    $type: CommentType!
    $id: String!
    $data_sharing_id: String
    $case_projection_id: String
    $body: BodyCreateOrUpdateComment!
  ) {
    updateComment(
      type: $type
      id: $id
      body: $body
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "CommentUpdate"
        path: "{args.type}/{args.id}?data_sharing_id={args.data_sharing_id}&case_projection_id={args.case_projection_id}"
        bodyKey: "body"
        method: "PUT"
      ) {
      data @type(name: "Comment") {
        ...CommentFragment
      }
      success
    }
  }
`;
