import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { SharingNavigationItems } from '../../../../../../Breadcrumbs/constants';

export function useSharingLinkController() {
  const { client_id } = useParams();

  const sharingAccesiblePath = useMemo(() => {
    const firstAccesiblePath = SharingNavigationItems[0]?.path;

    return `/${client_id}${firstAccesiblePath}`;
  }, [client_id]);

  const sharingPaths = useMemo(() => {
    return SharingNavigationItems.map((item) => `/${client_id}${item.path}`);
  }, [client_id]);

  return {
    sharingPaths,
    sharingAccesiblePath,
  };
}
