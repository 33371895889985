import {
  Card,
  ErrorButton,
  GridContainer,
  Select,
  SuccessButton,
  Text,
} from '@aid-module/ui';
import { ClientPickerViewProps } from './types';
import { useClientPickerController } from './controller';

export function ClientPicker({
  sharingKey,
  onRequestClose,
  clients,
  isMultiple,
  accountId,
}: ClientPickerViewProps) {
  const { client, onChange, options, loading, onClickAccept, onClickDecline } =
    useClientPickerController(sharingKey, onRequestClose, clients, accountId);
  return (
    <Card
      background="main-deep-1"
      loaderForeground="main-deep-1"
      loaderBackground="main-deep-1"
      padding="SXL"
    >
      <GridContainer gridTemplateColumns="320px" gap="20">
        <GridContainer gap="16">
          <Text
            text="Another client has sent you an invite to create a data sharing connection"
            size={18}
            weight="VeryBig"
            color="main-deep-4"
          />
          <Text
            text="By accepting a data sharing invitation, you will be able to receive data from another client."
            weight="Thin"
            color="main-deep-4"
          />
        </GridContainer>
        {isMultiple ? (
          <Select
            label="To accept the invitation, please select a client in which the shared data will be displayed"
            value={client}
            onChange={onChange}
            options={options}
          />
        ) : null}
        <GridContainer gridTemplateColumns="1fr 1fr" gap="20">
          <ErrorButton
            text="Decline"
            disabled={loading}
            onClick={onClickDecline}
          />
          <SuccessButton
            text="Accept"
            disabled={loading}
            onClick={onClickAccept}
          />
        </GridContainer>
      </GridContainer>
    </Card>
  );
}
