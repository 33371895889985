import { TypePolicies, FieldPolicy, gql } from '@apollo/client';
import { RestArrayResponse } from './types';
import { toEndCallStack } from './utils';

function getArrayTypePolicy(
  type: string,
  keyArgs: string[] | false
): FieldPolicy {
  return {
    keyArgs,
    merge(
      existing: RestArrayResponse,
      incoming: RestArrayResponse,
      { args, toReference, fieldName, readField }
    ) {
      //TODO check why call refetchQueries does not provide existing cache data
      const currentExisting =
        existing ||
        readField({
          fieldName: fieldName,
          from: toReference('ROOT_QUERY'),
          args: args || undefined,
        });
      const count = args?.per || 20;
      const dataKey = `data@type({"name":"${type}"})`;
      const data =
        (currentExisting && [...(currentExisting?.[dataKey] as object[])]) ||
        [];
      const incomingData = (incoming?.[dataKey] as object[]) || [];
      const incomingMeta = incoming?.meta;
      const insetIndex = (incomingMeta.page - 1) * count;
      const lastIndex = incomingData.reduce((_, el, index) => {
        data[insetIndex + index] = el;
        return insetIndex + index;
      }, 0);

      return {
        [dataKey]: data.slice(
          0,
          lastIndex === 0 && incomingData.length === 0
            ? lastIndex
            : lastIndex + 1
        ),
        success: incoming.success,
        meta: incoming.meta,
      };
    },
  };
}

function getSingleTypePolicy(type: string, responseType: string): FieldPolicy {
  return {
    read(_, { args, toReference }) {
      return {
        [`data@type({"name":"${type}"})`]: toReference({
          __typename: type,
          id: args?.id,
        }),
        success: true,
        __typename: responseType,
      };
    },
  };
}

function clearOnDelete(__typename: string): FieldPolicy {
  return {
    merge(_, next, { cache }) {
      const id = next?.data?.id;
      if (next?.success && id) {
        toEndCallStack(() => {
          const normalizedId = cache.identify({
            id,
            __typename,
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        });
      }
      return;
    },
  };
}

function deleteBlacklistProfile(__typename: string): FieldPolicy {
  return {
    merge(_, next, { cache }) {
      const data = next?.data;
      if (next.success && data) {
        cache.writeFragment({
          id: `Profile:${data.profile.id}`,
          fragment: gql`
            fragment DeleteBlacklistProfile on Profile {
              id
              blacklisted_profile_id
            }
          `,
          data: {
            id: data.profile.id,
            blacklisted_profile_id: null,
          },
        });
        toEndCallStack(() => {
          const normalizedId = cache.identify({
            id: data.id,
            __typename,
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        });
      }
      return;
    },
  };
}

export const typePolicies: TypePolicies = {
  ReminderSetting: {
    merge(_, incoming) {
      return incoming;
    },
  },
  TransactionCase: {
    fields: {
      activity_types: {
        merge(_, incoming) {
          return incoming;
        },
      },
      case_origins: {
        merge(_, incoming) {
          return incoming;
        },
      },
      resolutions: {
        merge(_, incoming) {
          return incoming;
        },
      },
      tags: {
        merge(_, incoming) {
          return incoming;
        },
      },
      alerts: {
        merge(_, incoming) {
          return incoming;
        },
      },
      events: {
        merge(_, incoming) {
          return incoming;
        },
      },
      entities: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  Case: {
    fields: {
      profiles: {
        merge(_, incoming) {
          return incoming;
        },
      },
      edd_reports: {
        merge(exist, incoming) {
          if (incoming === null) {
            return exist;
          }
          return incoming;
        },
      },
    },
  },
  Profile: {
    fields: {
      email: {
        merge(prev, incoming) {
          return incoming || prev;
        },
      },
      phone: {
        merge(prev, incoming) {
          return incoming || prev;
        },
      },
    },
  },
  CaseSection: {
    fields: {
      failed_questionnaire_items: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  FormGroup: {
    fields: {
      form_fields: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  FormScreen: {
    fields: {
      form_groups: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  Form: {
    fields: {
      form_screens: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  CaseScoringModel: {
    fields: {
      case_rules: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  TransactionAlert: {
    fields: {
      attachments: {
        merge(_, incoming) {
          return incoming;
        },
      },
      tags: {
        merge(_, incoming) {
          return incoming;
        },
      },
      profiles: {
        merge(_, incoming) {
          return incoming;
        },
      },
      cases: {
        merge(_, incoming) {
          return incoming;
        },
      },
    },
  },
  Mutation: {
    fields: {
      deleteRiskScoringTemplate: clearOnDelete('RiskScoringTemplate'),
      deleteBlacklistProfile: deleteBlacklistProfile('BlacklistedProfile'),
      deleteBlacklistReason: clearOnDelete('BlacklistReason'),
      deleteCheckupConfig: clearOnDelete('CheckupConfig'),
      deleteTransactionTag: clearOnDelete('TransactionTag'),
      deleteCaseSectionItem: clearOnDelete('CaseSection'),
      deleteResolution: clearOnDelete('Resolution'),
      deleteCaseRejectReason: clearOnDelete('CaseRejectReason'),
      deleteTabRegistry: clearOnDelete('TabRegistry'),
      deleteProhibitedCountry: clearOnDelete('ProhibitedCountry'),
      deleteCdsEntityTemplate: clearOnDelete('CdsEntityTemplate'),
      deleteCaseReminderSetting: clearOnDelete('ReminderSetting'),
      deleteNarrative: clearOnDelete('Narrative'),
      deleteTransactionScoringRule: clearOnDelete('TransactionScoringRule'),
      deleteTransactionScoringManualRule: clearOnDelete(
        'TransactionScoringManualRule'
      ),
      deleteTransactionScoringDependentRule: clearOnDelete(
        'TransactionScoringDependentRule'
      ),
      deleteTransactionScoringScoreRule: clearOnDelete(
        'TransactionScoringScoreRule'
      ),
    },
  },
  Query: {
    fields: {
      document: {
        keyArgs: false,
        read(...props) {
          const { read } = getSingleTypePolicy('Document', 'ResponseDocument');
          const data = read?.(...props);
          return {
            ...(data || {}),
            errors: [],
          };
        },
      },
      clients: getArrayTypePolicy('Client', ['q']),
      client: getSingleTypePolicy('Client', 'ResponseClient'),
      caseScoringModel: {
        keyArgs: ['case_config_id'],
        read(existing) {
          const ref = existing?.[`data@type({"name":"CaseScoringModel"})`];
          if (ref) {
            return {
              [`data@type({"name":"CaseScoringModel"})`]: ref,
              success: true,
              __typename: 'ResponseCaseScoringModel',
            };
          }
        },
      },
      formScoringModel: {
        keyArgs: ['form_configuration_id'],
        read(existing) {
          const ref = existing?.[`data@type({"name":"FormScoringModel"})`];
          if (ref) {
            return {
              [`data@type({"name":"FormScoringModel"})`]: ref,
              success: true,
              __typename: 'ResponseFormScoringModel',
            };
          }
        },
      },
      accounts: getArrayTypePolicy('Account', ['status', 'q']),
      tabRegistries: getArrayTypePolicy('TabRegistry', [
        'favorite',
        'registry_type',
        'per',
      ]),
      account: getSingleTypePolicy('Account', 'ResponseAccount'),
      accountStats: {
        keyArgs: ['client_id'],
        read(...props) {
          const { read } = getSingleTypePolicy(
            'AccountStats',
            'ResponseAccountStats'
          );
          const data = read?.(...props);
          return data || {};
        },
      },
      blacklistReasons: getArrayTypePolicy('BlacklistReason', ['q']),
      blacklistReason: getSingleTypePolicy(
        'BlacklistReason',
        'ResponseBlacklistReason'
      ),
      casesStats: {
        keyArgs: [
          'ids',
          'from_date',
          'to_date',
          'from_submission_date',
          'to_submission_date',
          'from_approved_date',
          'to_approved_date',
          'state',
          'assigned_to_id',
          'auto_audit_state',
          'score_more_then',
          'score_less_then',
          'is_shared',
          'case_sharings',
          'profile_id',
          'tab_registry_id',
          'search_by_field',
          'search_id',
          'form_configuration_reference_keys',
          'form_submission_id',
          'entity_type',
          'profile_kinds',
          'q',
        ],
        read(...props) {
          const { read } = getSingleTypePolicy(
            'CasesStats',
            'ResponseCasesStats'
          );
          const data = read?.(...props);
          return data || {};
        },
      },
      clientDictionaries: getArrayTypePolicy('ClientDictionaryItem', ['q']),
      checkupStats: {
        keyArgs: [
          'checkup_kind_code',
          'fincen_list_ids',
          'q',
          'resolutions',
          'execution_statuses',
          'audit_statuses',
          'provider_statuses',
          'external_ref_entity_types',
          'created_at_from',
          'created_at_to',
          'last_screened_at_from',
          'last_screened_at_to',
          'case_status',
          'blockchain',
          'cryptocurrency_symbol',
        ],
        read(...props) {
          const { read } = getSingleTypePolicy(
            'CheckupStats',
            'CheckupStatsResponse'
          );
          const data = read?.(...props);
          return data || {};
        },
      },
      alertsStats: {
        keyArgs: [
          'ruleset_id',
          'rule_id',
          'rule_code',
          'event_id',
          'from_date',
          'sender_entity_id',
          'receiver_entity_id',
          'to_date',
          'deadline_at_from',
          'deadline_at_to',
          'profile_id',
          'disposition',
          'assigned_to_id',
          'is_for_test',
          'is_from_scheduled_rule',
          'state',
          'q',
        ],
        read(...props) {
          const { read } = getSingleTypePolicy(
            'AlertsStats',
            'ResponseAlertsStats'
          );
          const data = read?.(...props);
          return data || {};
        },
      },
      eddReportsStats: {
        keyArgs: [
          'case_ids',
          'assigned_to_id',
          'form_configuration_id',
          'status',
          'main_profile_id',
          'form_submission_id',
          'from_created_at',
          'to_created_at',
          'completed_at_from',
          'to_completed_at',
          'profile_kinds',
          'per',
          'q',
        ],
        read(...props) {
          const { read } = getSingleTypePolicy(
            'EDDReportStats',
            'ResponseEDDReportStats'
          );
          const data = read?.(...props);
          return data || {};
        },
      },
      queuedAlertsStats: {
        keyArgs: [
          'ruleset_id',
          'rule_id',
          'rule_code',
          'event_id',
          'from_date',
          'sender_entity_id',
          'receiver_entity_id',
          'to_date',
          'deadline_at_from',
          'deadline_at_to',
          'profile_id',
          'disposition',
          'assigned_to_id',
          'is_for_test',
          'state',
          'q',
        ],
        read(...props) {
          const { read } = getSingleTypePolicy(
            'AlertsStats',
            'ResponseQueuedAlertsStats'
          );
          const data = read?.(...props);
          return data || {};
        },
      },
      resolutions: getArrayTypePolicy('Resolution', ['q']),
      resolution: getSingleTypePolicy('Resolution', 'ResponseResolution'),
      fieldTemplates: getArrayTypePolicy('FieldTemplate', [
        'kind',
        'q',
        'codes',
        'kinds',
        'common',
        'except_hidden',
      ]),
      cdsFormConfigurations: getArrayTypePolicy('CdsFormConfiguration', [
        'q',
        'only_not_mapped',
      ]),
      cdsCableRefreshTasks: getArrayTypePolicy('CdsCableRefreshTask', false),
      cdsCableCsvExports: getArrayTypePolicy('CdsCableCsvExport', [
        'table_name',
        'status',
      ]),
      adminFieldTemplates: getArrayTypePolicy('FieldTemplate', [
        'kind',
        'q',
        'codes',
        'kinds',
        'common',
        'except_hidden',
      ]),
      fieldTemplate: getSingleTypePolicy(
        'FieldTemplate',
        'ResponseFieldTemplate'
      ),
      adminFieldTemplate: getSingleTypePolicy(
        'FieldTemplate',
        'ResponseFieldTemplate'
      ),
      checkups: getArrayTypePolicy('Checkup', ['price_min', 'q', 'price_max']),
      checkupAttachments: getArrayTypePolicy('CheckupAttachment', [
        'checkup_id',
      ]),
      checkup: getSingleTypePolicy('Checkup', 'ResponseCheckup'),
      serviceCheckups: getArrayTypePolicy('ServiceCheckup', [
        'q',
        'resolutions',
        'ids',
        'case_id',
        'checkup_kind_code',
        'checkup_kind_codes',
        'fincen_list_ids',
        'execution_statuses',
        'audit_statuses',
        'provider_statuses',
        'external_ref_entity_id',
        'external_ref_entity_types',
        'created_at_from',
        'created_at_to',
        'last_screened_at_from',
        'last_screened_at_to',
        'sort_field',
        'sort_order',
        'assigned_to_id',
        'case_status',
        'cryptocurrency_symbol',
        'blockchain',
        'retention_plan_id',
      ]),
      instantCheckups: getArrayTypePolicy('InstantCheckup', [
        'price_min',
        'q',
        'price_max',
      ]),
      instantCheckup: getSingleTypePolicy(
        'InstantCheckup',
        'ResponseInstantCheckup'
      ),
      providers: getArrayTypePolicy('Provider', ['q']),
      provider: getSingleTypePolicy('Provider', 'ResponseProvider'),
      riskScoringTemplates: getArrayTypePolicy('RiskScoringTemplate', ['q']),
      riskScoringTemplate: getSingleTypePolicy(
        'RiskScoringTemplate',
        'ResponseRiskScoringTemplate'
      ),
      checkupKinds: getArrayTypePolicy('CheckupKind', ['q']),
      checkupKind: getSingleTypePolicy('CheckupKind', 'ResponseCheckupKind'),
      clientProviders: getArrayTypePolicy('ClientProvider', ['q']),
      clientProvider: getSingleTypePolicy(
        'ClientProvider',
        'ResponseClientProvider'
      ),
      clientCheckupKinds: getArrayTypePolicy('ClientCheckupKind', ['q']),
      clientCheckupKind: getSingleTypePolicy(
        'ClientCheckupKind',
        'ResponseClientCheckupKind'
      ),
      serviceCheckupKinds: getArrayTypePolicy('CheckupKind', ['q']),
      serviceCheckupKind: getSingleTypePolicy(
        'CheckupKind',
        'ResponseCheckupKind'
      ),
      regions: getArrayTypePolicy('Region', ['q', 'enabled']),
      region: getSingleTypePolicy('Region', 'ResponseRegion'),
      users: getArrayTypePolicy('User', [
        'status',
        'enabled',
        'q',
        'permission_codes',
        'has_assigned_cases',
        'has_assigned_screenings',
        'has_created_retention_plans',
        'role_id',
      ]),
      checkupsUsers: getArrayTypePolicy('User', [
        'status',
        'q',
        'permission_codes',
        'has_assigned_cases',
        'has_created_retention_plans',
        'has_assigned_screenings',
      ]),
      user: getSingleTypePolicy('User', 'ResponseUser'),
      roles: getArrayTypePolicy('Role', ['q', 'sort_field', 'sort_order']),
      role: getSingleTypePolicy('Role', 'ResponseRole'),
      transactions: getArrayTypePolicy('Transaction', [
        'q',
        'amount_to',
        'amount_from',
        'status',
        'kind',
      ]),
      transaction: getSingleTypePolicy('Transaction', 'ResponseRole'),
      formTags: getArrayTypePolicy('FormTag', ['q', 'kind']),
      formTag: getSingleTypePolicy('FormTag', 'ResponseFormTag'),
      profiles: getArrayTypePolicy('Profile', [
        'q',
        'kind',
        'created_at_from',
        'created_at_to',
        'form_submission_id',
        'sort_field',
        'case_id',
        'sort_order',
        'with_form_submissions',
        'retention_plan_id',
      ]),
      profile: getSingleTypePolicy('Profile', 'ResponseProfile'),
      slaGeneral: getArrayTypePolicy('SlaGeneral', ['states']),
      cases: getArrayTypePolicy('Case', [
        'reminder_interval_days',
        'ids',
        'from_date',
        'to_date',
        'from_submission_date',
        'to_submission_date',
        'from_approved_date',
        'to_approved_date',
        'state',
        'form_configuration_reference_keys',
        'required_action_source_ids',
        'profile_id',
        'assigned_to_id',
        'search_id',
        'auto_audit_state',
        'search_by_field',
        'case_id',
        'form_submission_id',
        'tab_registry_id',
        'score_more_then',
        'score_less_then',
        'is_shared',
        'sort_field',
        'sort_order',
        'entity_type',
        'q',
        'profile_kinds',
        'open_required_actions_exist',
        'bank_tariff_name',
        'status_onboarding_fee',
        'retention_plan_id',
        'data_sharing_id',
      ]),
      caseReminders: getArrayTypePolicy('CaseReminder', ['case_id']),
      caseReminderKinds: getArrayTypePolicy('ReminderKind', [
        'q',
        'case_section_kind',
      ]),
      caseReminderSettings: getArrayTypePolicy('ReminderSetting', [
        'q',
        'active',
        'case_state',
      ]),
      case: getSingleTypePolicy('Case', 'ResponseCase'),
      blacklistedProfiles: getArrayTypePolicy('BlacklistedProfile', [
        'q',
        'sort_field',
        'sort_order',
        'created_at_from',
        'created_at_to',
        'profile_kinds',
        'blacklisted_reason_id',
      ]),
      blacklistedProfile: getSingleTypePolicy(
        'BlacklistedProfile',
        'ResponseBlacklistProfile'
      ),
      submissions: getArrayTypePolicy('Submission', [
        'q',
        'sort_field',
        'sort_order',
        'profile_id',
        'audit_status',
        'created_after',
        'case_id',
        'created_before',
        'linked_with_submission_id',
        'doubles_for_device_info_id',
        'is_abandoned',
        'status',
        'form_ref',
        'form_id',
        'retention_plan_id',
      ]),
      revisions: getArrayTypePolicy('Submission', ['id']),
      submission: getSingleTypePolicy('Submission', 'ResponseSubmission'),
      transactionEvents: getArrayTypePolicy('TransactionEvent', [
        'q',
        'profile_id',
        'sort_field',
        'sort_order',
        'checkup_result_state',
        'entity_id',
        'instrument_id',
        'from_date',
        'to_date',
        'amount_to',
        'amount_from',
        'status',
        'rejection_reason_name',
        'bank_client_id',
        'event_ids',
      ]),
      transactionPriorAlerts: getArrayTypePolicy('TransactionAlert', ['id']),
      transactionPriorCases: getArrayTypePolicy('TransactionCase', ['id']),
      transactionEvent: getSingleTypePolicy(
        'TransactionEvent',
        'ResponseTransactionEvent'
      ),
      transactionCases: getArrayTypePolicy('TransactionCase', [
        'q',
        'profile_id',
        'created_at_from',
        'created_at_to',
        'deadline_at_from',
        'deadline_at_to',
        'closed_at_from',
        'closed_at_to',
        'total_amount_from',
        'total_amount_to',
        'is_for_test',
        'state',
        'account_id',
        'sort_field',
        'sort_order',
        'tag_names',
        'sender_entity_id',
        'receiver_entity_id',
        'resolution_ids',
      ]),
      transactionBlacklistGroups: getArrayTypePolicy(
        'TransactionBlacklistGroup',
        ['subject_id', 'subject_type']
      ),
      transactionBlacklistCommonItems: getArrayTypePolicy(
        'TransactionCommonItem',
        ['blacklist_group_id', 'kind']
      ),
      transactionCasesQueued: getArrayTypePolicy('TransactionCase', [
        'q',
        'profile_id',
        'created_at_from',
        'created_at_to',
        'deadline_at_from',
        'deadline_at_to',
        'closed_at_from',
        'closed_at_to',
        'total_amount_from',
        'sender_entity_id',
        'receiver_entity_id',
        'total_amount_to',
        'is_for_test',
        'state',
        'account_id',
        'sort_field',
        'sort_order',
        'tag_names',
      ]),
      transactionCase: getSingleTypePolicy(
        'TransactionCase',
        'ResponseTransactionCase'
      ),
      profileRelations: getArrayTypePolicy('ProfileRelation', ['q']),
      verificationFlows: getArrayTypePolicy('VerificationFlow', [
        'q',
        'sort_field',
        'sort_order',
        'enabled',
      ]),
      blacklistedDeviceInfos: getArrayTypePolicy('BlacklistedDeviceInfo', [
        'q',
      ]),
      verificationFlow: getSingleTypePolicy(
        'VerificationFlow',
        'ResponseVerificationFlow'
      ),
      forms: getArrayTypePolicy('Form', [
        'q',
        'order',
        'tags',
        'enabled',
        'linked_form_configuration_id',
        'linked_form_submission_id',
        'use_dictionary_with_id',
        'purpose_kind',
      ]),
      form: getSingleTypePolicy('Form', 'ResponseForm'),
      customerVerifications: getArrayTypePolicy('CustomerVerification', [
        'verification_flow_id',
        'retention_plan_id',
      ]),
      translations: getArrayTypePolicy('Translation', [
        'except_state',
        'state',
        'source_lang',
        'target_lang',
      ]),
      narrativeTemplates: getArrayTypePolicy('NarrativeTemplate', ['q']),
      narrativeTemplatesQueued: getArrayTypePolicy('NarrativeTemplate', [
        'case_id',
        'alert_id',
        'q',
      ]),
      narratives: getArrayTypePolicy('Narrative', [
        'subject_id',
        'subject_type',
      ]),
      auditConfigs: getArrayTypePolicy('AuditConfig', [
        'all_revisions',
        'external_source_ref',
      ]),
      auditConfig: getSingleTypePolicy('AuditConfig', 'ResponseAuditConfig'),
      checkupConfigs: getArrayTypePolicy('CheckupConfig', ['audit_config_id']),
      checkupConfig: getSingleTypePolicy(
        'CheckupConfig',
        'ResponseCheckupConfig'
      ),
      comments: getArrayTypePolicy('Comment', [
        'type',
        'state',
        'subject_id',
        'subject_type',
        'subject_entity_id',
        'subject_entity_type',
        'event_id',
        'account_id',
        'is_not_deleted',
        'all_and_my_hidden',
        'data_sharing_id',
        'case_projection_id',
      ]),
      transactionRuleResults: getArrayTypePolicy('TransactionRuleResult', [
        'rule_id',
      ]),
      transactionRules: getArrayTypePolicy('Rule', ['enabled', 'is_for_test']),
      transactionQueues: getArrayTypePolicy('TransactionQueue', [
        'q',
        'sort_field',
        'sort_order',
        'per',
      ]),
      transactionAlerts: getArrayTypePolicy('TransactionAlert', [
        'ruleset_id',
        'rule_id',
        'rule_code',
        'event_id',
        'from_date',
        'sender_entity_id',
        'receiver_entity_id',
        'to_date',
        'deadline_at_from',
        'deadline_at_to',
        'profile_id',
        'disposition',
        'assigned_to_id',
        'is_for_test',
        'is_from_scheduled_rule',
        'state',
        'q',
        'page',
        'per',
        'sort_field',
        'sort_order',
        'alert_ids',
      ]),
      transactionAlertsQueued: getArrayTypePolicy('TransactionAlert', [
        'ruleset_id',
        'rule_id',
        'rule_code',
        'event_id',
        'from_date',
        'sender_entity_id',
        'receiver_entity_id',
        'to_date',
        'deadline_at_from',
        'deadline_at_to',
        'profile_id',
        'disposition',
        'assigned_to_id',
        'is_for_test',
        'state',
        'q',
        'page',
        'per',
        'sort_field',
        'sort_order',
      ]),
      transactionDispositions: getArrayTypePolicy('TransactionDisposition', [
        'order_by_created_at',
        'alert_id',
        'q',
      ]),
      transactionEntities: getArrayTypePolicy('TransactionEntity', [
        'q',
        'source',
      ]),
      transactionInstruments: getArrayTypePolicy('TransactionInstrument', [
        'q',
        'source',
        'entity_id',
      ]),
      transactionAlert: getSingleTypePolicy(
        'TransactionAlert',
        'ResponseTransactionAlert'
      ),
      transactionAccount: getSingleTypePolicy(
        'TransactionAccount',
        'ResponseTransactionAccount'
      ),
      transactionTags: getArrayTypePolicy('TransactionTag', ['q']),
      transactionTag: getSingleTypePolicy(
        'TransactionTag',
        'ResponseTransactionTag'
      ),
      transactionEntity: getSingleTypePolicy(
        'TransactionEntity',
        'ResponseTransactionEntity'
      ),
      transactionInstrument: getSingleTypePolicy(
        'TransactionInstrument',
        'ResponseTransactionInstrument'
      ),
      transactionRuleSet: getSingleTypePolicy(
        'RuleSet',
        'ResponseTransactionRuleSet'
      ),
      formInvitations: getArrayTypePolicy('FormInvitation', [
        'base_form_submission_id',
      ]),
      audits: getArrayTypePolicy('Audit', [
        'from_date',
        'to_date',
        'submission_id',
      ]),
      prohibitedCountries: getArrayTypePolicy('ProhibitedCountry', [
        'q',
        'sort_field',
        'sort_order',
        'with_deleted',
      ]),
      dispositions: getArrayTypePolicy('Dictionary', ['q', 'alert_id']),
      auditRecords: getArrayTypePolicy('AuditRecord', [
        'obj_id',
        'record_type',
        'obj_type',
        'created_before',
        'created_after',
        'subject_type',
        'subject_id',
        'order',
      ]),
      auditProjectionRecords: getArrayTypePolicy('AuditRecord', [
        'data_sharing_id',
        'case_id',
        'obj_id',
        'record_type',
        'obj_type',
        'created_before',
        'created_after',
        'subject_type',
        'subject_id',
        'order',
      ]),
      audit: getSingleTypePolicy('Audit', 'ResponseAudit'),
      caseRejectionReasons: getArrayTypePolicy('RejectionReason', ['q']),
      deviceInfos: getArrayTypePolicy('DeviceInfo', ['profile_id']),
      approvalGeneral: {
        keyArgs: ['date_from', 'date_to', 'form_configuration_ids'],
      },
      rfiGeneral: {
        keyArgs: ['date_from', 'date_to', 'form_configuration_ids'],
      },
      notifications: getArrayTypePolicy('Notification', [
        'is_readed',
        'is_readed_modal',
        'q',
        'created_before',
        'created_after',
        'sort_field',
        'sort_order',
        'obj_type',
        'notification_type',
        'client_id',
        'case_states',
      ]),
      fincenList: getArrayTypePolicy('FincenList', [
        'kind',
        'with_checkups_counters',
        'with_statuses',
      ]),
      fincenPersonalRecords: getArrayTypePolicy('FincenPersonalRecord', [
        'fincen_list_id',
        'q',
      ]),
      fincenBusinessRecords: getArrayTypePolicy('FincenBusinessRecord', [
        'fincen_list_id',
        'q',
      ]),
      caseRejectReasons: getArrayTypePolicy('CaseRejectReason', ['q']),
      caseRejectReason: getSingleTypePolicy(
        'CaseRejectReason',
        'ResponseCaseRejectReason'
      ),
      formTemplates: getArrayTypePolicy('FormTemplate', [
        'enabled',
        'client_id',
        'q',
      ]),
      exportRequests: getArrayTypePolicy('ExportRequest', [
        'download_at_exist',
        'execution_status',
        'q',
      ]),
      identityVerifications: getArrayTypePolicy('IdentityVerificationItem', [
        'name',
        'surname',
        'date_of_birth_at',
        'country',
        'status',
        'sort_field',
        'sort_order',
        'q',
      ]),
      submissionsStatsPresets: getArrayTypePolicy('SubmissionsStatsPreset', [
        'reference_key',
        'per',
        'score_from',
        'score_to',
        'bank_tariff_name',
        'sort_field',
        'sort_order',
        'is_template',
        'q',
      ]),
      identityVerification: getSingleTypePolicy(
        'IdentityVerificationItem',
        'ResponseIdentityVerification'
      ),
      pPFincenList: getArrayTypePolicy('PPFincenList', [
        'kind',
        'with_checkups_counters',
        'with_statuses',
      ]),
      pPFincenBusinessRecords: getArrayTypePolicy('PPFincenBusinessRecord', [
        'q',
        'fincen_list_id',
      ]),
      pPFincenPersonalRecords: getArrayTypePolicy('PPFincenPersonalRecord', [
        'q',
        'fincen_list_id',
      ]),
      pPScreening: getArrayTypePolicy('PPScreeningRecord', [
        'q',
        'mass_screening_id',
        'checkup_provider_statuses',
        'origin_checkup_provider_statuses',
      ]),
      reminderHistory: getArrayTypePolicy('ReminderHistoryItem', [
        'case_id',
        'q',
      ]),
      customerDocuments: getArrayTypePolicy('CustomerDocument', [
        'q',
        'expiry_date_before',
        'expiry_date_after',
        'without_case_section_id',
        'document_type',
        'blacklisted',
        'sort_field',
        'sort_order',
        'retention_plan_id',
      ]),
      cdsCableAddresses: getArrayTypePolicy('CdsCableAddress', [
        'user_id',
        'company_id',
      ]),
      cdsCableAccountStatuses: getArrayTypePolicy('CdsCableAccountStatus', [
        'user_id',
        'company_id',
      ]),
      cdsCableCompanies: getArrayTypePolicy('CdsCableCompany', [
        'submission_id',
      ]),
      cdsCableIdvResults: getArrayTypePolicy('CdsCableIdvResult', [
        'user_id',
        'company_id',
      ]),
      cdsCableOnboardingFlows: getArrayTypePolicy('CdsCableOnboardingFlow', [
        'user_id',
        'company_id',
      ]),
      cdsCablePeoples: getArrayTypePolicy('CdsCablePeople', [
        'company_id',
        'submission_id',
      ]),
      cdsCableRiskAssessments: getArrayTypePolicy('CdsCableRiskAssessment', [
        'user_id',
        'company_id',
      ]),
      cdsCablePeriodicReviews: getArrayTypePolicy('CdsCablePeriodicReview', [
        'company_id',
      ]),
      cdsCableScreeningChecks: getArrayTypePolicy('CdsCableScreeningCheck', [
        'user_id',
        'company_id',
      ]),
      emailTemplates: getArrayTypePolicy('EmailTemplate', [
        'q',
        'sort_field',
        'sort_order',
        'created_at_to',
        'created_at_from',
        'account_ids',
      ]),
      invites: getArrayTypePolicy('Invite', ['status', 'q']),
      invite: getSingleTypePolicy('Invite', 'ResponseInvite'),
      customerMessages: getArrayTypePolicy('CustomerMessage', [
        'q',
        'sort_field',
        'sort_order',
        'created_at_to',
        'sent_at_to',
        'sent_at_from',
        'account_ids',
        'state',
      ]),
      fieldsPresets: getArrayTypePolicy('FieldsPreset', []),
      globalSearchEntrie: getSingleTypePolicy(
        'GlobalSearchEntrie',
        'ResponseGlobalSearchEntrie'
      ),
      globalSearchEntries: getArrayTypePolicy('GlobalSearchEntrie', [
        'sort_field',
        'sort_order',
        'q',
      ]),
      transactionScoringMatrices: getArrayTypePolicy(
        'TransactionScoringMatrix',
        ['is_test', 'enabled']
      ),
      transactionScoringMatrix: getSingleTypePolicy(
        'TransactionScoringMatrix',
        'ResponseTransactionScoringMatrix'
      ),
      transactionScoringRules: getArrayTypePolicy('TransactionScoringRule', [
        'scoring_matrix_id',
      ]),
      transactionScoringManualRules: getArrayTypePolicy(
        'TransactionScoringManualRule',
        ['scoring_matrix_id']
      ),
      transactionScoringDependentRules: getArrayTypePolicy(
        'TransactionScoringDependentRule',
        ['scoring_matrix_id']
      ),
      transactionScoringScoreRules: getArrayTypePolicy(
        'TransactionScoringScoreRule',
        ['scoring_matrix_id']
      ),
      pricingPlans: getArrayTypePolicy('PricingPlan', [
        'kind',
        'onboarding_enabled',
        'billing_period',
        'q',
        'status',
        'price_more_then',
        'price_less_then',
        'sort_field',
        'sort_order',
      ]),
      pricingPlanClients: getArrayTypePolicy('PricingPlanClient', [
        'sort_field',
        'sort_order',
        'q',
        'subscription_status',
        'plan_id',
      ]),

      pricingFeatureTemplates: getArrayTypePolicy('FeatureTemplate', [
        'is_system',
        'sort_field',
        'sort_order',
      ]),
      pricingClientTransacrions: getArrayTypePolicy('ClientTransaction', []),
      aiSummaryConfigs: getArrayTypePolicy('AISummaryConfig', []),
      aiSummaries: getArrayTypePolicy('AISummary', []),
      aiSummaryRevisions: getArrayTypePolicy('AISummaryRevision', []),
      transactionEventRfiItems: getArrayTypePolicy('EventRfiItem', [
        'event_id',
      ]),
      eddReports: getArrayTypePolicy('EDDReport', [
        'case_ids',
        'assigned_to_id',
        'form_configuration_id',
        'status',
        'main_profile_id',
        'form_submission_id',
        'from_created_at',
        'to_created_at',
        'completed_at_from',
        'to_completed_at',
        'profile_kinds',
        'sort_field',
        'sort_order',
        'q',
      ]),
      eddReportDocuments: getArrayTypePolicy('EDDReportDocument', [
        'edd_report_id',
      ]),
      dataRetentionConfigs: getArrayTypePolicy('DataRetentionConfig', [
        'sort_field',
        'sort_order',
      ]),
      dataRetentionPlans: getArrayTypePolicy('DataRetentionPlan', [
        'sort_field',
        'sort_order',
        'object_type',
        'object_id',
        'state',
        'is_manual',
        'created_by_id',
        'q',
      ]),
      aiTaskConfigs: getArrayTypePolicy('AITaskConfig', [
        'configurable_entity_id',
        'configurable_entity_type',
      ]),
      aiTasks: getArrayTypePolicy('AITask', [
        'ai_task_config_id',
        'processable_entity_type',
        'processable_entity_id',
        'status',
        'kinds',
      ]),
      aiTaskResults: getArrayTypePolicy('AITaskResult', ['ai_task_id']),
      massScreening: getArrayTypePolicy('ResponseMassScreening', [
        'fincen_list_id',
        'per',
        'status',
        'q',
      ]),
      sarReports: getArrayTypePolicy('SarReport', [
        'q',
        'created_by_id',
        'assigned_at_id',
        'created_at_from',
        'created_at_to',
        'updated_at_from',
        'updated_at_to',
        'status',
        'case_id',
        'alert_id',
        'filing_type',
        'sort_field',
        'sort_order',
      ]),
      dataSharings: getArrayTypePolicy('DataSharing', [
        'q',
        'as_sender',
        'as_receiver',
        'states',
        'created_at_from',
        'created_at_to',
        'expired_at_from',
        'expired_at_to',
        'accepted_at_from',
        'accepted_at_to',
        'sender_account_id',
        'receiver_account_id',
        'sort_field',
        'sort_order',
        'receiver_email_is_null',
      ]),
      caseProjections: getArrayTypePolicy('CaseProjection', [
        'data_sharing_id',
        'reminder_interval_days',
        'ids',
        'from_date',
        'to_date',
        'from_submission_date',
        'to_submission_date',
        'from_approved_date',
        'to_approved_date',
        'state',
        'form_configuration_reference_keys',
        'required_action_source_ids',
        'profile_id',
        'assigned_to_id',
        'search_id',
        'auto_audit_state',
        'search_by_field',
        'case_id',
        'form_submission_id',
        'tab_registry_id',
        'score_more_then',
        'score_less_then',
        'is_shared',
        'sort_field',
        'sort_order',
        'entity_type',
        'q',
        'profile_kinds',
        'open_required_actions_exist',
        'bank_tariff_name',
        'status_onboarding_fee',
        'retention_plan_id',
      ]),
      transactionProjectionEvents: getArrayTypePolicy(
        'TransactionProjectionEvent',
        [
          'data_sharing_id',
          'profile_id',
          'sort_field',
          'sort_order',
          'case_id',
          'checkup_result_state',
          'entity_id',
          'instrument_id',
          'from_date',
          'to_date',
          'amount_to',
          'amount_from',
          'status',
          'q',
          'rejection_reason_name',
          'event_ids',
          'bank_client_id',
        ]
      ),
      dataSharingAiTasks: getArrayTypePolicy('SharingAITask', [
        'case_projection_id',
        'ai_task_config_id',
        'processable_entity_type',
        'processable_entity_id',
        'status',
        'kinds',
      ]),
    },
  },
};
