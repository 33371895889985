import { gql } from '@apollo/client';
import { IDENFY_RESULT_FRAGMENT } from '../../fragment/pdf/case/identityVerifications/IdenfyResult';
import { VERIFF_RESULT_FRAGMENT } from '../../fragment/pdf/case/identityVerifications/VeriffResult';
import { CUSTOMER_FRAGMENT } from '../../fragment/cm';
import { PROFILE_FRAGMENT } from '../../fragment/profiles/profiles';
import { ATTACHMENT_FRAGMENT } from '../../fragment/attachment';
import { FORM_CONFIGURATION_FRAGMENT } from '../../fragment/submission/FormConfiguration';
import { FORM_AUDIT_FRAGMENT } from '../../fragment/submission/FormAudit';
import { FORM_FRAGMENT, FORM_SCREEN_FRAGMENT } from '../../fragment/form';
import { CASE_CONFIG_FRAGMENT } from '../../fragment/caseConfig/CaseConfig';
import { TRANSACTION_EVENT_FRAGMENT } from '../../fragment/tm';

export const CaseProjections = gql`
  query CaseProjections(
    $data_sharing_id: String!
    $reminder_interval_days: Int
    $ids: [String!]
    $from_date: String
    $to_date: String
    $from_submission_date: String
    $to_submission_date: String
    $from_approved_date: String
    $to_approved_date: String
    $state: [String!]
    $auto_audit_state: [String!]
    $score_more_then: String
    $score_less_then: String
    $form_submission_id: String
    $search_id: String
    $search_by_field: String
    $tab_registry_id: String
    $case_sharings: Boolean
    $is_shared: String
    $remind_finish: Boolean
    $assigned_to_id: String
    $profile_id: String
    $sort_field: String
    $sort_order: String
    $form_configuration_reference_keys: [String!]
    $required_action_source_ids: [String!]
    $entity_type: String
    $profile_kinds: String
    $open_required_actions_exist: Boolean
    $bank_tariff_name: String
    $status_onboarding_fee: String
    $retention_plan_id: String
    $page: Int
    $per: Int
    $q: String
  ) {
    caseProjections(
      data_sharing_id: $data_sharing_id
      reminder_interval_days: $reminder_interval_days
      ids: $ids
      from_date: $from_date
      to_date: $to_date
      from_submission_date: $from_submission_date
      to_submission_date: $to_submission_date
      from_approved_date: $from_approved_date
      to_approved_date: $to_approved_date
      search_id: $search_id
      state: $state
      profile_id: $profile_id
      assigned_to_id: $assigned_to_id
      auto_audit_state: $auto_audit_state
      score_more_then: $score_more_then
      score_less_then: $score_less_then
      case_sharings: $case_sharings
      tab_registry_id: $tab_registry_id
      form_submission_id: $form_submission_id
      is_shared: $is_shared
      remind_finish: $remind_finish
      sort_field: $sort_field
      sort_order: $sort_order
      search_by_field: $search_by_field
      form_configuration_reference_keys: $form_configuration_reference_keys
      required_action_source_ids: $required_action_source_ids
      entity_type: $entity_type
      profile_kinds: $profile_kinds
      open_required_actions_exist: $open_required_actions_exist
      bank_tariff_name: $bank_tariff_name
      status_onboarding_fee: $status_onboarding_fee
      retention_plan_id: $retention_plan_id
      page: $page
      per: $per
      q: $q
    )
      @rest(
        type: "ResponseCaseProjections"
        path: "/core/client/sharing/case_projections?{args}"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "Profile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
            is_my
          }
        }
        sharing_entities
        allowed_actions
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CaseProjection = gql`
  ${IDENFY_RESULT_FRAGMENT}
  ${VERIFF_RESULT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  query CaseProjection($id: String!) {
    caseProjection(id: $id)
      @rest(
        type: "ResponseCaseProjection"
        path: "/core/client/sharing/case_projections/{args.id}"
      ) {
      data @type(name: "CaseProjection") {
        id
        case_id
        data_sharing_id
        projection @type(name: "ProjectionCase") {
          sharing_entities
          allowed_actions
          form_submission_ids
          edd_reports @type(name: "EDDReport") {
            assigned_to_id
            case_ids
            client_id
            completed_at
            created_at
            description
            form_configuration_id
            form_submission_id
            id
            is_my
            main_profile_id
            main_profile @type(name: "Profile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            profile_kind
            status
            updated_at
          }
          form_submissions @type(name: "ProjectionCaseSubmission") {
            id
            form_configuration_id
          }
          case_sections @type(name: "ProjectionCaseSection") {
            failed_questionnaire_items @type(name: "FailedQuestionnaireItem") {
              id
              field_submission_id
            }
            case_section_tabs @type(name: "ProjectionCaseSectionTab") {
              failed_questionnaire_items
                @type(name: "FailedQuestionnaireItem") {
                id
                field_submission_id
              }
              name
              case_section_items @type(name: "ProjectionCaseSectionItem") {
                ai_task
                value
                naics_name
                is_my
                audit_id
                case_section_tab_id
                checkup_id
                checkup_result
                created_at
                description
                device_info
                form_audit
                form_field
                form_group
                form_screen
                created_at
                file_name
                file_size
                form_submission_id
                id
                updated_at
                uploaded_document_id
                kind
                customer_document_id
                case_question @type(name: "CaseQuestion") {
                  id
                  answer_body
                  author @type(name: "Account") {
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    id
                    phone
                  }
                  case_id
                  case_question_config_id
                  comment
                  created_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  question_body
                  status
                  updated_at
                }
                case_summary @type(name: "AISummary") {
                  id
                  body
                  case_id
                  case_summary_config_id
                  client_id
                  created_at
                  current_revision @type(name: "AISummaryRevision") {
                    id
                    body
                    case_summary_id
                    client_id
                    comment
                    created_at
                    edited_at
                    is_marked_as_actual
                    is_marked_as_irrelevant
                    request_prompt
                    status
                    updated_at
                    edited_by @type(name: "Account") {
                      id
                      avatar_id
                      email
                      file_name
                      file_size
                      full_name
                      phone
                    }
                  }
                  revisions_count
                  updated_at
                  used_sources
                }
                customer_document @type(name: "CustomerDocument") {
                  id
                  client_id
                  veriff_result_id
                  idenfy_result_id
                  created_at
                  updated_at
                  date_of_birth
                  date_of_issue
                  expiry_date
                  first_name
                  last_name
                  gender
                  document_number
                  document_type
                  blacklisted
                  country
                  nationality
                  attachments @type(name: "Attachment") {
                    ...AttachmentFragment
                  }
                  profiles @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  customers @type(name: "Customer") {
                    avatar_id
                    company_name
                    created_at
                    customer_verification_full_name
                    email
                    first_name
                    full_name
                    id
                    last_name
                    links
                    phone
                    profile @type(name: "Profile") {
                      avatar_id
                      created_at
                      display_name
                      email
                      id
                      is_whitelisted
                      kind
                      phone
                      updated_at
                    }
                    region_code
                    updated_at
                  }
                }
                customer_message @type(name: "CustomerMessage") {
                  id
                  body
                  client_id
                  created_at
                  name
                  state
                  subject
                  updated_at
                  email
                  account @type(name: "Account") {
                    id
                    full_name
                  }
                  header
                  sent_at
                }
                idenfy_result @type(name: "IdenfyResult") {
                  ...IdenfyResultFragment
                }
                veriff_result @type(name: "VeriffResult") {
                  ...VeriffResultFragment
                }
                stripe_checkout @type(name: "StripeCheckout") {
                  amount_discount
                  amount_total
                  currency
                  id
                  session_id
                  status
                  verification_step_passing_id
                }
                case_custom_step_evaluation
                  @type(name: "CaseCustomStepEvaluation") {
                  id
                  custom_step_action_evaluations
                    @type(name: "CustomStepActionEvaluation") {
                    id
                    value
                    uploaded_document_id
                    file_name
                    file_size
                    custom_step_action @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                  custom_step @type(name: "CustomStep") {
                    id
                    name
                    custom_actions @type(name: "CustomStepAction") {
                      id
                      kind
                      mandatory
                      name
                      repeatable
                    }
                  }
                }
                case_section_tab_id
                description
              }
              case_section_items_count
              created_at
              id
              section_info {
                is_always_exists
                is_section_approvable
                is_section_approved_by_default
                is_tabs_enabled
                kind
              }
              is_tabs_enabled
              kind
              state
              updated_at
            }
            case_section_items @type(name: "ProjectionCaseSectionItem") {
              ai_task
              value
              naics_name
              is_my
              audit_id
              case_section_tab_id
              checkup_id
              checkup_result
              created_at
              description
              device_info
              form_audit
              form_field
              form_group
              form_screen
              created_at
              file_name
              file_size
              form_submission_id
              id
              updated_at
              uploaded_document_id
              kind
              customer_document_id
              case_question @type(name: "CaseQuestion") {
                id
                answer_body
                author @type(name: "Account") {
                  avatar_id
                  email
                  file_name
                  file_size
                  full_name
                  id
                  phone
                }
                case_id
                case_question_config_id
                comment
                created_at
                is_marked_as_actual
                is_marked_as_irrelevant
                question_body
                status
                updated_at
              }
              case_summary @type(name: "AISummary") {
                id
                body
                case_id
                case_summary_config_id
                client_id
                created_at
                current_revision @type(name: "AISummaryRevision") {
                  id
                  body
                  case_summary_id
                  client_id
                  comment
                  created_at
                  edited_at
                  is_marked_as_actual
                  is_marked_as_irrelevant
                  request_prompt
                  status
                  updated_at
                  edited_by @type(name: "Account") {
                    id
                    avatar_id
                    email
                    file_name
                    file_size
                    full_name
                    phone
                  }
                }
                revisions_count
                updated_at
                used_sources
              }
              customer_document @type(name: "CustomerDocument") {
                id
                client_id
                veriff_result_id
                idenfy_result_id
                created_at
                updated_at
                date_of_birth
                date_of_issue
                expiry_date
                first_name
                last_name
                gender
                document_number
                document_type
                blacklisted
                country
                nationality
                attachments @type(name: "Attachment") {
                  ...AttachmentFragment
                }
                profiles @type(name: "Profile") {
                  avatar_id
                  created_at
                  display_name
                  email
                  id
                  is_whitelisted
                  kind
                  phone
                  updated_at
                }
                customers @type(name: "Customer") {
                  avatar_id
                  company_name
                  created_at
                  customer_verification_full_name
                  email
                  first_name
                  full_name
                  id
                  last_name
                  links
                  phone
                  profile @type(name: "Profile") {
                    avatar_id
                    created_at
                    display_name
                    email
                    id
                    is_whitelisted
                    kind
                    phone
                    updated_at
                  }
                  region_code
                  updated_at
                }
              }
              customer_message @type(name: "CustomerMessage") {
                id
                body
                client_id
                created_at
                name
                state
                subject
                updated_at
                email
                account @type(name: "Account") {
                  id
                  full_name
                }
                header
                sent_at
              }
              idenfy_result @type(name: "IdenfyResult") {
                ...IdenfyResultFragment
              }
              veriff_result @type(name: "VeriffResult") {
                ...VeriffResultFragment
              }
              stripe_checkout @type(name: "StripeCheckout") {
                amount_discount
                amount_total
                currency
                id
                session_id
                status
                verification_step_passing_id
              }
              case_custom_step_evaluation
                @type(name: "CaseCustomStepEvaluation") {
                id
                custom_step_action_evaluations
                  @type(name: "CustomStepActionEvaluation") {
                  id
                  value
                  uploaded_document_id
                  file_name
                  file_size
                  custom_step_action @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
                custom_step @type(name: "CustomStep") {
                  id
                  name
                  custom_actions @type(name: "CustomStepAction") {
                    id
                    kind
                    mandatory
                    name
                    repeatable
                  }
                }
              }
              case_section_tab_id
              description
            }
            case_section_items_count
            created_at
            id
            section_info {
              is_always_exists
              is_section_approvable
              is_section_approved_by_default
              is_tabs_enabled
              kind
            }
            is_tabs_enabled
            kind
            state
            updated_at
          }
          approved_at
          audit_repeat_interval
          auto_audit_state
          case_sections_count
          case_sharings_count
          created_at
          entity_type
          external_id
          last_submission_completed_at
          id
          is_ongoing
          is_whitelisted
          profile_display_name
          purpose
          score
          scoring_conflicts_count
          customer @type(name: "ProjectionCustomer") {
            avatar_id
            company_name
            created_at
            customer_verification_full_name
            email
            first_name
            full_name
            id
            last_name
            links
            phone
            profile @type(name: "ProjectionProfile") {
              avatar_id
              created_at
              display_name
              email
              id
              is_whitelisted
              kind
              phone
              updated_at
            }
            region_code
            updated_at
          }
          state
          updated_at
          open_required_action_count
          bank_tariff_name
          status_onboarding_fee
          total_onboarding_fee
          enabled_features_codes
          profiles @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
            is_my
          }
        }
        sharing_entities
        allowed_actions
      }
      success
    }
  }
`;

export const SubmissionProjection = gql`
  ${FORM_CONFIGURATION_FRAGMENT}
  ${FORM_AUDIT_FRAGMENT}
  query SubmissionProjection(
    $id: String!
    $data_sharing_id: String!
    $case_projection_id: String!
  ) {
    submissionProjection(
      id: $id
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "ResponseSubmissionProjection"
        path: "/core/client/sharing/submissions/{args.id}?data_sharing_id={args.data_sharing_id}&case_projection_id={args.case_projection_id}"
      ) {
      data @type(name: "ProjectionSubmission") {
        audit_status
        created_at
        customer @type(name: "Customer") {
          avatar_id
          company_name
          created_at
          customer_verification_full_name
          email
          first_name
          full_name
          id
          last_name
          links
          phone
          profile @type(name: "ProjectionProfile") {
            avatar_id
            created_at
            display_name
            email
            id
            is_whitelisted
            kind
            phone
            updated_at
          }
          region_code
          updated_at
        }
        form_audit @type(name: "FormAudit") {
          ...FormAuditFragment
        }
        form_configuration @type(name: "FormConfiguration") {
          ...FormConfigurationFragment
        }
        data_retention @type(name: "DataRetentionInfo") {
          full_delete_date
          period_start_date
          retention_plan_id
          retention_state
          soft_delete_date
        }
        profiles @type(name: "ProjectionProfile") {
          avatar_id
          created_at
          display_name
          email
          id
          is_whitelisted
          kind
          phone
          updated_at
          is_my
        }
        display_name
        form_invitation_id
        id
        is_actual_revision
        profile_display_name
        reference_key
        revision_number
        status
        ubt_submission_ref
        updated_at
        is_my
        field_submissions @type(name: "FieldSubmission") {
          business_passport_id
          created_at
          file_name
          file_size
          field_update_claims_count
          form_field @type(name: "FormField") {
            created_at
            description
            field_template @type(name: "FormFieldTemplate") {
              code
              common
              created_at
              defaults
              field_select_values
              id
              kind
              name
              options
              updated_at
            }
            form_group @type(name: "FormGroup") {
              created_at
              form_screen_id
              id
              name
              options
              position
              repeatable
              tags
              updated_at
            }
            field_template_id
            id
            name
            options
            position
            protected_tags
            reference_key
            tags
            updated_at
          }
          form_group_index
          form_group_subindex
          form_invitation_id
          id
          personal_passport_id
          updated_at
          uploaded_document_id
          value
        }
      }
      success
    }
  }
`;

export const FormProjection = gql`
  ${FORM_FRAGMENT}
  ${FORM_SCREEN_FRAGMENT}
  ${CASE_CONFIG_FRAGMENT}
  query FormProjection($id: String!, $case_projection_id: String!) {
    formProjection(id: $id, case_projection_id: $case_projection_id)
      @rest(
        type: "ResponseForm"
        path: "/core/client/sharing/form_configurations/{args.id}?case_projection_id={args.case_projection_id}"
      ) {
      data @type(name: "Form") {
        ...FormFragment
        form_screens @type(name: "FormScreen") {
          ...FormScreenFragment
        }
        case_config @type(name: "CaseConfig") {
          ...CaseConfigFragment
        }
      }
      success
    }
  }
`;

export const DocumentProjection = gql`
  query DocumentProjection(
    $id: String!
    $clientId: String!
    $path: String!
    $data_sharing_id: String!
    $case_id: String!
  ) {
    documentProjection(
      id: $id
      clientId: $clientId
      path: $path
      data_sharing_id: $data_sharing_id
      case_id: $case_id
    )
      @rest(
        type: "ResponseDocument"
        path: "/documents/sharing/{args.path}/{args.id}?client_id={args.clientId}&data_sharing_id={args.data_sharing_id}&case_id={args.case_id}"
      ) {
      data @type(name: "Document") {
        file_size
        file_path
        file_name
        safe_download_url
        av_status
        id
      }
      errors
      success
    }
  }
`;

export const FieldUpdatesHistoryProjection = gql`
  query FieldUpdatesHistoryProjection(
    $form_submission_id: String!
    $field_submission_id: String!
    $data_sharing_id: String!
    $case_projection_id: String!
  ) {
    fieldUpdatesHistoryProjection(
      form_submission_id: $form_submission_id
      field_submission_id: $field_submission_id
      data_sharing_id: $data_sharing_id
      case_projection_id: $case_projection_id
    )
      @rest(
        type: "ResponseFieldUpdatesHistory"
        path: "/core/client/sharing/submissions/field_update_claims?data_sharing_id={args.data_sharing_id}&case_projection_id={args.case_projection_id}&form_submission_id={args.form_submission_id}&field_submission_id={args.field_submission_id}"
      ) {
      data @type(name: "FieldUpdateItem") {
        id
        account_display_name
        account_id
        comment
        created_at
        field_submission_id
        new_uploaded_document_id
        old_uploaded_document_id
        new_value
        old_value
        status
        value_type
        field_submission @type(name: "UpdateFieldSubmission") {
          id
          submission_id
          field_update_claims_count
          form_field_id
          form_group_index
          form_group_subindex
          value
          uploaded_document_id
        }
      }
      errors
      success
    }
  }
`;

export const CheckupProjections = gql`
  query CheckupProjections(
    $page: Int
    $q: String
    $checkup_kind_code: String
    $checkup_kind_codes: String
    $fincen_list_ids: String
    $ids: String
    $resolutions: String
    $execution_statuses: String
    $audit_statuses: String
    $provider_statuses: String
    $external_ref_entity_types: String
    $external_ref_entity_id: String
    $created_at_from: String
    $created_at_to: String
    $last_screened_at_from: String
    $last_screened_at_to: String
    $assigned_to_id: String
    $sort_field: String
    $sort_order: String
    $case_status: String
    $cryptocurrency_symbol: String
    $blockchain: String
    $retention_plan_id: String
    $data_sharing_id: String
    $case_id: String
    $per: Int = 20
  ) {
    checkupProjections(
      page: $page
      per: $per
      q: $q
      checkup_kind_code: $checkup_kind_code
      checkup_kind_codes: $checkup_kind_codes
      fincen_list_ids: $fincen_list_ids
      ids: $ids
      resolutions: $resolutions
      execution_statuses: $execution_statuses
      audit_statuses: $audit_statuses
      provider_statuses: $provider_statuses
      external_ref_entity_types: $external_ref_entity_types
      external_ref_entity_id: $external_ref_entity_id
      created_at_from: $created_at_from
      created_at_to: $created_at_to
      last_screened_at_from: $last_screened_at_from
      last_screened_at_to: $last_screened_at_to
      sort_field: $sort_field
      sort_order: $sort_order
      assigned_to_id: $assigned_to_id
      case_status: $case_status
      cryptocurrency_symbol: $cryptocurrency_symbol
      retention_plan_id: $retention_plan_id
      blockchain: $blockchain
      data_sharing_id: $data_sharing_id
      case_id: $case_id
    )
      @rest(
        type: "ResponseServiceCheckups"
        path: "/checkups/client/sharing/checkup_projections?{args}"
      ) {
      data @type(name: "CheckupProjection") {
        checkup_id
        data_sharing_id
        id
        projection @type(name: "ProjectionCheckup") {
          audit_status
          assigned_to @type(name: "Account") {
            id
            email
            first_name
            last_name
            full_name
          }
          ca_search_id
          checkup_attachments @type(name: "Attachment") {
            id
          }
          checkup_kind @type(name: "CheckupKind") {
            code
            description
            id
            name
            provider_id
          }
          checkup_kind_code
          checkup_profiles @type(name: "CheckupProfile") {
            checkup_id
            id
            profile_display_name
            profile_id
          }
          created_at
          display_name
          execution_status
          execution_time
          external_refs @type(name: "ExternalRef") {
            id
            external_entity_id
            external_entity_type
            external_system
          }
          id
          is_cached
          is_migrated
          last_screened_at
          meta
          migrated_data @type(name: "MigratedData") {
            instant_checkup_field_submissions
              @type(name: "InstantCheckupFieldSubmission") {
              id
              name
              value
            }
            raw_result
          }
          provider_status
          resolution
          veriff_result_id
          is_my
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const CheckupProjection = gql`
  query CheckupProjection(
    $id: String!
    $data_sharing_id: String!
    $case_id: String!
  ) {
    checkupProjection(
      id: $id
      data_sharing_id: $data_sharing_id
      case_id: $case_id
    )
      @rest(
        type: "ResponseServiceCheckup"
        path: "/checkups/client/sharing/checkup_projections/{args.id}?data_sharing_id={args.data_sharing_id}&case_id={args.case_id}"
      ) {
      data @type(name: "CheckupProjection") {
        checkup_id
        data_sharing_id
        id
        projection @type(name: "ProjectionCheckup") {
          audit_status
          assigned_to @type(name: "Account") {
            id
            email
            first_name
            last_name
            full_name
          }
          ca_search_id
          checkup_attachments @type(name: "Attachment") {
            id
          }
          checkup_kind @type(name: "CheckupKind") {
            code
            description
            id
            name
            provider_id
          }
          checkup_kind_code
          checkup_profiles @type(name: "CheckupProfile") {
            checkup_id
            id
            profile_display_name
            profile_id
          }
          created_at
          display_name
          execution_status
          execution_time
          external_refs @type(name: "ExternalRef") {
            id
            external_entity_id
            external_entity_type
            external_system
          }
          id
          is_cached
          is_migrated
          last_screened_at
          meta
          migrated_data @type(name: "MigratedData") {
            instant_checkup_field_submissions
              @type(name: "InstantCheckupFieldSubmission") {
              id
              name
              value
            }
            raw_result
          }
          provider_status
          resolution
          veriff_result_id
          is_my
          form
          serialized_result
        }
      }
      success
    }
  }
`;

export const AuditProjectionRecords = gql`
  query AuditProjectionRecords(
    $data_sharing_id: String!
    $case_id: String!
    $obj_id: String
    $record_type: String
    $obj_type: String
    $subject_type: String
    $subject_id: String
    $created_before: String
    $created_after: String
    $order: String
    $page: Int
  ) {
    auditProjectionRecords(
      data_sharing_id: $data_sharing_id
      case_id: $case_id
      obj_id: $obj_id
      record_type: $record_type
      obj_type: $obj_type
      subject_type: $subject_type
      subject_id: $subject_id
      created_before: $created_before
      created_after: $created_after
      order: $order
      page: $page
      per: 20
    )
      @rest(
        type: "ResponseAuditRecords"
        path: "/audit/sharing/records?{args}"
      ) {
      data @type(name: "AuditRecord") {
        display_name
        created_at
        obj_type
        obj_id
        subject_meta @type(name: "AuditRecordMeta") {
          display_name
          email
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;

export const TransactionProjectionEvents = gql`
  ${TRANSACTION_EVENT_FRAGMENT}
  query TransactionProjectionEvents(
    $data_sharing_id: String!
    $profile_id: String
    $sort_field: String
    $sort_order: String
    $case_id: String
    $checkup_result_state: String
    $entity_id: String
    $instrument_id: String
    $from_date: String
    $to_date: String
    $amount_to: String
    $amount_from: String
    $status: String
    $page: Int
    $per: Int
    $q: String
    $rejection_reason_name: String
    $bank_client_id: String
    $event_ids: [String!]
  ) {
    transactionProjectionEvents(
      data_sharing_id: $data_sharing_id
      profile_id: $profile_id
      sort_field: $sort_field
      sort_order: $sort_order
      checkup_result_state: $checkup_result_state
      entity_id: $entity_id
      instrument_id: $instrument_id
      from_date: $from_date
      to_date: $to_date
      amount_to: $amount_to
      amount_from: $amount_from
      status: $status
      page: $page
      per: $per
      case_id: $case_id
      q: $q
      rejection_reason_name: $rejection_reason_name
      bank_client_id: $bank_client_id
      event_ids: $event_ids
    )
      @rest(
        type: "ResponseTransactionEvents"
        path: "/tm/sharing/event_projections?{args}"
      ) {
      data @type(name: "TransactionProjectionEvent") {
        id
        data_sharing_id
        event_id
        projection @type(name: "TransactionEvent") {
          ...TransactionEventFragment
        }
      }
      success
      meta {
        count
        page
        items
        pages
      }
    }
  }
`;
