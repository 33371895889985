import { showToast } from '@aid-module/ui';
import { useChangeStateCustomerDataSharingMutation } from '@aid-package/api';
import { useCallback } from 'react';

export function useClientPickerModel() {
  const [mutation, { loading }] = useChangeStateCustomerDataSharingMutation();

  const change = useCallback(
    async (
      id: string,
      account_id: string,
      state: string,
      client_id?: string
    ) => {
      const { data } = await mutation({
        variables: {
          id,
          body: {
            state,
            receiver_account_id: account_id,
            receiver_client_id: client_id,
          },
        },
      });
      if (data?.changeStateCustomerDataSharing?.success) {
        showToast({
          type: 'success',
          message: 'Changes were successfully saved',
        });
        return true;
      }
      showToast({ type: 'error', message: 'Something went wrong' });
      return;
    },
    [mutation]
  );

  return { change, loading };
}
