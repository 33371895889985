import styled from '@emotion/styled';
import { FlexContainer } from '../../containers';
import { getColor, getSpace } from '../../utils';
import { EmotionContainerViewProps } from './types';

export const Container = styled(FlexContainer)<EmotionContainerViewProps>`
  padding: ${({ padding }) =>
    padding === 'XS'
      ? `${getSpace('2')} ${getSpace('6')}`
      : padding === 'M'
      ? `${getSpace('4')} ${getSpace('6')}`
      : `${getSpace('4')} ${getSpace('8')}`};
  background: ${({ background = 'main-deep-4' }) => getColor(background)};
  border-radius: 4px;

  ${({ border }) => border && `border: 1px solid ${getColor(border)};`}
`;
