import { Navigate, Route, Routes } from 'react-router-dom';
import { OutgoingRegister } from './OutgoingRegister';
import { IncomingRegister } from './IncomingRegister';
import { Description } from './Description';
import { ErrorPages, ProtectedRoute } from '@aid-module/ui';
import { PERMISSIONS } from '@aid-package/context';
import { IncomingDescription } from './IncomingDescription';
import { CaseProjectionDescription } from './CaseProjection';
import { CaseProjectionRegister } from './CaseProjectionRegister';

export function SharingRouter() {
  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedRoute check={PERMISSIONS.MANAGE_DATA_SHARING} />}
      >
        <Route index element={<Navigate to="outgoing" />} />
        <Route path="outgoing">
          <Route index element={<OutgoingRegister />} />
          <Route path=":id" element={<Description />} />
        </Route>
        <Route path="incoming">
          <Route index element={<IncomingRegister />} />
          <Route path=":id" element={<IncomingDescription />} />
        </Route>
        <Route path="case_projections">
          <Route index element={<CaseProjectionRegister />} />
          <Route path=":id" element={<CaseProjectionDescription />} />
        </Route>
        <Route path="*" element={<ErrorPages type="local" code={404} />} />
      </Route>
    </Routes>
  );
}
