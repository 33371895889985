import { SvgContainerViewProps } from '../../../../components/SvgContainer/types';

export type ComponentViewProps = Omit<SvgContainerViewProps, 'viewBox'>;

export enum Icons {
  alert = 'alert',
  calendar = 'calendar',
  cross = 'cross',
  info = 'info',
  spinner = 'spinner',
  triangleWarning = 'triangleWarning',
  arrowRightDouble = 'arrowRightDouble',
  arrowRight = 'arrowRight',
  arrowLeft = 'arrowLeft',
  arrowLeftDouble = 'arrowLeftDouble',
  arrowUp = 'arrowUp',
  arrowDown = 'arrowDown',
  empty = 'empty',
  filter = 'filter',
  filterOutline = 'filterOutline',
  drag = 'drag',
  link = 'link',
  users = 'users',
  client = 'client',
  blacklist = 'blacklist',
  region = 'region',
  fieldTemplate = 'fieldTemplate',
  checkup = 'checkup',
  instantCheckup = 'instantCheckup',
  kycDocVerification = 'kycDocVerification',
  checkmark = 'checkmark',
  purse = 'purse',
  logOut = 'logOut',
  settings = 'settings',
  warning = 'warning',
  zoom = 'zoom',
  webCamera = 'webCamera',
  upload = 'upload',
  trash = 'trash',
  roles = 'roles',
  tags = 'tags',
  question = 'question',
  protected = 'protected',
  public = 'public',
  timer = 'timer',
  email = 'email',
  phone = 'phone',
  id = 'id',
  clipBoard = 'clipBoard',
  infoItem = 'infoItem',
  verifications = 'verifications',
  new = 'new',
  amlForIndividual = 'amlForIndividual',
  amlForBusiness = 'amlForBusiness',
  busRegistryVerification = 'busRegistryVerification',
  elliptic = 'elliptic',
  form = 'form',
  formLink = 'formLink',
  file = 'file',
  translations = 'translations',
  delete = 'delete',
  passportLogo = 'passportLogo',
  plus = 'plus',
  hide = 'hide',
  attach = 'attach',
  dismiss = 'dismiss',
  create = 'create',
  add = 'add',
  trigger = 'trigger',
  monitoring = 'monitoring',
  medicalHistory = 'medicalHistory',
  lock = 'lock',
  unlock = 'unlock',
  pencil = 'pencil',
  download = 'download',
  scoring = 'scoring',
  bell = 'bell',
  notificationBell = 'bell',
  emptyList = 'emptyList',
  rocket = 'rocket',
  criminal = 'criminal',
  aidLogo = 'aidLogo',
  walletRisk = 'walletRisk',
  defaultAvatar = 'defaultAvatar',
  search = 'search',
  user = 'user',
  comments = 'comments',
  dashboard = 'dashboard',
  briefcase = 'briefcase',
  checklist = 'checklist',
  scheme = 'scheme',
  windowGear = 'windowGear',
  transactions = 'transactions',
  windowSearch = 'windowSearch',
  tools = 'tools',
  customisation = 'customisation',
  permissions = 'permissions',
  payment = 'payment',
  windowChart = 'windowChart',
  checkups = 'checkups',
  templates = 'templates',
  starFilled = 'starFilled',
  star = 'star',
  save = 'save',
  notificationOn = 'notificationOn',
  notificationOff = 'notificationOff',
  statistic = 'statistic',
  externalLink = 'externalLink',
  minus = 'minus',
  identityVerification = 'identityVerification',
  filledPinned = 'filledPinned',
  pinned = 'pinned',
  notificationRead = 'notificationRead',
  eye = 'eye',
  submissionStatistic = 'submissionStatistic',
  history = 'history',
  clockCircle = 'clockCircle',
  restore = 'restore',
  columns = 'columns',
  searchTab = 'searchTab',
  switch = 'switch',
  flag = 'flag',
  assign = 'assign',
  research = 'research',
  searcher = 'searcher',
  tabs = 'tabs',
  bellUser = 'bellUser',
  mapping = 'mapping',
  invite = 'invite',
  emailTemplate = 'emailTemplate',
  letter = 'letter',
  undo = 'undo',
  edit = 'edit',
  addUser = 'addUser',
  comment = 'comment',
  eye2 = 'eye2',
  eyeInvisible = 'eyeInvisible',
  disconnect = 'disconnect',
  fileSearch = 'fileSearch',
  unit21 = 'unit21',
  searchDetail = 'searchDetail',
  individual = 'individual',
  business = 'business',
  rules = 'rules',
  magicWand = 'magicWand',
  badSmile = 'badSmile',
  goodSmile = 'goodSmile',
  send = 'send',
  candidate = 'candidate',
  redo = 'redo',
  hosting = 'hosting',
  cloudServer = 'cloudServer',
  options = 'options',
  recognize = 'recognize',
  browser = 'browser',
  key = 'key',
  userSwitch = 'userSwitch',
  export = 'export',
  import = 'import',
  navigation = 'navigation',
}

export interface IconsViewProps
  extends Omit<SvgContainerViewProps, 'viewBox' | 'preserveAspectRatio'> {
  glyph: keyof typeof Icons;
}
