import { AdminRouter } from '@aid-module/admin';
import { AccountRouter } from '@aid-module/account';
import {
  ThemeProvider,
  ConfirmModalProvider,
  IconsCacheContextProvider,
  showToast,
} from '@aid-module/ui';
import {
  ApiClientProvider,
  OnboardingApiClientProvider,
} from '@aid-package/api';
import {
  ClientProvider,
  FeaturesProvider,
  PermissionsProvider,
  RetentionProvider,
} from '@aid-package/context';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  CheckAccounts,
  ExternalLink,
  GlobalError,
  Layout,
  OnBoarding,
  Redirect,
  RedirectFromOldUrls,
} from './components';
import { Container } from './styles';
import { StatsRouter } from '@aid-module/statistic';
import { ProfilesRouter } from '@aid-module/profile';
import { CheckupRouter } from '@aid-module/checkup';
import { VerificationRouter } from '@aid-module/verification';
import { BuilderRouter } from '@aid-module/builder';
import { TransactionRouter } from '@aid-module/transaction';
import { NotificationRouter } from '@aid-module/notification';
import { SearchRouter } from '@aid-module/search';
import { PDFExportContextProvider } from '@aid-package/pdf';
import { PricingAdminRouter, PricingRouter } from '@aid-module/pricing';
import { SharingRouter } from '@aid-module/sharing';

function RedirectToFormService() {
  const location = useLocation();

  window.location.href = `${process.env.REACT_APP_FORM_HOST}${location.pathname}${location.search}`;

  return null;
}

function RedirectToFormServiceReplay() {
  const { ubt_submission_ref, form_ref, form_configuration_id } = useParams();

  window.location.href = `${process.env.REACT_APP_FORM_HOST}/replay/${form_ref}/${ubt_submission_ref}/${form_configuration_id}`;

  return null;
}

function showError(message: string) {
  showToast({ type: 'error', message });
}

export function App() {
  return (
    <Container>
      <IconsCacheContextProvider>
        <ThemeProvider>
          <GlobalError>
            <ConfirmModalProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/external_link" element={<ExternalLink />} />
                  <Route
                    path="/external/form_replay_tracking/:form_ref/:ubt_submission_ref/:form_configuration_id"
                    element={<RedirectToFormServiceReplay />}
                  />
                  <Route
                    path="/form_replay_tracking/:form_ref/:ubt_submission_ref/:form_configuration_id"
                    element={<RedirectToFormServiceReplay />}
                  />
                  <Route
                    path="/form/verification_flows/:flow_id"
                    element={<RedirectToFormService />}
                  />
                  <Route
                    path="/form/verification_flows/:flow_id/:lang"
                    element={<RedirectToFormService />}
                  />
                  <Route
                    path="/external/forms/:formId"
                    element={<RedirectToFormService />}
                  />
                  <Route
                    path="/external/form_invitation/:formId"
                    element={<RedirectToFormService />}
                  />
                  <Route
                    path="/forms/:formId"
                    element={<RedirectToFormService />}
                  />
                  <Route
                    path="/upload/:id"
                    element={<RedirectToFormService />}
                  />
                  <Route
                    path="/upload/:id/:lang"
                    element={<RedirectToFormService />}
                  />
                  <Route
                    path="/onboarding"
                    element={
                      <OnboardingApiClientProvider>
                        <ClientProvider>
                          <OnBoarding />
                        </ClientProvider>
                      </OnboardingApiClientProvider>
                    }
                  />
                  <Route
                    path="/:client_id/*"
                    element={
                      <ApiClientProvider showError={showError}>
                        <ClientProvider>
                          <FeaturesProvider>
                            <CheckAccounts>
                              <PermissionsProvider>
                                <RetentionProvider>
                                  <PDFExportContextProvider>
                                    <Layout>
                                      <Routes>
                                        <Route
                                          path="/admin/*"
                                          element={
                                            <Routes>
                                              <Route
                                                path="/*"
                                                element={<AdminRouter />}
                                              />
                                              <Route
                                                path="/pricing/*"
                                                element={<PricingAdminRouter />}
                                              />
                                            </Routes>
                                          }
                                        />
                                        <Route
                                          path="/pricing/*"
                                          element={<PricingRouter />}
                                        />
                                        <Route
                                          path="/account/*"
                                          element={<AccountRouter />}
                                        />
                                        <Route
                                          path="/statistic/*"
                                          element={<StatsRouter />}
                                        />
                                        <Route
                                          path="/profile/*"
                                          element={<ProfilesRouter />}
                                        />
                                        <Route
                                          path="/checkup/*"
                                          element={<CheckupRouter />}
                                        />
                                        <Route
                                          path="/verification/*"
                                          element={<VerificationRouter />}
                                        />
                                        <Route
                                          path="/builder/*"
                                          element={<BuilderRouter />}
                                        />
                                        <Route
                                          path="/transaction/*"
                                          element={<TransactionRouter />}
                                        />
                                        <Route
                                          path="/notification/*"
                                          element={<NotificationRouter />}
                                        />
                                        <Route
                                          path="/search/*"
                                          element={<SearchRouter />}
                                        />
                                        <Route
                                          path="/sharing/*"
                                          element={<SharingRouter />}
                                        />
                                        <Route
                                          path="*"
                                          element={<RedirectFromOldUrls />}
                                        />
                                      </Routes>
                                    </Layout>
                                  </PDFExportContextProvider>
                                </RetentionProvider>
                              </PermissionsProvider>
                            </CheckAccounts>
                          </FeaturesProvider>
                        </ClientProvider>
                      </ApiClientProvider>
                    }
                  />
                  <Route path="/" element={<Redirect />} />
                </Routes>
              </BrowserRouter>
            </ConfirmModalProvider>
          </GlobalError>
        </ThemeProvider>
      </IconsCacheContextProvider>
    </Container>
  );
}
