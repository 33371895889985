import { DefaultModal } from '@aid-module/ui';
import { useInviteController } from './controller';
import { Body } from './components';

export function Invite() {
  const { sharingKey, canShow, onRequestClose } = useInviteController();

  if (sharingKey && canShow) {
    return (
      <DefaultModal
        isOpen={!!sharingKey}
        onRequestClose={onRequestClose}
        withoutSpace
      >
        <Body sharingKey={sharingKey} onRequestClose={onRequestClose} />
      </DefaultModal>
    );
  }
  return null;
}
