import { PropsWithChildren } from 'react';
import {
  Header,
  Menu,
  Breadcrumbs,
  Content,
  TimerLogout,
  Loader,
  DataRetention,
  Invite,
} from './components';
import { Container } from './styles';

export function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <>
      <Invite />
      <DataRetention />
      <TimerLogout />
      <Loader />
      <Container gridTemplateRows="100vh" gridTemplateColumns="200px 1fr">
        <Menu />
        <Container
          gridTemplateRows="80px minmax(0, max-content) 1fr"
          gridTemplateColumns="1fr"
        >
          <Header />
          <Breadcrumbs />
          <Content>{children}</Content>
        </Container>
      </Container>
    </>
  );
}
