import { Link } from '../Link';
import { MultiplePathsLinkContainer } from '../MultiplePathsLinkContainer';
import { useSharingLinkController } from './controller';

export function SharingLink() {
  const { sharingAccesiblePath, sharingPaths } = useSharingLinkController();

  return (
    <MultiplePathsLinkContainer paths={sharingPaths}>
      <Link to={sharingAccesiblePath} icon="navigation" text="Data Sharing" />
    </MultiplePathsLinkContainer>
  );
}
